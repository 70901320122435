import React, { createContext, useContext, useState } from 'react'
import ReactDOM from 'react-dom/client'
import './index.css'
import App from './App'
import { setupStore } from 'store'
import { Provider } from 'react-redux'
import { BrowserRouter as Router } from 'react-router-dom'
import { createTheme, ThemeProvider } from '@mui/material'
import { ConfirmProvider } from 'material-ui-confirm'
import Futura from '../src/assets/fonts/FuturaPT-Book.woff2'
import Futura_bold from '../src/assets/fonts/FuturaPT-Bold.woff2'

const root = ReactDOM.createRoot(document.getElementById('root'))

const store = setupStore()

const { palette } = createTheme()

const lightTheme = createTheme({
  typography: {
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
      'Futura'
    ].join(',')
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: `
        @font-face {
          font-family: 'Futura';
          font-style: normal;
          font-display: swap;
          font-weight: 400;
          src: local('Raleway'), local('Raleway-Regular'), url(${Futura}) format('woff2');
        }
        @font-face {
          font-family: 'Futura';
          font-style: normal;
          font-display: swap;
          font-weight: 600;
          src: local('Raleway'), local('Raleway-Regular'), url(${Futura_bold}) format('woff2');
        }
      `
    },
    MuiInputLabel: {
      styleOverrides: {
        asterisk: {
          display: 'none'
        }
      }
    }
  },
  palette: {
    mode: 'light',
    border_blue: palette.augmentColor({
      color: {
        main: '#00829b',
        border: '#000000'
      }
    })
  }
})

const darkTheme = createTheme({
  ...lightTheme,
  palette: {
    mode: 'dark',
    border: '#ffffff'
  }
})

const ThemeContext = createContext()

export function useThemeToggle() {
  return useContext(ThemeContext)
}

function ThemeProviderWithToggle({ children }) {
  const ignoredLocations = [
    '/privacy-policy',
    '/form',
    '/oschadbank',
    '/credit'
  ]
  const initialDarkMode =
    localStorage.getItem('darkMode') === 'true' &&
    !ignoredLocations.includes(window.location.pathname)
  const [darkMode, setDarkMode] = useState(initialDarkMode)

  const theme =
    darkMode && !ignoredLocations.includes(window.location.pathname)
      ? darkTheme
      : lightTheme

  if (initialDarkMode) {
    document.body.classList.add('dark-theme')
  }

  const toggleDarkMode = () => {
    const newDarkMode = !darkMode
    setDarkMode(newDarkMode)
    localStorage.setItem('darkMode', newDarkMode.toString())
  }

  return (
    <ThemeContext.Provider value={{ darkMode, setDarkMode: toggleDarkMode }}>
      <ThemeProvider theme={theme}>{children}</ThemeProvider>
    </ThemeContext.Provider>
  )
}

root.render(
  <ThemeProviderWithToggle>
    <ConfirmProvider>
      <Provider store={store}>
        <Router>
          <App />
        </Router>
      </Provider>
    </ConfirmProvider>
  </ThemeProviderWithToggle>
)
