import React, { useState } from 'react'
import { Alert, Button, Stack, TextField, styled } from '@mui/material'
import { useDispatch, useSelector } from 'react-redux'
import { changeUserPassword } from 'store/reducers/admin/adminActions'

const ColoredTextField = styled(TextField)({
  '& label': {
    color: '#0276aa'
  },
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: '#0276aa',
      borderWidth: 1
    }
  },
  '& input + fieldset': {
    borderColor: '#0276aa',
    borderWidth: 1
  }
})

const ChangePassword = () => {
  const { error } = useSelector(state => state.admin)
  const dispatch = useDispatch()

  const [passwordData, setPasswordData] = useState({
    old_password: '',
    new_password: '',
    re_new_password: ''
  })

  const { old_password, new_password, re_new_password } = passwordData

  const handlePasswordChange = e => {
    setPasswordData({ ...passwordData, [e.target.name]: e.target.value })
  }

  const handlePasswordClick = () => {
    dispatch(changeUserPassword({ ...passwordData }))
  }

  return (
    <Stack
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: { xs: '10px', md: '24px' },
        justifyContent: 'center',
        alignItems: 'center',
        width: '50%'
      }}
    >
      <ColoredTextField
        onChange={e => handlePasswordChange(e)}
        value={old_password}
        type='password'
        name='old_password'
        label='Старий пароль'
        size='small'
      />
      <ColoredTextField
        onChange={e => handlePasswordChange(e)}
        value={new_password}
        type='password'
        name='new_password'
        label='Новий пароль'
        size='small'
      />
      <ColoredTextField
        onChange={e => handlePasswordChange(e)}
        value={re_new_password}
        type='password'
        name='re_new_password'
        label='Повторіть новий пароль'
        size='small'
      />
      <Button
        size='medium'
        variant='contained'
        color='success'
        onClick={handlePasswordClick}
      >
        Змінити
      </Button>
      {error && <Alert severity='error'>{error}</Alert>}
    </Stack>
  )
}

export default ChangePassword
