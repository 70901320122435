import React, { useEffect, useState } from 'react'
import EditProfileInfo from './EditProfileInfo'
import { useDispatch, useSelector } from 'react-redux'
import { profileInfoUpdateByAdmin } from 'store/reducers/admin/adminActions'
import MyModal from 'UI/MyModal'

const EditProfileModal = ({ profile_data, open, handleClose }) => {
  const { partners, adminOffers } = useSelector(state => state.admin)
  const dispatch = useDispatch()

  const [editableProfile, setEditableProfile] = useState({})

  const {
    first_name,
    last_name,
    email,
    offers,
    partner,
    webmaster_id,
    address,
    phone,
    conversion_payment,
    balance,
    user_type
  } = editableProfile

  const handleChange = e => {
    if (e.target.name === 'offers') {
      if (offers.includes(e.target.value)) {
        setEditableProfile({
          ...editableProfile,
          offers: offers
            .filter(o => o.id !== e.target.value)
            .map(o => partners.find(p => p.id === o))
        })
      } else {
        setEditableProfile({
          ...editableProfile,
          offers: e.target.value.map(o => adminOffers.find(ao => ao.id === o))
        })
      }
    } else {
      setEditableProfile({
        ...editableProfile,
        [e.target.name]: e.target.value
      })
    }
  }

  const handleClick = () => {
    dispatch(profileInfoUpdateByAdmin(profile_data.id, { ...editableProfile }))
    handleClose()
  }

  useEffect(() => {
    setEditableProfile(profile_data)
  }, [profile_data])

  return (
    <MyModal open={open} onClose={handleClose}>
      <EditProfileInfo
        phone={phone}
        address={address}
        email={email}
        last_name={last_name}
        first_name={first_name}
        offers={offers?.map(o => o.id)}
        partner={partner}
        webmaster_id={webmaster_id}
        conversion_payment={conversion_payment}
        balance={balance}
        user_type={user_type}
        handleChange={handleChange}
        handleClick={handleClick}
      />
    </MyModal>
  )
}

export default EditProfileModal
