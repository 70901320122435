import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Font,
  Image,
} from '@react-pdf/renderer'
import NotoSans from './NotoSans-Regular.ttf'

Font.register({ family: 'NotoSans', src: NotoSans })

Font.registerEmojiSource({
  format: 'png',
  url: 'https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/72x72/'
})

function PdfExportMenuItem({ rows, columns, user }) {
  const dataRange = JSON.parse(localStorage.getItem('dateRange'))

  const styles = StyleSheet.create({
    page: {
      flexDirection: 'column',
      backgroundColor: 'white',
      fontFamily: 'NotoSans',
      padding: 20,
      breakAfter: 'auto'
    },
    section: {
      margin: 10,
      flexGrow: 1
    },
    table: {
      display: 'table',
      width: 'auto',
      borderStyle: 'solid',
      borderWidth: 1,
      borderRightWidth: 0,
      borderBottomWidth: 0,
      borderCollapse: 'collapse'
    },
    tableRow: {
      margin: 'auto',
      flexDirection: 'row',
      marginTop: '-3px',

    },
    tableRowFirst: {
      margin: 'auto',
      flexDirection: 'row',
      borderTop: '1px solid black',
      marginTop: '0px',
    },
    tableRowLast: {
      margin: 'auto',
      flexDirection: 'row',
      marginTop: '-3px',
      marginBottom: '0px'
    },
    tableRowHead: {
      backgroundColor: '#cdcdcd',
      fontWeight: 600,
      margin: 'auto',
      flexDirection: 'row',
      marginTop: '0px',
    },
    tableCol: {
      width: 100 / columns?.length + '%',
      borderStyle: 'solid',
      borderWidth: 1,
      borderLeftWidth: 0,
      borderTopWidth: 0,
      margin: 'auto',
      textAlign: "center"
    },
    tableColSmall: {
      width: 100 / columns?.length - 3 + '%',
      borderStyle: 'solid',
      borderWidth: 1,
      borderLeftWidth: 0,
      borderTopWidth: 0,
      margin: 'auto',
      textAlign: "center"
    },
    tableColMessage: {
      width: 100 / columns?.length + 8 + '%',
      borderStyle: 'solid',
      borderWidth: 1,
      borderLeftWidth: 0,
      borderTopWidth: 0,
      margin: 'auto',
      textAlign: "center"
    },
    tableColGoal: {
      width: 100 / columns?.length + '%',
      borderStyle: 'solid',
      borderWidth: 1,
      borderLeftWidth: 0,
      borderTopWidth: 0,
      margin: 'auto',
      textAlign: "center"
    },
    tableColIMG: {
      width: 100 / columns?.length + 4 + '%',
      borderStyle: 'solid',
      borderWidth: 1,
      borderLeftWidth: 0,
      borderTopWidth: 0,
      margin: 'auto',
      textAlign: "center"
    },
    iconContainer: {
      width: '20%',
      paddingTop: '10%',
      paddingBottom: '10%',
      paddingLeft: 5,
      fontSize: 10
    },
    textContainer: {
      width: '80%'
    },
    tableCell: {
      margin: 'auto',
      padding: '5px 2px',
      fontSize: '10px',
      justifyContent: 'flex-start',
      alignItems: 'center',
      flexDirection: 'row',
      display: 'flex',
      height: '40px'
    },
    img: {
      height: '7px'
    },
    title: {
      marginBottom: 20
    }
  })

  const formatDate = time => {
    const date = new Date(time)
    return `${String(date.getDate()).padStart(2, '0')}.${String(
      date.getMonth() + 1
    ).padStart(2, '0')}.${date.getFullYear()} ${String(
      date.getHours()
    ).padStart(2, '0')}:${String(date.getMinutes()).padStart(2, '0')}:${String(
      date.getSeconds()
    ).padStart(2, '0')}`
  }

  function convertDateFormat(inputDate) {
    let dateParts = inputDate.split('-')
    let year = dateParts[0]
    let month = dateParts[1]
    let day = dateParts[2]
    let formattedDate = day + '.' + month + '.' + year + 'р.'
    return formattedDate
  }

  return (
    <Document>
    <Page size='A4' style={styles.page} orientation='landscape'>
      <View style={styles.title}>
        <Text>
          Звіт про конверсії за період{' '}
          {convertDateFormat(dataRange.start_date)} -{' '}
          {convertDateFormat(dataRange.end_date)}{' '}
        </Text>
      </View>
      <View style={styles.table}>
        <View style={styles.tableRowHead}>
          {
             columns &&
              columns.map(el => (
                <View
                  style={
                    [
                      'ID',
                      'Статус',
                      'Час конверсії',
                      'Виплата',
                      'Місто',
                      'Вебмайстер'
                    ].includes(el.headerName)
                      ? styles.tableColSmall
                      : el.headerName === 'IP відвідувача'
                      ? styles.tableColIMG
                      : el.headerName === 'Додаткова інформація'
                      ? styles.tableColMessage
                      : el.headerName === 'Цільова дія'
                      ? styles.tableColGoal
                      : styles.tableCol
                  }
                >
                  <Text style={styles.tableCell}>{el.headerName === 'Гроші в очікуванні апрува' 
                  ?  'Гроші на підтвердженні' : el.headerName }</Text>
                </View>
              ))}
        </View>
        {rows.map((el, index) => {
          return (
            <View style={
              (index === 12 || (index - 12)% 14 === 0) 
                ? styles.tableRowFirst 
                : (index === 11 || (index - 11)% 14 === 0)
                ? styles.tableRowLast 
                : styles.tableRow } key={el.id} wrap={false}>

              {Object.keys(el).map((key) => {

                 if (key === 'webmaster_id' && user.user_type === 'admin') {
                  return (
                    <View style={styles.tableColSmall} key={el[key]}>
                      <Text style={styles.tableCell}>{el[key]}</Text>
                    </View>
                  )
                }
                if (key === 'conversion_time') {
                  return (
                    <View style={styles.tableColSmall} key={el[key]}>
                      <Text style={styles.tableCell}>
                        {formatDate(el[key])}
                      </Text>
                    </View>
                  )
                }
                if (['id', 'status'].includes(key)) {
                  return (
                    <View style={styles.tableColSmall} key={el[key]}>
                      <Text style={styles.tableCell}>{el[key]}</Text>
                    </View>
                  )
                }
                if (key === 'goal') {
                  return (
                    <View style={styles.tableColGoal} key={el[key]}>
                      <Text style={styles.tableCell}>{el[key]}</Text>
                    </View>
                  )
                }
                if (key === 'visitor_ip') {
                  return (
                    <View style={styles.tableColIMG} key={el[key]}>
                      <Text style={styles.tableCell}>
                        {el.visitor_country &&
                        el.visitor_country === 'Austria' ? (
                          <>
                            <Image
                              style={styles.img}
                              src='https://upload.wikimedia.org/wikipedia/commons/7/76/Flag_of_Austria.png'
                            />
                            <Text> </Text>
                          </>
                        ) : (
                          el.visitor_country_png + ' '
                        )}
                        {el[key]}
                      </Text>
                    </View>
                  )
                }
                if (key === 'message_from_bank') {
                  return (
                    <View style={styles.tableColMessage} key={el[key]}>
                      <Text style={styles.tableCell}>{el[key]}</Text>
                    </View>
                  )
                }

                if (key === 'payment' && user.user_type !== 'admin') {
                  return (
                    <View style={styles.tableColSmall} key={el[key]}>
                      <Text style={styles.tableCell}>{el[key]}</Text>
                    </View>
                  )
                }

               
                if (
                  ![
                    'visitor_city',
                    'visitor_country',
                    'visitor_country_png',
                    'conversion_payment',
                    'payment',
                    'webmaster_id'
                  ].includes(key)
                ) {
                  return (
                    <View style={styles.tableCol} key={el[key]}>
                      <Text style={styles.tableCell}>{el[key]}</Text>
                    </View>
                  )
                }
                return null
              })}
            </View>
          )
        })}
      </View>
    </Page>
  </Document>
  )

}

export default PdfExportMenuItem
