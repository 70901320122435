import React, { useEffect, useState } from 'react'
import AdminLayoutAdapt from 'layouts/AdminLayoutAdapt'
import { Box, Button, useTheme } from '@mui/material'
import OffersList from 'components/admin/offers/OffersList'
import { useDispatch, useSelector } from 'react-redux'
import {
  getOffersList,
  getPartnersList
} from 'store/reducers/admin/adminActions'
import CreateOffer from 'components/admin/offers/CreateOffer'
import { useNavigate } from 'react-router-dom'
import { updateOfferInfo } from 'store/reducers/admin/adminActions'

const OffersPage = () => {
  const theme = useTheme()
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const { user, isAuthenticated, adminOffers } = useSelector(
    state => state.admin
  )

  const [createOfferModalOpen, setCreateOfferModalOpen] = useState(false)
  const [openChangPosition, setOpenChangPosition] = useState(false)
  const [dataNewPosition, setDataNewPosition] = useState([])
  const [isLoadingChangPosition, setIsLoadingChangPosition] = useState(false)

  const handleOpen = () => setCreateOfferModalOpen(true)
  const handleClose = () => setCreateOfferModalOpen(false)

  useEffect(() => {
    if (!openChangPosition) {
      dispatch(getOffersList())
      dispatch(getPartnersList())
    }
  }, [dispatch, openChangPosition])

  useEffect(() => {
    setTimeout(() => {
      if (!isAuthenticated) navigate('/admin/login')
    }, 1000)
  }, [isAuthenticated, navigate])

  function dispatchAndUpdateOffer(dispatch, offer) {
    return new Promise((resolve, reject) => {
      dispatch(updateOfferInfo(offer))
        .then(() => resolve())
        .catch(error => reject(error))
    })
  }

  async function savePosition() {
    setIsLoadingChangPosition(true)
    if (openChangPosition) {
      let start =
        [...dataNewPosition].sort(
          (a, b) => +b.showcase_position - +a.showcase_position
        )[0].showcase_position + 1

      for (let i = 0; i < adminOffers.length; i++) {
        if (
          adminOffers[i].showcase_position !==
          dataNewPosition[i].showcase_position
        ) {
          const secondNumber = dataNewPosition[i].showcase_position
          const findIndex = adminOffers.findIndex(
            el => el.showcase_position === secondNumber
          )
          const findElement = dataNewPosition.find(
            el => el.id === adminOffers[findIndex].id
          )

          if (findIndex !== -1) {
            await dispatchAndUpdateOffer(dispatch, {
              ...adminOffers[findIndex],
              company: adminOffers[findIndex]?.company?.id,
              showcase_position: start,
            });

            await dispatchAndUpdateOffer(dispatch, {
              ...adminOffers[i],
              company: adminOffers[i]?.company?.id,
              showcase_position: secondNumber,
            });

            await dispatchAndUpdateOffer(dispatch, {
              ...adminOffers[findIndex],
              company: adminOffers[findIndex]?.company?.id,
              showcase_position: findElement.showcase_position,
            });
            start++;
            
          }
        }
      }
    }
    setIsLoadingChangPosition(false)
    setOpenChangPosition(false)
  }

  return (
    <AdminLayoutAdapt>
      <Box sx={{ mt: theme.spacing(2) }} mb={2} ml={2} mr={2}>
        {!(user.user_type === 'webmaster') && (
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              gap: '30px',
              alignItems: 'center'
            }}
          >
            <Button
              variant='contained'
              color='success'
              onClick={handleOpen}
              sx={{ mb: theme.spacing(1) }}
            >
              Створити нову кредитну пропозицію
            </Button>

            {!openChangPosition && (
              <Button
                size='medium'
                color='info'
                variant='outlined'
                onClick={() => {
                  setOpenChangPosition(true)
                }}
                sx={{ mb: theme.spacing(1) }}
              >
                Змінити позицію на вітрині
              </Button>
            )}

            {openChangPosition && (
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  gap: '30px',
                  alignItems: 'center'
                }}
              >
                <Button
                  size='medium'
                  color='info'
                  variant='outlined'
                  onClick={savePosition}
                  sx={{ mb: theme.spacing(1) }}
                >
                  Зберегти зміни
                </Button>
                <Button
                  size='medium'
                  color='info'
                  variant='outlined'
                  onClick={() => {
                    setOpenChangPosition(false)
                  }}
                  sx={{ mb: theme.spacing(1) }}
                >
                  Скасувати
                </Button>
              </Box>
            )}
          </Box>
        )}
        <OffersList
          disableCU={user.user_type === 'webmaster'}
          setDataNewPosition={setDataNewPosition}
          openChangPosition={openChangPosition}
          isLoadingChangPosition={isLoadingChangPosition}
        />
        {!(user.user_type === 'webmaster') && (
          <CreateOffer handleClose={handleClose} open={createOfferModalOpen} />
        )}
      </Box>
    </AdminLayoutAdapt>
  )
}

export default OffersPage
