import { Modal, Box, Typography } from '@mui/material'
import tick from '../../assets/images/Tick.svg'
import { ModalBtn } from 'styled-components/Styled.styled'
import { useDispatch } from 'react-redux'
import { createConversationsVisit } from 'store/reducers/loans/loanActions'
import { useEffect, useState } from 'react'
import axios from 'axios'

const OschadInfoModal = ({
  retry,
  open,
  handleClose,
  title = 'Вітаємо! Ви отримали попереднє погодження від ІдеяБанку.',
  text = 'Очікуйте дзвінка представника банку найближчим часом.',
  showTitle = true,
  textButton = 'Продовжити'
}) => {
  const dispatch = useDispatch()
  const [returnUrl, setReturnURL] = useState(
    'https://sportbank.ua/?source=cpa_ocean_cash&utm_campaign=cash'
  )

  useEffect(() => {
    async function getReturnUrl() {
      const response = await axios.get(
        'https://leadleader.herokuapp.com/api/partners/sportbank-settings/'
      )
      setReturnURL(response.data.redirect_url)
    }

    getReturnUrl()
  }, [])

  function closeModal() {
    if (
      title === 'Для отримання кредиту встановіть мобільний додаток izibank.'
    ) {
      const loan = {
        company_id: 2858,
        loan_offer_url: 'https://izibank.com.ua/',
        loan_pk: 20,
        return_click_id: false,
        webmaster_id: 'leadleader',
        session_id: localStorage.uuid
      }
      dispatch(createConversationsVisit(loan))
      window.location.href = returnUrl
      handleClose()
    } else if (title === "Помилка зв'язку") {
      retry()
      handleClose()
    } else {
      handleClose()
    }
  }

  return (
    <Modal
      open={open}
      onClose={handleClose}
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center'
      }}
    >
      <Box
        sx={{
          display: 'flex',
          alignItems: 'flex-start',
          flexDirection: 'column',
          width: '320px',
          backgroundColor: '#fff',
          p: '25px 19px 30px',
          borderRadius: '10px',
          gap: '27px'
        }}
      >
        <Box
          sx={{
            display: 'flex',
            alignItems: 'flex-start',
            gap: '15px'
          }}
        >
          {title === "Помилка зв'язку" || (
            <img src={tick} alt='tick' style={{ marginTop: 10 }} />
          )}
          <Box>
            <Typography
              fontWeight={400}
              textAlign='left'
              color='#0463EF'
              fontSize='20px'
              id='modal-modal-title'
              variant='h6'
              component='h2'
              lineHeight={'130%'}
              sx={{ fontFamily: 'Roboto', mb: showTitle ? 2 : 0 }}
            >
              {title}
            </Typography>
            {showTitle && (
              <Typography textAlign='left' fontSize='16px' color='#262626'>
                {text}
              </Typography>
            )}
          </Box>
        </Box>
        <ModalBtn onClick={closeModal}>{textButton}</ModalBtn>
      </Box>
    </Modal>
  )
}

export default OschadInfoModal
