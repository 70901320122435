import React, { useEffect, useState } from 'react'
import { Box, Button, useTheme } from '@mui/material'
import { useDispatch, useSelector } from 'react-redux'
import { getPartnersList } from 'store/reducers/admin/adminActions'
import AdminLayoutAdapt from 'layouts/AdminLayoutAdapt'
import PartnersList from 'components/admin/partners/PartnersList'
import CreatePartner from 'components/admin/partners/CreatePartner'
import { useNavigate } from 'react-router-dom'

const PartnersPage = () => {
  const theme = useTheme()
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const [createPartnerModalOpen, setCreatePartnerModalOpen] = useState(false)
  const { isAuthenticated } = useSelector(state => state.admin)

  const handleOpen = () => setCreatePartnerModalOpen(true)
  const handleClose = () => setCreatePartnerModalOpen(false)

  useEffect(() => {
    dispatch(getPartnersList())
  }, [dispatch])

  useEffect(() => {
    setTimeout(() => {
      if (!isAuthenticated) navigate('/admin/login')
    }, 1000)
  }, [isAuthenticated, navigate])

  return (
    <AdminLayoutAdapt>
      <Box sx={{ mt: theme.spacing(2) }} mb={2} ml={2} mr={2}>
        <Button
          variant='contained'
          color='success'
          onClick={handleOpen}
          sx={{ mb: theme.spacing(1) }}
        >
          Створити нового партнера
        </Button>
        <PartnersList />
        <CreatePartner
          handleClose={handleClose}
          open={createPartnerModalOpen}
        />
      </Box>
    </AdminLayoutAdapt>
  )
}

export default PartnersPage
