import axios from 'axios'
import {
  getLoans,
  getLoansFailure,
  getLoansSuccess,
  registrationBisbank,
  registrationBisbankFailure,
  registrationBisbankSuccess,
  registrationIdeabank,
  registrationIdeabankFailure,
  registrationIdeabankSuccess
} from './loanSlise'

export const getLoanOffers =
  ({ limit = 10, offset = 0 }) =>
  async dispatch => {
    try {
      dispatch(getLoans())
      const response = await axios
        .get(`${process.env.REACT_APP_API_URL}/loans/`, {
          params: {
            limit,
            offset
          }
        })
        .then(response => response.data)
      dispatch(
        getLoansSuccess({
          results: response.results,
          page: offset / 10,
          count: response.count
        })
      )
    } catch (e) {
      dispatch(getLoansFailure(e.message))
    }
  }

export const createConversationsVisit = loan => async dispatch => {
  const loan_offer_url = loan.loan_offer_url.split('?')[0]
  if (loan.return_click_id) {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/partner-site-visit/`,
        {
          company_id: loan.company_id,
          loan_offer_url: loan_offer_url,
          loan_pk: loan.loan_pk,
          return_click_id: loan.return_click_id,
          webmaster_id: loan.webmaster_id,
          session_id: loan.session_id
        }
      )
      //setTimeout to prevent popup blocking by safari
      setTimeout(() => {
        window.open(response.data, '_blank')
      })
    } catch (e) {
      console.log(e)
    }
  } else {
    try {
      await axios.post(`${process.env.REACT_APP_API_URL}/partner-site-visit/`, {
        company_id: loan.company_id,
        loan_offer_url: loan_offer_url,
        loan_pk: loan.loan_pk,
        webmaster_id: loan.webmaster_id,
        session_id: loan.session_id
      })
      //setTimeout to prevent popup blocking by safari
      setTimeout(() => {
        window.open(loan.loan_offer_url, '_blank')
      })
    } catch (e) {
      console.log(e)
    }
  }
}

export const registrationBis =
  (
    {
      name,
      phone,
      idenf_code,
      address,
      email,
      amount_credit,
      credit_term,
      activity_type
    },
    id_conversion
  ) =>
  async dispatch => {
    dispatch(registrationBisbank())
    try {
      await axios.post(`${process.env.REACT_APP_API_URL}/partners/bisbank/`, {
        name,
        phone,
        idenf_code,
        address,
        email,
        amount_credit,
        credit_term,
        activity_type,
        id_conversion
      })

      dispatch(registrationBisbankSuccess())
    } catch (e) {
      dispatch(registrationBisbankFailure())
    }
  }

export const registrationIdea =
  (
    {
      name,
      phone,
      idenf_code,
      address,
      email,
      amount_credit,
      credit_term,
      activity_type
    },
    id_conversion
  ) =>
  async dispatch => {
    dispatch(registrationIdeabank())
    try {
      await axios.post(`${process.env.REACT_APP_API_URL}/partners/ideabank/`, {
        name,
        phone,
        idenf_code,
        address,
        email,
        amount_credit,
        credit_term,
        activity_type,
        id_conversion
      })

      dispatch(registrationIdeabankSuccess())
    } catch (e) {
      dispatch(registrationIdeabankFailure())
    }
  }
