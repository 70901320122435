import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { CircularProgress, Stack, Typography, useTheme } from '@mui/material'
import {
  getOffersList,
  updateUserData
} from 'store/reducers/admin/adminActions'
import AdminLayoutAdapt from 'layouts/AdminLayoutAdapt'
import EditProfileInfo from 'components/admin/profiles/edit/EditProfileInfo'
import ConversionsInfo from 'components/admin/webmaster/ConversionsInfo'
import ChangePassword from 'components/admin/profiles/edit/ChangePassword'
import SecurityToken from 'components/admin/advertiser/SecurityToken'
import { useNavigate } from 'react-router-dom'

const ProfilePage = () => {
  const theme = useTheme()
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const { user, partners, isLoading, adminOffers, isAuthenticated } =
    useSelector(state => state.admin)
  const { user_type } = user
  const [userData, setUserData] = useState({
    email: '',
    first_name: '',
    last_name: '',
    offers: [],
    partner: -1,
    webmaster_id: '',
    address: '',
    phone: ''
  })
  const {
    email,
    first_name,
    last_name,
    offers,
    partner,
    webmaster_id,
    address,
    phone
  } = userData

  const handleChange = e => {
    if (e.target.name === 'offers') {
      if (offers.includes(e.target.value)) {
        setUserData({
          ...userData,
          offers: adminOffers
            .filter(ao => ao.id !== e.target.value)
            .map(ao => partners.find(p => p.id === ao))
        })
      } else {
        setUserData({
          ...userData,
          offers: e.target.value.map(o => adminOffers.find(ao => ao.id === o))
        })
      }
    } else {
      setUserData({
        ...userData,
        [e.target.name]: e.target.value
      })
    }
  }

  const handleClick = () => {
    dispatch(updateUserData(user.id, user.user_type, { ...userData }))
  }

  useEffect(() => {
    dispatch(getOffersList())
    setUserData({ ...user })
  }, [dispatch, user])

  useEffect(() => {
    setTimeout(() => {
      if (!isAuthenticated) navigate('/admin/login')
    }, 1000)
  }, [isAuthenticated, navigate])

  return (
    <AdminLayoutAdapt>
      {isLoading && <CircularProgress />}
      <Stack spacing={3} alignItems='center' justifyContent='center'>
        {user_type === 'webmaster' && (
          <>
            <Typography
              sx={{ fontSize: { xs: '20px', md: '32px' } }}
              mt={1}
              color={theme.palette.success.main}
              textAlign='center'
            >
              Поточний баланс конверсій
            </Typography>
            <ConversionsInfo />
          </>
        )}
        <Stack
          sx={{
            display: 'flex',
            width: '100%',
            flexDirection: 'row',
            alignItems: 'flex-start',
            marginTop: '45px !important'
          }}
        >
          {userData && (
            <EditProfileInfo
              email={email}
              address={address}
              first_name={first_name}
              last_name={last_name}
              phone={phone}
              offers={offers?.map(o => o.id)}
              webmaster_id={webmaster_id}
              partner={partner}
              handleChange={handleChange}
              handleClick={handleClick}
              user_type={user_type}
              disableUserTypeChoice={true}
            />
          )}
          {user_type === 'advertiser' && <SecurityToken />}
          <ChangePassword />
        </Stack>
      </Stack>
    </AdminLayoutAdapt>
  )
}

export default ProfilePage
