import './Footer.scss'
import LogoFooter from '../../../assets/images/Logo-footer.svg'
import { Link } from 'react-router-dom'

export const Footer = ({ contactsRef }) => {
  return (
    <section className='footer'>
      <article className='footer__menu' ref={contactsRef}>
        <Link className='footer__logo' to='/'>
          <img className='footer__logo-img' src={LogoFooter} alt='logo' />
        </Link>
        <ul className='footer__nav'>
          <li className='footer__nav-item'>
            <a
              className='footer__nav-link footer__nav-link--fone'
              href='email:office@leadleader.com.ua'
            >
              office@leadleader.com.ua
            </a>
          </li>
        </ul>
        <div className='footer__copyright'>
          <p className='footer__copyright-item'>Copyright © 2023 Lead Leader</p>
          <p className='footer__copyright-item'>All Rights Reserved</p>
        </div>
      </article>
    </section>
  )
}
