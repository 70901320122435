import React, { useEffect, useRef, useState, useCallback } from 'react'
import { Stack, Alert } from '@mui/material'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { loginUser } from 'store/reducers/admin/adminActions'
import { Link } from 'react-router-dom'
import './Login.scss'
import Logo from '../../../assets/images/Logo-registration.svg'
import { Header } from '../../../components/homeNew/Header'

const Login = () => {
  const dispatch = useDispatch()
  const { isAuthenticated, error } = useSelector(state => state.admin)

  const navigate = useNavigate()

  const [credentials, setCredentials] = useState({ email: '', password: '' })
  const [rememberMe, setRememberMe] = useState(false)

  const { email, password } = credentials
  const handleChange = e =>
    setCredentials({ ...credentials, [e.target.name]: e.target.value })

  const handleCheked = event => {
    const input = event.target
    const value = input.type === 'checkbox' ? input.checked : input.value
    setRememberMe(value)
  }

  const handleClick = () => {
    localStorage.setItem('rememberMe', rememberMe)
    localStorage.setItem('email', rememberMe ? email : '')
    localStorage.setItem('password', rememberMe ? password : '')

    dispatch(loginUser(credentials))
  }

  const buttonRef = useRef(null)
  const handleKeyPress = e => {
    if (e.key === 'Enter') {
      buttonRef.current.click()
    }
  }

  const redirectToAdmin = useCallback(() => {
    navigate('/admin')
  }, [navigate])

  useEffect(() => {
    const rememberMe = localStorage.getItem('rememberMe') === 'true'
    const email = rememberMe ? localStorage.getItem('email') : ''
    const password = rememberMe ? localStorage.getItem('password') : ''
    setCredentials({ ...credentials, email: email, password: password })
    setRememberMe(rememberMe)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (isAuthenticated) redirectToAdmin()
  }, [isAuthenticated, redirectToAdmin])

  return (
    <main className='login'>
      <div className='login__header'>
        <Header />
      </div>

      <div className='login__container'>
        <div className='login__block-menu'>
          <Link className='login__logo' to='/'>
            <img className='login__logo-img' src={Logo} alt='logo' />
          </Link>
          <Link to='/' className='login__button-close'></Link>
        </div>
        <h2 className='login__title'>Вхід</h2>
        <Stack className='login__form'>
          <div className='login__personal-information'>
            <input
              type='email'
              name='email'
              className='login__item'
              placeholder='Email'
              value={email}
              onChange={e => handleChange(e)}
              required
            />

            <input
              onKeyUp={e => handleKeyPress(e)}
              value={password}
              onChange={e => handleChange(e)}
              type='password'
              name='password'
              className='login__item'
              placeholder='Пароль'
              required
            />
            {error && <Alert severity='error'>{error}</Alert>}
          </div>
          <div className='login__remember'>
            <input
              checked={rememberMe}
              onChange={e => handleCheked(e)}
              type='checkbox'
              id='remember'
              name='remember'
              value='remember'
              className='login__remember-input'
            />
            <label className='login__remember-label' for='remember'>
              Запам’ятати мене
            </label>
          </div>
          <button
            ref={buttonRef}
            onClick={handleClick}
            className='login__button'
            value='Увійти'
          >
            Увійти
          </button>
        </Stack>
        <div className='login__reset-password'>
          <p className='login__reset-password-text'>Забули пароль?</p>
          <Link
            to='/admin/resetpassword/'
            className='login__reset-password-link'
          >
            Відновити
          </Link>
        </div>
      </div>
    </main>
  )
}

export default Login
