import React, { useEffect, useState, useMemo } from 'react'
import {
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody
} from '@mui/material'
import { amountConversionsPayment } from 'utils/amountConversionsPayment'
import axios from 'axios'
import { useSelector } from 'react-redux'

const ConversionsInfo = () => {
  const { user } = useSelector(state => state.admin)

  const [separatedConversions, setSeparatedConversions] = useState({
    pending: [],
    active: [],
    paid: []
  })
  const { pending, active } = separatedConversions

  useEffect(() => {
    axios
      .get(
        `${process.env.REACT_APP_API_URL}/users/profile/get-separated-conversions`,
        {
          headers: {
            Authorization: `JWT ${localStorage.getItem('access')}`
          }
        }
      )
      .then(res => setSeparatedConversions(res.data))
  }, [user.offers])

  function createData(name, count, sum) {
    return { name, count, sum }
  }

  const rows = useMemo(
    () => [
      createData(
        'В обробці',
        pending.length,
        amountConversionsPayment(pending).toFixed(2)
      ),
      createData(
        'Підтверджено',
        active.length,
        amountConversionsPayment(active).toFixed(2)
      ),
      createData(
        'До сплати',
        active.length,
        amountConversionsPayment(active).toFixed(2)
      )
    ],
    [active, pending]
  )

  return (
    <div>
      {/* <Stack direction='row' spacing={1} justifyContent='space-between'>
        <Stack direction='column' justifyContent='space-between'>
          <Typography variant='body2'>В обробці</Typography>
          <Typography variant='body2'>Підтверджено</Typography>
          <Typography variant='body2'>До сплати</Typography>
        </Stack>
        <Stack direction='column' justifyContent='space-between'>
          <Typography variant='body2'>к-сть: {pending.length}</Typography>
          <Typography variant='body2'>к-сть: {active.length}</Typography>
          <Typography variant='body2'>к-сть: {paid.length}</Typography>
        </Stack>
        <Stack direction='column' justifyContent='space-between'>
          <Typography variant='body2'>
            сума:{' '}
            {subtractFloats(
              countConversionsPayment(pending),
              parseFloat(pending[0]?.offer.commission)
            )}
            ₴
          </Typography>
          <Typography variant='body2'>
            сума:{' '}
            {subtractFloats(
              countConversionsPayment(active),
              parseFloat(active[0]?.offer.commission)
            )}
            ₴
          </Typography>
          <Typography variant='body2'>
            сума:{' '}
            {subtractFloats(
              countConversionsPayment(paid),
              parseFloat(paid[0]?.offer.commission)
            )}
            ₴
          </Typography>
        </Stack>
      </Stack> */}

      <TableContainer>
        <Table
          sx={{ minWidth: { xs: '280px', md: '520px' } }}
          size='small'
          aria-label='a dense table'
        >
          <TableHead>
            <TableRow
              sx={{
                '& td, & th': {
                  border: '1px solid #0276aa',
                  paddingLeft: '10px',
                  paddingRight: '10px'
                }
              }}
            >
              <TableCell>Статус</TableCell>
              <TableCell align='right'>Кількість</TableCell>
              <TableCell align='right'>Сума, грн</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map(row => (
              <TableRow
                key={row.name}
                sx={{
                  '& td, & th': {
                    border: '1px solid #0276aa',
                    paddingLeft: '10px',
                    paddingRight: '10px'
                  }
                }}
              >
                <TableCell component='th' scope='row'>
                  {row.name}
                </TableCell>
                <TableCell align='right'>{row.count}</TableCell>
                <TableCell align='right'>{row.sum}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  )
}

export default ConversionsInfo
