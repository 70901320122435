import React, { useState } from 'react'
import {
  Box,
  MenuItem,
  Stack,
  styled,
  TextField,
  Typography,
  Button
} from '@mui/material'
import 'index.css'
import { useDispatch } from 'react-redux'
import { registrationIdea } from 'store/reducers/loans/loanActions'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as Yup from 'yup'
import Layout from '../layouts/Layout'

import OschadInfoModal from '../components/oschadbank/OschadInfoModal'
import axios from 'axios'
import './Dots.scss'
import Header from 'components/common/Header'

const ColoredTextField = styled(TextField)({
  '& label': {
    fontFamily: 'Roboto',
    color: '#060B19'
  },
  '& input': {
    fontFamily: 'Roboto'
  },

  '& .MuiSelect-select': {
    fontFamily: 'Roboto',
    borderColor: '#CCCCCC',
    borderWidth: 1
  },
  '& input + fieldset': {
    borderColor: '#CCCCCC',
    borderWidth: 1,
    fontFamily: 'Roboto'
  },
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: '#CCCCCC',
      borderWidth: 1,
      fontFamily: 'Roboto'
    }
  }
})

const MyButton = styled(Button)({
  position: 'relative',
  backgroundColor: '#0463EF',
  width: '340px',
  height: '60px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  borderRadius: '4px',
  color: 'white',
  textTransform: 'uppercase',
  fontFamily: 'Rubik',
  fontSize: 24,
  marginBottom: 18,

  '&:hover': {
    backgroundColor: `#1f57a9`
  }
})

const GrayBtn = styled(MyButton)({
  backgroundColor: '#F3F3F3',
  color: '#060B19',
  textTransform: 'none',
  border: '2px solid #0463EF'
})

const Oschadbank = () => {
  const dispatch = useDispatch()

  // const { isRegistration } = useSelector(state => state.loans)

  const [modalOpen, setModalOpen] = useState(false)
  // const [modalDisabledOpen, setModalDisabledOpen] = useState(false)
  const [toglRegistration, setToglRegistration] = useState({
    toglRegistrationIdea: true,
    toglRegistrationBis: true
  })
  const [isLoading, setIsLoading] = useState(false)
  const [userData, setUserData] = useState({
    name: '',
    phone: '',
    idenf_code: '',
    address: '',
    email: '',
    amount_credit: '',
    credit_term: '',
    activity_type: ''
  })
  const [modalText, setModalText] = useState(
    'Очікуйте дзвінка представника банку найближчим часом'
  )
  const [checked, setChecked] = useState(true)
  const [error, setError] = useState(false)

  const [showModalTitle, setShowModalTitle] = useState(true)
  const [modalTitle, setModalTitle] = useState('Bаша заявка відправлена')

  const { toglRegistrationIdea } = toglRegistration

  const {
    name,
    phone,
    idenf_code,
    address,
    email,
    amount_credit,
    credit_term,
    activity_type
  } = userData

  const onChangeText = event => {
    setUserData({ ...userData, [event.target.name]: event.target.value })
  }

  const onChangeNumber = event => {
    // eslint-disable-next-line
    if (event.target.value == Number(event.target.value)) {
      setUserData({ ...userData, [event.target.name]: event.target.value })
    }
  }

  const handleClickIdea = async () => {
    setIsLoading(true)
    if (toglRegistrationIdea && checked) {
      try {
        const response = await axios.post(
          `${process.env.REACT_APP_API_URL}/partner-site-visit/`,
          {
            company_id: 'ideabank',
            loan_offer_url: 'https://ideabank.ua/uk/product/lead/credits-sd',
            loan_pk: 19,
            return_click_id: true,
            webmaster_id: 'ocean',
            session_id: localStorage.uuid
          }
        )

        const result = await axios.post(
          `${process.env.REACT_APP_API_URL}/partners/ideabank/`,
          userData,
          response.data
        )
        dispatch(registrationIdea(userData, response.data))
        setToglRegistration({
          ...toglRegistration,
          toglRegistrationIdea: false
        })
        if (result.data.message === 'Трапилася помилка') {
          setModalTitle('')
          setModalText(
            'Для отримання кредиту завантажте мобільний додаток sportbank'
          )
          setModalOpen(true)
          setShowModalTitle(false)
        } else {
          setModalTitle('Bаша заявка відправлена')
          setModalText('Очікуйте дзвінка представника банку найближчим часом')
          setModalOpen(true)
          setShowModalTitle(true)
        }
      } catch (e) {
        setModalTitle('')
        setModalText(
          'Для отримання кредиту завантажте мобільний додаток sportbank'
        )
        setModalOpen(true)
        setShowModalTitle(false)
      }
    }
    setIsLoading(false)
  }

  const validationSchema = Yup.object().shape({
    name: Yup.string()
      .required('Необхідно ввести ПІБ повністю')
      .test(
        'len',
        'Необхідно ввести ПІБ повністю',
        val => val.toString().trim().split(' ').length === 3
      ),
    phone: Yup.number().required(),
    idenf_code: Yup.string().required().min(10, 'Невірний код'),
    address: Yup.string().required(),
    email: Yup.string().required().email('Невірний Email'),
    amount_credit: Yup.number().required(),
    credit_term: Yup.number().required(),
    activity_type: Yup.string().required()
  })

  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm({
    resolver: yupResolver(validationSchema)
  })

  const handleClose = () => setModalOpen(false)
  // const handleCloseDisabled = () => setModalDisabledOpen(false)

  // useEffect(() => {
  //   if (isRegistration) {
  //     setModalOpen(true)
  //   }
  // }, [isRegistration])

  return (
    <>
      <Header />
      <Layout>
        <Box
          maxWidth='400px'
          m={0}
          display='flex'
          flexDirection='column'
          pt='20px'
          pb='30px'
        >
          <OschadInfoModal
            open={modalOpen}
            handleClose={handleClose}
            title={modalTitle}
            text={modalText}
            showTitle={showModalTitle}
          />

          <Box
            height='100%'
            display='flex'
            flexDirection='column'
            justifyContent='space-around'
          >
            <Typography
              fontWeight={400}
              textAlign='center'
              color='#0463EF'
              fontSize='33px'
              pl='30px'
              pr='30px'
              sx={{ fontFamily: 'Roboto' }}
              lineHeight={'150%'}
              marginBottom={'12px'}
            >
              Кращі банківські кредити з низькою процентною ставкою
            </Typography>
            <Typography
              fontWeight={500}
              textAlign='center'
              color='#0AD5FF'
              fontSize='18px'
              pl='30px'
              pr='30px'
              sx={{ fontFamily: 'Roboto' }}
              lineHeight={'150%'}
              marginBottom={'31px'}
            >
              Попереднє рішення за одну хвилину
            </Typography>
            <Stack gap='18px' px={'30px'} mb='15px'>
              <ColoredTextField
                label="Прізвище, ім'я, по батькові"
                variant='outlined'
                size='small'
                type='text'
                name='name'
                value={name}
                required
                {...register('name')}
                error={errors.name ? true : false}
                helperText={errors.name?.message}
                onChange={e => onChangeText(e)}
                sx={{ fontFamily: 'Roboto' }}
                InputLabelProps={{
                  sx: {
                    '&[data-shrink=true]': {
                      transform: 'translate(18px, -9px) scale(1)',
                      fontSize: 12
                    }
                  }
                }}
              />
              <ColoredTextField
                label='Мобільний телефон'
                variant='outlined'
                size='small'
                value={phone}
                name='phone'
                required
                {...register('phone')}
                error={errors.phone ? true : false}
                onChange={e => onChangeNumber(e)}
                InputLabelProps={{
                  sx: {
                    '&[data-shrink=true]': {
                      transform: 'translate(18px, -9px) scale(1)',
                      fontSize: 12
                    }
                  }
                }}
              />
              <ColoredTextField
                label='Ідентифікаційний код'
                variant='outlined'
                size='small'
                name='idenf_code'
                value={idenf_code}
                required
                {...register('idenf_code')}
                error={errors.idenf_code ? true : false}
                onChange={e => onChangeNumber(e)}
                InputLabelProps={{
                  sx: {
                    '&[data-shrink=true]': {
                      transform: 'translate(18px, -9px) scale(1)',
                      fontSize: 12
                    }
                  }
                }}
              />
              <ColoredTextField
                label='Населенний пункт'
                variant='outlined'
                size='small'
                type='text'
                value={address}
                name='address'
                required
                {...register('address')}
                error={errors.address ? true : false}
                onChange={e => onChangeText(e)}
                InputLabelProps={{
                  sx: {
                    '&[data-shrink=true]': {
                      transform: 'translate(18px, -9px) scale(1)',
                      fontSize: 12
                    }
                  }
                }}
              />
              <ColoredTextField
                label='Електронна пошта'
                variant='outlined'
                size='small'
                name='email'
                value={email}
                required
                {...register('email')}
                error={errors.email ? true : false}
                onChange={e => onChangeText(e)}
                InputLabelProps={{
                  sx: {
                    '&[data-shrink=true]': {
                      transform: 'translate(18px, -9px) scale(1)',
                      fontSize: 12
                    }
                  }
                }}
              />
              <Box
                display='grid'
                gridTemplateColumns={'175px 149px'}
                columnGap={2}
              >
                <ColoredTextField
                  label='Сума, грн.'
                  variant='outlined'
                  size='small'
                  value={amount_credit}
                  name='amount_credit'
                  required
                  {...register('amount_credit')}
                  error={errors.amount_credit ? true : false}
                  onChange={e => onChangeNumber(e)}
                  InputLabelProps={{
                    sx: {
                      '&[data-shrink=true]': {
                        transform: 'translate(18px, -9px) scale(1)',
                        fontSize: 12
                      }
                    }
                  }}
                />
                <ColoredTextField
                  label='Термін'
                  variant='outlined'
                  select
                  size='small'
                  value={credit_term}
                  name='credit_term'
                  required
                  {...register('credit_term')}
                  error={errors.credit_term ? true : false}
                  onChange={e => onChangeNumber(e)}
                  InputLabelProps={{
                    sx: {
                      '&[data-shrink=true]': {
                        transform: 'translate(18px, -9px) scale(1)',
                        fontSize: 12
                      }
                    }
                  }}
                >
                  <MenuItem value='3'>3 місяці</MenuItem>
                  <MenuItem value='6'>6 місяців</MenuItem>
                  <MenuItem value='9'>9 місяців</MenuItem>
                  <MenuItem value='12'>12 місяців</MenuItem>
                  <MenuItem value='18'>18 місяців</MenuItem>
                </ColoredTextField>
              </Box>
              <ColoredTextField
                label='Працевлаштування'
                variant='outlined'
                size='small'
                type='text'
                name='activity_type'
                value={activity_type}
                required
                {...register('activity_type')}
                error={errors.activity_type ? true : false}
                onChange={e => onChangeText(e)}
                select
                InputLabelProps={{
                  sx: {
                    '&[data-shrink=true]': {
                      transform: 'translate(18px, -9px) scale(1)',
                      fontSize: 12
                    }
                  }
                }}
              >
                <MenuItem value='1'>Працюю офіційно / не офіційно</MenuItem>
                <MenuItem value='2'>Підприємець (ФОП)</MenuItem>
                <MenuItem value='3'>Пенсіонер</MenuItem>
                <MenuItem value='4'>Студент</MenuItem>
                <MenuItem value='5'>Декретна відпустка</MenuItem>
                <MenuItem value='6'>Тимчасово не працюю</MenuItem>
              </ColoredTextField>
            </Stack>
            <Stack
              direction='row'
              justifyContent='center'
              alignItems='center'
              spacing={2}
            >
              <MyButton onClick={handleSubmit(handleClickIdea)}>
                {isLoading ? (
                  <div id='dots4'>
                    <span></span>
                    <span></span>
                    <span></span>
                    <span></span>
                  </div>
                ) : (
                  'ОТРИМАТИ КРЕДИТ'
                )}
              </MyButton>
            </Stack>
            <Box
              px='30px'
              position='relative'
              mb='21px'
              onClick={() => {
                setChecked(prev => !prev)
                setError(prev => !prev)
              }}
              style={{ cursor: 'pointer' }}
            >
              <svg
                width='24'
                height='24'
                viewBox='0 0 24 24'
                fill='none'
                xmlns='http://www.w3.org/2000/svg'
                style={{
                  color: error ? 'red' : '#060B19',
                  padding: 0,
                  marginRight: 5,
                  transform: 'translate(-2px, 7px)',
                  borderRadius: 10,
                  cursor: 'pointer'
                }}
              >
                <g id='vuesax/linear/tick-square'>
                  <g id='tick-square'>
                    <path
                      id='Vector'
                      d='M9 22H15C20 22 22 20 22 15V9C22 4 20 2 15 2H9C4 2 2 4 2 9V15C2 20 4 22 9 22Z'
                      stroke={error ? 'red' : '#060B19'}
                      strokeWidth='1.5'
                      strokeLinecap='round'
                      strokeLinejoin='round'
                    />
                    <path
                      id='Vector_2'
                      d='M7.75 12L10.58 14.83L16.25 9.16998'
                      stroke={error ? 'red' : '#060B19'}
                      strokeWidth='1.5'
                      strokeLinecap='round'
                      strokeLinejoin='round'
                    />
                  </g>
                </g>
              </svg>

              <div
                style={{
                  position: 'absolute',
                  top: 10,
                  left: 32,
                  width: 17,
                  height: 17,
                  backgroundColor: 'white',
                  borderRadius: 10,
                  opacity: checked ? 0 : 1,
                  transition: 'opacity 250ms ease-in-out',
                  pointerEvents: 'none'
                }}
              ></div>
              <Typography
                display='inline'
                fontWeight={400}
                textAlign='left'
                color='#060B19'
                fontSize='15px'
                sx={{ fontFamily: 'Roboto' }}
                lineHeight={'150%'}
              >
                Натискаючи “Отримати кредит”, я надаю згоду на обробку моїх
                персональних даних для оформлення заявки.
              </Typography>
            </Box>
          </Box>
        </Box>
      </Layout>
      <Box backgroundColor='#F3F3F3'>
        <Box width='400px' mx='auto' px='30px' pt='36px' pb='32px'>
          <GrayBtn>Зв'язатись з нами</GrayBtn>
          <Typography
            fontWeight={400}
            textAlign='left'
            color='#060B19'
            fontSize='15px'
            sx={{ fontFamily: 'Roboto' }}
            lineHeight='150%'
            marginTop='26px'
            marginBottom='18px'
            width='250px'
          >
            ТОВАРИСТВО З ОБМЕЖЕНОЮ ВІПОВІДАЛЬНІСТЮ «ЛІД ЛІДЕР» Код за ЄДРПОУ
            44740684
          </Typography>
          <Typography
            fontWeight={400}
            textAlign='left'
            color='#060B19'
            fontSize='15px'
            sx={{ fontFamily: 'Roboto' }}
            lineHeight={'150%'}
            margintop={'21px'}
            width='180px'
          >
            © 2022-2023 Lead Leader All Rights Reserved
          </Typography>
        </Box>
      </Box>
    </>
  )
}

export default Oschadbank
