import axios from 'axios'
import {
  changeFilter,
  changePassword,
  changePasswordFailure,
  changePasswordSuccess,
  checkAuthenticated,
  checkAuthenticatedFailure,
  checkAuthenticatedSuccess,
  createOffer,
  createOfferFailure,
  createOfferSuccess,
  createPartner,
  createPartnerFailure,
  createPartnerSuccess,
  createUser,
  createUserFailure,
  createUserSuccess,
  deleteOffer,
  deleteOfferFailure,
  deleteOfferSuccess,
  deletePartner,
  deletePartnerFailure,
  deletePartnerSuccess,
  getConversions,
  getConversionsFailure,
  getConversionsSuccess,
  getOffers,
  getOffersFailure,
  getOffersSuccess,
  getPartners,
  getPartnersFailure,
  getPartnersSuccess,
  getProfiles,
  getProfilesFailure,
  getProfilesSuccess,
  loadUser,
  loadUserFailure,
  loadUserSuccess,
  login,
  loginFailure,
  loginSuccess,
  logout,
  profileUpdateByAdmin,
  profileUpdateByAdminFailure,
  profileUpdateByAdminSuccess,
  refreshToken,
  refreshTokenFailure,
  refreshTokenSuccess,
  registrationUser,
  registrationUserFailure,
  registrationUserSuccess,
  resetPassword,
  resetPasswordFailure,
  resetPasswordSuccess,
  updateOffer,
  updateOfferFailure,
  updateOfferSuccess,
  updatePartner,
  updatePartnerFailure,
  updatePartnerSuccess,
  updateUser,
  updateUserFailure,
  updateUserSuccess
} from './adminSlice'

export const checkUserAuthenticated = () => async dispatch => {
  if (localStorage.getItem('access')) {
    try {
      dispatch(checkAuthenticated())
      await axios.post(`${process.env.REACT_APP_API_URL}/token/verify/`, {
        token: localStorage.getItem('access')
      })
      dispatch(checkAuthenticatedSuccess())
    } catch (e) {
      dispatch(checkAuthenticatedFailure())
      dispatch(refreshUserToken())
    }
  } else {
    dispatch(checkAuthenticatedFailure())
    dispatch(refreshUserToken())
  }
}

export const loginUser =
  ({ email, password }) =>
  async dispatch => {
    try {
      dispatch(login())
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/token/`,
        {
          email,
          password
        }
      )
      dispatch(loginSuccess(response.data))
      dispatch(loadUserData())
    } catch (e) {
      dispatch(loginFailure(e.response.data.detail))
    }
  }

export const registrationNewUser =
  ({ email, first_name = '', user_type, webmaster_id = '', password }) =>
  async dispatch => {
    dispatch(registrationUser())
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/users/registration/create-user/`,
        {
          email,
          first_name,
          user_type,
          webmaster_id,
          password
        }
      )
      dispatch(registrationUserSuccess(response.data))
    } catch (e) {
      dispatch(registrationUserFailure())
    }
  }

export const resetPasswordUser = email => async dispatch => {
  try {
    dispatch(resetPassword())
    const response = await axios.post(
      `${process.env.REACT_APP_API_URL}/users/password/reset-password/`,
      { email }
    )
    dispatch(resetPasswordSuccess(response.data))
  } catch (e) {
    dispatch(resetPasswordFailure())
  }
}

const refreshUserToken = () => async dispatch => {
  if (localStorage.getItem('refresh')) {
    try {
      dispatch(refreshToken())
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/token/refresh/`,
        {
          refresh: `JWT ${localStorage.getItem('refresh')}`
        }
      )
      dispatch(refreshTokenSuccess(response.data))
    } catch (e) {
      dispatch(refreshTokenFailure())
    }
  } else {
    dispatch(refreshTokenFailure())
  }
}

export const loadUserData = () => async dispatch => {
  if (localStorage.getItem('access')) {
    try {
      dispatch(loadUser())
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/users/profile/get-profile/`,
        {
          headers: {
            Authorization: `JWT ${localStorage.getItem('access')}`
          }
        }
      )
      dispatch(loadUserSuccess(response.data))
    } catch (e) {
      dispatch(loadUserFailure(e.detail))
    }
  } else {
    dispatch(loadUserFailure('Login again'))
  }
}

export const updateUserData =
  (
    id,
    user_type,
    {
      email,
      first_name,
      last_name,
      offers,
      partner,
      webmaster_id,
      address,
      phone
    }
  ) =>
  async dispatch => {
    try {
      dispatch(updateUser)
      const response = await axios.put(
        `${process.env.REACT_APP_API_URL}/users/profile/${id}/`,
        {
          email,
          first_name,
          last_name,
          offers: offers.map(o => o.id),
          partner,
          webmaster_id,
          address,
          phone,
          user_type
        },
        {
          headers: {
            Authorization: `JWT ${localStorage.getItem('access')}`
          }
        }
      )
      dispatch(updateUserSuccess(response.data))
    } catch (e) {
      dispatch(updateUserFailure(e.data.message))
    }
  }

export const changeUserPassword =
  ({ old_password, new_password, re_new_password }) =>
  async dispatch => {
    try {
      dispatch(changePassword())
      await axios.put(
        `${process.env.REACT_APP_API_URL}/users/profile/update-password/`,
        {
          old_password,
          new_password,
          re_new_password
        },
        {
          headers: {
            Authorization: `JWT ${localStorage.getItem('access')}`
          }
        }
      )
      dispatch(changePasswordSuccess())
    } catch (e) {
      dispatch(changePasswordFailure(e.data.message))
    }
  }

export const logoutUser = () => dispatch => {
  dispatch(logout())
}

export const getConversionsList =
  ({ start_date, end_date } = {}) =>
  async dispatch => {
    try {
      dispatch(getConversions())
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/admin/main/conversion/`,
        {
          headers: {
            Authorization: `JWT ${localStorage.getItem('access')}`
          },
          params: {
            conversion_time_after: start_date,
            conversion_time_before: end_date
          }
        }
      )
      dispatch(getConversionsSuccess(response.data))
    } catch (e) {
      dispatch(getConversionsFailure(e.data.message))
    }
  }

export const getProfilesList = () => async dispatch => {
  try {
    dispatch(getProfiles())
    const response = await axios.get(
      `${process.env.REACT_APP_API_URL}/admin/users/customuser/`,
      {
        headers: {
          Authorization: `JWT ${localStorage.getItem('access')}`
        }
      }
    )
    dispatch(getProfilesSuccess(response.data))
  } catch (e) {
    dispatch(getProfilesFailure(e.data.message))
  }
}

export const getPartnersList = () => async dispatch => {
  if (localStorage.getItem('access')) {
    try {
      dispatch(getPartners())
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/admin/main/partner/`,
        {
          headers: {
            Authorization: `JWT ${localStorage.getItem('access')}`
          }
        }
      )
      dispatch(getPartnersSuccess(response.data))
    } catch (e) {
      dispatch(getPartnersFailure(e.data))
    }
  } else {
    dispatch(getPartnersFailure('not logged in'))
  }
}

export const profileInfoUpdateByAdmin =
  (
    id,
    {
      email,
      first_name,
      last_name,
      offers,
      partner,
      webmaster_id,
      address,
      phone,
      conversion_payment,
      balance,
      user_type
    }
  ) =>
  async dispatch => {
    try {
      dispatch(profileUpdateByAdmin())
      await axios.put(
        `${process.env.REACT_APP_API_URL}/admin/users/customuser/${id}/`,
        {
          email,
          first_name,
          last_name,
          offers: offers.map(o => o.id),
          partner,
          webmaster_id,
          address,
          phone,
          conversion_payment,
          balance,
          user_type
        },
        {
          headers: {
            Authorization: `JWT ${localStorage.getItem('access')}`
          }
        }
      )
      dispatch(profileUpdateByAdminSuccess())
      dispatch(getProfilesList())
    } catch (e) {
      dispatch(profileUpdateByAdminFailure(e.data.message))
    }
  }

export const createCustomUser =
  ({
    email,
    first_name = '',
    last_name = '',
    offers = [],
    partner,
    webmaster_id,
    address = '',
    phone = '',
    user_type,
    password
  }) =>
  async dispatch => {
    if (localStorage.getItem('access')) {
      dispatch(createUser())
      try {
        await axios.post(
          `${process.env.REACT_APP_API_URL}/users/registration/create-user/`,
          {
            email,
            first_name,
            last_name,
            offers,
            partner,
            webmaster_id,
            address,
            phone,
            user_type,
            password
          },
          {
            headers: {
              Authorization: `JWT ${localStorage.getItem('access')}`
            }
          }
        )
        dispatch(createUserSuccess())
        dispatch(getProfilesList())
      } catch (e) {
        dispatch(createUserFailure(e.data.message))
      }
    } else {
      dispatch(createUserFailure('Not logged in'))
    }
  }

export const getOffersList = () => async dispatch => {
  if (localStorage.getItem('access')) {
    try {
      dispatch(getOffers())
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/admin/loan_offers/loanoffer/`,
        {
          headers: {
            Authorization: `JWT ${localStorage.getItem('access')}`
          }
        }
      )
      dispatch(getOffersSuccess(response.data))
    } catch (e) {
      dispatch(getOffersFailure(e.data))
    }
  } else {
    dispatch(getOffersFailure('not logged in'))
  }
}

export const updateOfferInfo = offerData => async dispatch => {
  const {
    id,
    company,
    company_logo_url,
    interest_rate,
    loan_terms,
    loan_info,
    max_loan_amount,
    loan_offer_url,
    showcase_position,
    conversion_payment,
    commission,
    send_click_id
  } = offerData
  if (localStorage.getItem('access')) {
    try {
      dispatch(updateOffer())
      await axios.put(
        `${process.env.REACT_APP_API_URL}/admin/loan_offers/loanoffer/${id}/`,
        {
          company,
          company_logo_url,
          interest_rate,
          loan_terms,
          loan_info,
          max_loan_amount,
          loan_offer_url,
          showcase_position,
          conversion_payment,
          commission,
          send_click_id
        },
        {
          headers: {
            Authorization: `JWT ${localStorage.getItem('access')}`
          }
        }
      )
      dispatch(updateOfferSuccess())
      dispatch(getOffersList())
    } catch (e) {
      dispatch(updateOfferFailure(e.data))
    }
  } else {
    dispatch(updateOfferFailure('not logged in'))
  }
}

export const updatePartnerInfo =
  ({ id, company_id, company_name }) =>
  async dispatch => {
    if (localStorage.getItem('access')) {
      try {
        dispatch(updatePartner())
        await axios.patch(
          `${process.env.REACT_APP_API_URL}/admin/main/partner/${id}/`,
          {
            company_id,
            company_name
          },
          {
            headers: {
              Authorization: `JWT ${localStorage.getItem('access')}`
            }
          }
        )
        dispatch(updatePartnerSuccess())
        dispatch(getPartnersList())
      } catch (e) {
        dispatch(updatePartnerFailure(e.data))
      }
    } else {
      dispatch(updatePartnerFailure('not logged in'))
    }
  }

export const createNewOffer = offerData => async dispatch => {
  const {
    company,
    company_logo_url,
    interest_rate,
    loan_info,
    loan_offer_url,
    loan_terms,
    max_loan_amount,
    showcase_position,
    conversion_payment,
    commission,
    send_click_id
  } = offerData
  if (localStorage.getItem('access')) {
    try {
      dispatch(createOffer)
      await axios.post(
        `${process.env.REACT_APP_API_URL}/admin/loan_offers/loanoffer/`,
        {
          company,
          company_logo_url,
          interest_rate,
          loan_info,
          loan_offer_url,
          loan_terms,
          max_loan_amount,
          showcase_position,
          conversion_payment,
          commission,
          send_click_id
        },
        {
          headers: {
            Authorization: `JWT ${localStorage.getItem('access')}`
          }
        }
      )
      dispatch(createOfferSuccess())
      dispatch(getOffersList())
    } catch (e) {
      dispatch(createOfferFailure(e.data))
    }
  } else {
    dispatch(createOfferFailure('not logged in'))
  }
}

export const createNewPartner = partnerData => async dispatch => {
  const { company_name, company_id } = partnerData
  if (localStorage.getItem('access')) {
    try {
      dispatch(createPartner)
      await axios.post(
        `${process.env.REACT_APP_API_URL}/admin/main/partner/`,
        {
          company_name,
          company_id
        },
        {
          headers: {
            Authorization: `JWT ${localStorage.getItem('access')}`
          }
        }
      )
      dispatch(createPartnerSuccess())
      dispatch(getPartnersList())
    } catch (e) {
      dispatch(createPartnerFailure(e.data))
    }
  } else {
    dispatch(createPartnerFailure('not logged in'))
  }
}

export const deleteOfferByID = id => async dispatch => {
  if (localStorage.getItem('access')) {
    try {
      dispatch(deleteOffer())
      await axios.delete(
        `${process.env.REACT_APP_API_URL}/admin/loan_offers/loanoffer/${id}/`,
        {
          headers: {
            Authorization: `JWT ${localStorage.getItem('access')}`
          }
        }
      )
      deleteOfferSuccess()
      dispatch(getOffersList())
    } catch (e) {
      dispatch(deleteOfferFailure(e.data))
    }
  } else {
    dispatch(deleteOfferFailure('not logged in'))
  }
}

export const deletePartnerByID = id => async dispatch => {
  if (localStorage.getItem('access')) {
    try {
      dispatch(deletePartner())
      await axios.delete(
        `${process.env.REACT_APP_API_URL}/admin/main/partner/${id}/`,
        {
          headers: {
            Authorization: `JWT ${localStorage.getItem('access')}`
          }
        }
      )
      dispatch(deletePartnerSuccess())
      dispatch(getPartnersList())
    } catch (e) {
      dispatch(deletePartnerFailure(e.data))
    }
  } else {
    dispatch(deletePartnerFailure('not logged in'))
  }
}

export const changeFilterConversions = (event) => async dispatch => {
  const token = localStorage.getItem('access')
  if (token) {
     try {
      await axios.patch(
        `${process.env.REACT_APP_API_URL}/users/profile/get-update-filter-conversions/`,
        { filter_conversions: event },
        {
          headers: {
            Authorization: `JWT ${token}`
          }
        }
      )
  } catch(e) {}
  dispatch(changeFilter(event))
 }
}

export const getFilterConversions = () => async dispatch => {
  const token = localStorage.getItem('access')
  if (token) {
     try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/users/profile/get-update-filter-conversions/`,
        {
          headers: {
            Authorization: `JWT ${token}`
          }
        }
        )
        dispatch(changeFilter(response.data.filter_conversions))
  } catch(e) {}
 }
}
