import React from 'react'
import { useSelector } from 'react-redux'
import { Alert, Box, CircularProgress } from '@mui/material'
import StatisticsPanel from '../../components/StatisticsPanel/StatisticsPanel'
import './ConversionsList.scss'

const StatisticsList = ({ setActiveStats }) => {
  const { isLoading, error } = useSelector(state => state.admin)

  if (error) return <Alert severity='error'>{error}</Alert>
  return (
    <Box>
      {isLoading && <CircularProgress />}
      <StatisticsPanel setActiveStats={setActiveStats} />
    </Box>
  )
}

export default StatisticsList
