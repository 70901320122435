import './Registration.scss'
import React, { useState, useEffect } from 'react'
import Logo from '../../../../assets/images/Logo-registration.svg'
import { Link } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { registrationNewUser } from 'store/reducers/admin/adminActions'
import { Header } from '../../../../components/homeNew/Header'

export const Registration = () => {
  const dispatch = useDispatch()
  const { isAuthenticated, messageRegistration } = useSelector(
    state => state.admin
  )
  const navigate = useNavigate()

  const [userData, setUserData] = useState({
    email: '',
    first_name: '',
    user_type: 'webmaster',
    webmaster_id: null,
    password: ''
  })

  const [repeatPassword, setRepeatPassword] = useState('')
  const [agreement, setAgreement] = useState(false)

  const [passwordClass, setPasswordClass] = useState('registration__item')
  const [togleMessage, setTogleMessage] = useState(false)

  const { email, first_name, user_type, webmaster_id, password } = userData

  const onChange = event => {
    setUserData({ ...userData, [event.target.name]: event.target.value })
  }

  const onChangeRessetPassword = event => {
    setRepeatPassword(event.target.value)
    if (event.target.value !== password) {
      setPasswordClass('registration__item-error')
    } else {
      setPasswordClass('registration__item')
    }
  }

  const handleClick = () => {
    if (
      email !== '' &&
      first_name !== '' &&
      password !== '' &&
      (user_type === 'webmaster' ? webmaster_id !== '' : true) &&
      agreement &&
      password === repeatPassword
    ) {
      dispatch(registrationNewUser(userData))
      setTogleMessage(true)
    }
  }

  const handleSubmit = event => {
    event.preventDefault()
  }

  const handleCheked = event => {
    const input = event.target
    const value = input.type === 'checkbox' ? input.checked : input.value
    setAgreement(value)
  }

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  useEffect(() => {
    if (isAuthenticated) return navigate('/admin/')
  }, [isAuthenticated, navigate, messageRegistration])

  return (
    <main className='registration'>
      <div className='login__header'>
        <Header />
      </div>
      <div className='registration__container'>
        <div className='registration__block-menu'>
          <Link className='registration__logo' to='/'>
            <img className='registration__logo-img' src={Logo} alt='logo' />
          </Link>
          <Link to='/' className='registration__button-close'></Link>
        </div>
        <h2 className='registration__title'>Реєстрація:</h2>
        <form onSubmit={handleSubmit} className='registration__form'>
          <div onChange={onChange} className='registration__button-block'>
            <input
              type='radio'
              id='webmaster'
              name='user_type'
              value='webmaster'
              className='registration__button-item registration__button-item--web'
              checked={user_type === 'webmaster'}
            />
            <label className='registration__button-label' for='webmaster'>
              Вебмайстер
            </label>

            <input
              type='radio'
              id='advertiser'
              name='user_type'
              value='advertiser'
              className='registration__button-item registration__button-item--seo'
              checked={user_type === 'advertiser'}
            />
            <label className='registration__button-label' for='advertiser'>
              Рекламодавець
            </label>
          </div>

          <div className='registration__personal-information'>
            <input
              type='text'
              name='first_name'
              className='registration__item'
              placeholder='Ім’я'
              value={first_name}
              onChange={onChange}
              required
            />

            <input
              type='email'
              name='email'
              className='registration__item'
              placeholder='E-mail'
              value={email}
              onChange={onChange}
              required
            />

            {user_type === 'webmaster' && (
              <input
                type='text'
                name='webmaster_id'
                className='registration__item'
                placeholder='ID вебмайстра'
                value={webmaster_id}
                onChange={onChange}
                required
              />
            )}

            <input
              type='password'
              name='password'
              className='registration__item'
              placeholder='Пароль'
              value={password}
              onChange={onChange}
              required
            />

            <input
              type='password'
              name='repeat_password'
              className={passwordClass}
              placeholder='Повторіть пароль'
              value={repeatPassword}
              onChange={onChangeRessetPassword}
              required
            />
          </div>
          <div className='registration__agreement'>
            <input
              checked={agreement}
              onChange={e => handleCheked(e)}
              type='checkbox'
              name='agreement'
              value='agreement'
              className='registration__agreement-input'
              required
            />
            <p className='registration__agreement-label'>
              Приймаю
              <span className='registration__agreement-link'>
                договір публичної оферти
              </span>
            </p>
          </div>
          {messageRegistration && togleMessage && (
            <p className='registration__messege'>
              Аккаунт ще не активовано. Очікуйте повідомлення на електронну
              пошту
            </p>
          )}
          <button className='registration__button' onClick={handleClick}>
            Зареєструватись
          </button>
        </form>
        <div className='registration__come-in'>
          <p className='registration__come-in-text'>Ви вже зареєстровані?</p>
          <Link to='/admin/login/' className='registration__come-in-link'>
            Увійти
          </Link>
        </div>
      </div>
    </main>
  )
}
export default Registration
