import {combineReducers, configureStore} from '@reduxjs/toolkit'
import loanSlice from './reducers/loans/loanSlise'
import adminSlice from './reducers/admin/adminSlice'

const rootReducer = combineReducers({
    admin: adminSlice,
    loans: loanSlice
})

export const setupStore = () => {
    return configureStore({
        devTools: process.env.REACT_APP_DEBUG === 'true',
        reducer: rootReducer
    })
}