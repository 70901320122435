import styled from '@emotion/styled'
import { Button, TextField } from '@mui/material'

export const ColoredTextField = styled(TextField)({
  // maxHeight: '48px ',
  '& label': {
    fontFamily: 'Roboto',
    color: '#060B19',
    lineHeight: '180%',
    fontSize: 18
  },
  '& input': {
    fontFamily: 'Roboto',
    fontSize: 18
  },
  '& .MuiSelect-select': {
    fontFamily: 'Roboto',
    borderColor: '#CCCCCC',
    borderWidth: 1
  },
  '& input + fieldset': {
    borderColor: '#CCCCCC',
    borderWidth: 1,
    fontFamily: 'Roboto',
    padding: '12.5px 14px'
  },
  '& .Mui-error': {
    marginBottom: -5
  },
  '& .MuiOutlinedInput-input': {
    padding: '8.5px 3px 9.5px'
  },
  '& .MuiOutlinedInput-root': {
    height: '48px !important',
    padding: '12.5px 14px',
    '& fieldset': {
      borderColor: '#CCCCCC',
      borderWidth: 1,
      fontFamily: 'Roboto'
    }
  },
  ':has( .Mui-error)': {
    marginBottom: '14px'
  }
})

export const MyButton = styled(Button)({
  position: 'relative',
  backgroundColor: '#0463EF',
  width: '340px',
  height: '60px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  borderRadius: '4px',
  color: 'white',
  textTransform: 'uppercase',
  fontFamily: 'Rubik',
  fontSize: 24,
  marginBottom: 18,

  '&:hover': {
    backgroundColor: '#0463EF',
    color: 'white'
  }
})

export const GrayBtn = styled(MyButton)({
  backgroundColor: '#F3F3F3',
  color: '#060B19',
  textTransform: 'none',
  border: '2px solid #0463EF',
  fontSize: 28,

  '&:hover': {
    backgroundColor: '#F3F3F3',
    color: '#060B19'
  }
})

export const ModalBtn = styled(MyButton)`
  width: 100%;
  padding: 0;
  display: grid;
  place-items: center;
  text-transform: none;
  margin-bottom: 0;
`
