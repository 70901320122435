import { useEffect } from 'react'
import { Button } from '@mui/material'
import {
  GridFilterListIcon,
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarDensitySelector
} from '@mui/x-data-grid'
import { CustomFilterPanel } from './CustomFilterPanel'
import './CustomGridToolbar.scss'
import CustomExportToolbar from './CustomExportToolbar'
import { useGridApiContext } from '@mui/x-data-grid'

export function CustomGridToolbar(props) {
  const gridApiContext = useGridApiContext()

  useEffect(() => {
    localStorage.setItem('density', gridApiContext.current.state.density.value)
  }, [gridApiContext, gridApiContext.current.state.density.value])

  return (
    <GridToolbarContainer>
      <GridToolbarColumnsButton />
      {props.hideFilters || (
        <Button
          onClick={props.toggleFilterPanel}
          startIcon={<GridFilterListIcon />}
          style={{ fontSize: '13px' }}
        >
          Фільтри
        </Button>
      )}
      <GridToolbarDensitySelector />
      <CustomExportToolbar rows={props.rows} columns={props.columns} />
      {props.hideFilters || <CustomFilterPanel props={props.filterPanel} />}
    </GridToolbarContainer>
  )
}
