import { Footer } from '../components/homeNew/Footer';
import { Header } from '../components/homeNew/Header';
import { useRef } from 'react';
import './HomeNew.scss';

const Home = () => {
  const contactsRef = useRef(null);
 
  const contactsScroll = () => contactsRef.current.scrollIntoView({behavior: 'smooth'});
  
  return (
    <div className="home">
      <Header
        contactsScroll={contactsScroll}
      />
      <Footer
       contactsRef={contactsRef} 
      />
     </div>
  )
}

export default Home
