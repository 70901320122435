import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  Box,
  Button,
  Checkbox,
  ListItemText,
  MenuItem,
  Stack,
  styled,
  TextField,
  useTheme
} from '@mui/material'

import { userTypes } from 'data/constants'
import { getPartnersList } from 'store/reducers/admin/adminActions'

const ColoredTextField = styled(TextField)({
  '& label': {
    color: '#0276aa'
  },
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: '#0276aa',
      borderWidth: 1
    }
  },
  '& input + fieldset': {
    borderColor: '#0276aa',
    borderWidth: 1
  }
})

const EditProfileInfo = ({
  email,
  first_name,
  last_name,
  offers,
  partner,
  webmaster_id,
  address,
  phone,
  user_type,
  handleChange,
  handleClick,
  disableUserTypeChoice,
  isCreation
}) => {
  const { user, adminOffers, partners } = useSelector(state => state.admin)

  const dispatch = useDispatch()

  const theme = useTheme()

  useEffect(() => {
    dispatch(getPartnersList())
  }, [dispatch])

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        width: '50%',
        alignItems: 'center',
        justifyContent: 'center',
        margin: 'auto !important'
      }}
    >
      <Stack
        sx={{
          // flexGrow: 1,
          display: 'flex',
          flexDirection: { xs: 'column', md: 'row' },
          gap: { xs: '10px', md: '30px' },
          justifyContent: 'center',
          alignItems: 'center'
        }}
        // spacing={2}
      >
        <Stack
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: { xs: '10px', md: '24px' },
            justifyContent: 'center',
            alignItems: 'center'
          }}
        >
          <ColoredTextField
            sx={{
              mr: theme.spacing(0.5),
              width: { xs: '280px', md: '240px' },
              borderColor: '#00829b',
              borderWidth: 1
            }}
            variant='outlined'
            size='small'
            label='Email'
            type='email'
            name='email'
            value={email || ''}
            onChange={e => handleChange(e)}
          ></ColoredTextField>
          <ColoredTextField
            sx={{
              mr: theme.spacing(0.5),
              width: { xs: '280px', md: '240px' },
              borderColor: '#00829b',
              borderWidth: 1
            }}
            variant='outlined'
            size='small'
            label="Ім'я"
            type='text'
            name='first_name'
            value={first_name || ''}
            onChange={e => handleChange(e)}
          ></ColoredTextField>
          <ColoredTextField
            sx={{
              mr: theme.spacing(0.5),
              width: { xs: '280px', md: '240px' },
              borderColor: '#00829b',
              borderWidth: 1
            }}
            variant='outlined'
            size='small'
            label='Прізвище'
            type='text'
            name='last_name'
            value={last_name || ''}
            onChange={e => handleChange(e)}
          ></ColoredTextField>
          {/* <TextField
            sx={{ mr: theme.spacing(0.5), width: '240px' }}
            variant='standard'
            label='Баланс'
            type='number'
            name='balance'
            value={balance || ''}
            onChange={e => handleChange(e)}
          ></TextField> */}
        </Stack>
        <Stack
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: { xs: '10px', md: '24px' },
            justifyContent: 'center',
            alignItems: 'center'
          }}
        >
          {user.user_type === 'advertiser' ||
          (!disableUserTypeChoice && user_type === 'advertiser') ? (
            <ColoredTextField
              required
              select
              sx={{
                mr: theme.spacing(0.5),
                width: { xs: '280px', md: '240px' },
                borderColor: '#00829b',
                borderWidth: 1
              }}
              variant='outlined'
              size='small'
              label='Партнер'
              name='partner'
              value={partner || ''}
              onChange={e => handleChange(e)}
            >
              {partners.map(option => (
                <MenuItem key={option.id} value={option.id}>
                  {option.company_name}
                </MenuItem>
              ))}
            </ColoredTextField>
          ) : user.user_type === 'webmaster' ||
            (!disableUserTypeChoice && user_type === 'webmaster') ? (
            <>
              <ColoredTextField
                required
                sx={{
                  mr: theme.spacing(0.5),
                  width: { xs: '280px', md: '240px' },
                  borderColor: '#00829b',
                  borderWidth: 1
                }}
                variant='outlined'
                size='small'
                label='ID вебмайстра'
                name='webmaster_id'
                value={webmaster_id}
                onChange={e => handleChange(e)}
              />
              <ColoredTextField
                required
                select
                sx={{
                  mr: theme.spacing(0.5),
                  width: { xs: '280px', md: '240px' },
                  borderColor: '#00829b',
                  borderWidth: 1
                }}
                variant='outlined'
                size='small'
                label='Офери'
                name='offers'
                SelectProps={{
                  multiple: true,
                  value: offers || [],
                  renderValue: selected =>
                    selected
                      .map(
                        s =>
                          adminOffers.find(p => p.id === s)?.company
                            .company_name
                      )
                      .join(', '),
                  onChange: e => handleChange(e),
                  width: { xs: '280px', md: '240px' },
                  borderColor: '#00829b',
                  borderWidth: 1
                }}
              >
                {adminOffers.map(option => (
                  <MenuItem key={option.id} value={option.id}>
                    <Checkbox checked={offers?.includes(option.id)} />
                    <ListItemText primary={option.company.company_name} />
                  </MenuItem>
                ))}
              </ColoredTextField>
            </>
          ) : null}
          {/* <ColoredTextField
            sx={{
              mr: theme.spacing(0.5),
              width: { xs: '280px', md: '240px' },
              borderColor: '#00829b',
              borderWidth: 1
            }}
            variant='outlined'
            size='small'
            label='Адреса'
            type='text'
            name='address'
            value={address || ''}
            onChange={e => handleChange(e)}
          ></ColoredTextField> */}
          <ColoredTextField
            sx={{
              mr: theme.spacing(0.5),
              width: { xs: '280px', md: '240px' },
              borderColor: '#00829b',
              borderWidth: 1
            }}
            variant='outlined'
            size='small'
            label='Телефон'
            type='tel'
            name='phone'
            value={phone || ''}
            onChange={e => handleChange(e)}
          ></ColoredTextField>
          {/* <TextField
            sx={{ mr: theme.spacing(0.5), width: '240px' }}
            variant='standard'
            label='Плата за конверсію'
            type='number'
            name='conversion_payment'
            value={conversion_payment || ''}
            onChange={e => handleChange(e)}
          ></TextField>+ */}
        </Stack>
      </Stack>

      {!disableUserTypeChoice && (
        <ColoredTextField
          required
          id='outlined-select-currency'
          sx={{
            mt: theme.spacing(2),
            width: '240px',
            borderColor: '#00829b',
            borderWidth: 1
          }}
          variant='outlined'
          size='small'
          select
          label='Тип користувача'
          name='user_type'
          value={user_type || ''}
          onChange={handleChange}
        >
          {userTypes.map(option => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </ColoredTextField>
      )}
      {!isCreation && (
        <Button
          sx={{ mt: '30px' }}
          size='medium'
          variant='contained'
          color='success'
          onClick={handleClick}
        >
          Зберегти
        </Button>
      )}
    </Box>
  )
}

export default EditProfileInfo
