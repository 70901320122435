import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  isLoading: true,
  isLoadingConversions: false,
  error: '',
  isAuthenticated: true,
  user: {},
  conversions: [],
  profiles: [],
  partners: [],
  adminOffers: [],
  message: '',
  messageRegistration: '',
  filterConversions: true,
  filteredConversions: []
}

const adminSlice = createSlice({
  name: 'admin',
  initialState,
  reducers: {
    checkAuthenticated: state => {
      state.isLoading = true
    },
    checkAuthenticatedSuccess: state => {
      state.isLoading = false
      state.isAuthenticated = true
    },
    checkAuthenticatedFailure: state => {
      state.isLoading = false
      state.isAuthenticated = false
    },
    login: state => {
      state.isLoading = true
    },
    loginSuccess: (state, action) => {
      state.isLoading = false
      state.isAuthenticated = true
      state.error = ''
      localStorage.setItem('access', action.payload.access)
      localStorage.setItem('refresh', action.payload.refresh)
    },
    loginFailure: (state, action) => {
      state.isLoading = false
      state.isAuthenticated = false
      state.error = action.payload
      localStorage.removeItem('access')
      localStorage.removeItem('refresh')
    },

    registrationUser: state => {
      state.isLoading = true
    },

    registrationUserSuccess: (state, action) => {
      state.isLoading = false
      // state.isAuthenticated = true
      // state.error = ''
      state.messageRegistration = action.payload.message
      // localStorage.setItem('access', action.payload.access)
      // localStorage.setItem('refresh', action.payload.refresh)
    },

    registrationUserFailure: (state, action) => {
      state.isLoading = false
      // state.isAuthenticated = false
      // state.error = action.payload
      // localStorage.removeItem('access')
      // localStorage.removeItem('refresh')
    },

    resetPassword: state => {
      state.isLoading = true
    },
    resetPasswordSuccess: (state, action) => {
      state.isLoading = false
      state.message = action.payload.message
    },
    resetPasswordFailure: (state, action) => {
      state.isLoading = false
    },

    refreshToken: state => {
      state.isLoading = true
    },
    refreshTokenSuccess: (state, action) => {
      state.isLoading = false
      state.isAuthenticated = true
      localStorage.setItem('access', action.payload.access)
      localStorage.setItem('refresh', action.payload.refresh)
    },
    refreshTokenFailure: state => {
      state.isLoading = false
      state.isAuthenticated = false
      localStorage.removeItem('access')
      localStorage.removeItem('refresh')
    },
    logout: state => {
      state.isAuthenticated = false
      state.user = ''
      localStorage.removeItem('access')
      localStorage.removeItem('refresh')
    },
    loadUser: state => {
      state.isLoading = true
    },
    loadUserSuccess: (state, action) => {
      state.isLoading = false
      state.user = action.payload
    },
    loadUserFailure: (state, action) => {
      state.isLoading = false
      state.isAuthenticated = false
      state.user = {}
      state.error = action.payload
      localStorage.removeItem('access')
      localStorage.removeItem('refresh')
    },
    updateUser: state => {
      state.isLoading = true
    },
    updateUserSuccess: (state, action) => {
      state.isLoading = false
      state.error = ''
      state.user = action.payload
    },
    updateUserFailure: (state, action) => {
      state.isLoading = false
      state.error = action.payload
    },
    changePassword: state => {
      state.isLoading = true
    },
    changePasswordSuccess: state => {
      state.isLoading = false
      state.error = ''
    },
    changePasswordFailure: (state, action) => {
      state.isLoading = false
      state.error = action.payload
    },
    getConversions: state => {
      state.isLoadingConversions = true
    },
    getConversionsSuccess: (state, action) => {
      state.isLoadingConversions = false
      state.error = ''
      state.conversions = action.payload
    },
    getConversionsFailure: (state, action) => {
      state.isLoadingConversions = false
      state.error = action.payload
      state.conversions = []
    },
    updateConversion: state => {
      state.isLoading = true
    },
    updateConversionSuccess: state => {
      state.isLoading = false
      state.error = ''
    },
    updateConversionFailure: (state, action) => {
      state.isLoading = false
      state.error = action.payload
    },
    getProfiles: state => {
      state.isLoading = true
    },
    getProfilesSuccess: (state, action) => {
      state.isLoading = false
      state.error = ''
      state.profiles = action.payload
    },
    getProfilesFailure: (state, action) => {
      state.isLoading = false
      state.error = action.payload
      state.profiles = []
    },
    profileUpdateByAdmin: state => {
      state.isLoading = true
    },
    profileUpdateByAdminSuccess: state => {
      state.isLoading = false
      state.error = ''
    },
    profileUpdateByAdminFailure: (state, action) => {
      state.isLoading = false
      state.error = action.payload
    },
    createUser: state => {
      state.isLoading = true
    },
    createUserSuccess: state => {
      state.isLoading = false
      state.error = ''
    },
    createUserFailure: (state, action) => {
      state.isLoading = false
      state.error = action.payload
    },
    getOffers: state => {
      state.isLoading = true
    },
    getOffersSuccess: (state, action) => {
      state.isLoading = false
      state.adminOffers = action.payload
      state.error = ''
    },
    getOffersFailure: (state, action) => {
      state.isLoading = false
      state.adminOffers = []
      state.error = action.payload
    },
    updateOffer: state => {
      state.isLoading = true
    },
    updateOfferSuccess: state => {
      state.isLoading = false
      state.error = ''
    },
    updateOfferFailure: (state, action) => {
      state.isLoading = false
      state.error = action.payload
    },
    getPartners: state => {
      state.isLoading = true
    },
    getPartnersSuccess: (state, action) => {
      state.isLoading = false
      state.error = ''
      state.partners = action.payload
    },
    getPartnersFailure: (state, action) => {
      state.isLoading = false
      state.error = action.payload
      state.partners = []
    },
    updatePartner: state => {
      state.isLoading = true
    },
    updatePartnerSuccess: state => {
      state.isLoading = false
      state.error = ''
    },
    updatePartnerFailure: (state, action) => {
      state.isLoading = false
      state.error = action.payload
    },
    createOffer: state => {
      state.isLoading = true
    },
    createOfferSuccess: (state, action) => {
      state.isLoading = false
      state.error = ''
    },
    createOfferFailure: (state, action) => {
      state.isLoading = false
      state.error = action.payload
    },
    createPartner: state => {
      state.isLoading = true
    },
    createPartnerSuccess: state => {
      state.isLoading = false
      state.error = ''
    },
    createPartnerFailure: (state, action) => {
      state.isLoading = false
      state.error = action.payload
    },
    deleteOffer: state => {
      state.isLoading = true
    },
    deleteOfferSuccess: state => {
      state.isLoading = false
      state.error = ''
    },
    deleteOfferFailure: (state, action) => {
      state.isLoading = false
      state.error = action.payload
    },
    deletePartner: state => {
      state.isLoading = true
    },
    deletePartnerSuccess: state => {
      state.isLoading = false
      state.error = ''
    },
    deletePartnerFailure: (state, action) => {
      state.isLoading = false
      state.error = action.payload
    },
    changeFilter: (state, action) => {
      state.filterConversions = action.payload
    },
    setFilteredConversions: (state, action) => {
      state.filteredConversions = action.payload
    }
  }
})

export const {
  setFilteredConversions,
  setPartnerId,
  checkAuthenticated,
  checkAuthenticatedSuccess,
  checkAuthenticatedFailure,
  login,
  loginSuccess,
  loginFailure,
  registrationUser,
  registrationUserSuccess,
  registrationUserFailure,
  resetPassword,
  resetPasswordSuccess,
  resetPasswordFailure,
  refreshToken,
  refreshTokenSuccess,
  refreshTokenFailure,
  logout,
  loadUser,
  loadUserSuccess,
  loadUserFailure,
  updateConversion,
  updateConversionSuccess,
  updateConversionFailure,
  updateUser,
  updateUserSuccess,
  updateUserFailure,
  changePassword,
  changePasswordSuccess,
  changePasswordFailure,
  getConversions,
  getConversionsSuccess,
  getConversionsFailure,
  getProfiles,
  getProfilesSuccess,
  getProfilesFailure,
  getPartners,
  getPartnersSuccess,
  getPartnersFailure,
  profileUpdateByAdmin,
  profileUpdateByAdminSuccess,
  profileUpdateByAdminFailure,
  createUser,
  createUserFailure,
  createUserSuccess,
  getOffers,
  getOffersSuccess,
  getOffersFailure,
  updateOffer,
  updateOfferSuccess,
  updateOfferFailure,
  updatePartner,
  updatePartnerSuccess,
  updatePartnerFailure,
  createOffer,
  createOfferSuccess,
  createOfferFailure,
  createPartner,
  createPartnerSuccess,
  createPartnerFailure,
  deleteOffer,
  deleteOfferFailure,
  deleteOfferSuccess,
  deletePartner,
  deletePartnerFailure,
  deletePartnerSuccess,
  changeFilter
} = adminSlice.actions

export default adminSlice.reducer
