import React, {useEffect, useState} from 'react'
import {useDispatch} from 'react-redux'
import EditPartnerInfo from './edit/EditPartnerInfo'
import {createNewPartner} from 'store/reducers/admin/adminActions'
import MyModal from 'UI/MyModal'

const initPartnerDataState = {
  company_id: '',
  company_name: ''
}

const CreatePartner = ({open, handleClose}) => {
  const dispatch = useDispatch()

  const [partnerData, setPartnerData] = useState(initPartnerDataState)

  const [isButtonActive, setIsButtonActive] = useState(false)

  const handleChange = e => setPartnerData(
      {...partnerData, [e.target.name]: e.target.value})

  const handleCreate = () => {
    dispatch(createNewPartner(partnerData))
    setPartnerData(initPartnerDataState)
    handleClose()
  }

  useEffect(() => {
    setIsButtonActive((partnerData.company_name !== null &&
        partnerData.company_name !== '') && (partnerData.company_id !== null && partnerData.company_id !== '' &&
        /^[a-z0-9\-_]+$/.test(partnerData.company_id)))
  }, [partnerData])

  return (
      <MyModal open={open} onClose={handleClose}>
        <EditPartnerInfo partnerData={partnerData} handleChange={handleChange} handleClick={handleCreate}
                         isCreation={true} buttonDisabled={!isButtonActive}/>
      </MyModal>
  )
}

export default CreatePartner