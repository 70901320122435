import styled from '@emotion/styled'
import logo from '../../assets/images/Logo.svg'
import { useNavigate } from 'react-router-dom'

const Header = () => {
  const navigate = useNavigate()

  const Background = styled.div`
    background-color: rgb(25, 118, 210);
    box-shadow: 0 3px 3px #00000037;
  `

  const HeaderStyled = styled.div`
    width: 100%;
    padding: 15px 30px;
    max-width: 400px;
    margin-inline: auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
    & img:nth-child(1) {
      cursor: pointer;
      width: 150px;
    }
  `

  return (
    <Background>
      <HeaderStyled>
        <img src={logo} alt='logo' onClick={() => navigate('/')} />
      </HeaderStyled>
    </Background>
  )
}

export default Header
