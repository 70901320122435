import React, { useEffect, useMemo, useState } from 'react'
import Tooltip from '@mui/material/Tooltip'
import { useDispatch, useSelector } from 'react-redux'
import {
  Alert,
  Box,
  Button,
  CircularProgress,
  Pagination,
  Stack
} from '@mui/material'
import {
  DataGrid,
  gridPageCountSelector,
  gridPageSelector,
  useGridApiContext,
  useGridSelector,
  ukUA
} from '@mui/x-data-grid'
import CountryFlag from 'react-country-flag'
import { CustomGridToolbar } from '../../../src/UI/CustomGridToolbar'
import moment from 'moment'
import return8DigitNumber from 'utils/return8DigitNumber'
import renderCellExpand from '../../UI/RenderCellExpand'
import { CustomCheckbox } from 'UI/CustomCheckbox.js'
import './ConversionsList.scss'
import { getOffersList } from 'store/reducers/admin/adminActions'
import { setFilteredConversions } from 'store/reducers/admin/adminSlice'
import { getCode } from 'country-list'
import { StatusOperators, CompanyNameOperators, GoalOperators, WebmasterIdOperators } from '../../UI/CustomFilterColumn'


export function CustomPagination() {
  const apiRef = useGridApiContext()
  const page = useGridSelector(apiRef, gridPageSelector)
  const pageCount = useGridSelector(apiRef, gridPageCountSelector)

  return (
    <Pagination
      size='small'
      shape='rounded'
      count={pageCount}
      page={page + 1}
      onChange={(_, value) => apiRef.current.setPage(value - 1)}
    />
  )
}

const ConversionsList = () => {
  const {
    conversions,
    error,
    user,
    filterConversions,
    isLoadingConversions: isLoading,
    adminOffers
  } = useSelector(state => state.admin)

  const [pageSize, setPageSize] = useState(
    localStorage.setPage ? localStorage.setPage : 10
  )
  const [showFilterPanel, setShowFilterPanel] = useState(false)
  const [currentConversions, setCurrentConversions] = useState([])
  const [columnVisibilityModel, setColumnVisibilityModel] = useState({})
  const [currentFilter, setCurrentFilter] = useState({
    company_name: {},
    webmaster_id: {},
    status: {},
    goal: {}
  })

  const dispatch = useDispatch()

  const translations = useMemo(
    () => ({
      status: {
        active: 'Підтверджено',
        pending: 'В обробці',
        rejected: 'Відхилено',
        canceled: 'Відмінено',
        paid: 'Сплачено'
      },
      goal: {
        1: 'Перший кредит',
        2: 'Повторний кредит',
        3: 'Реєстрація нового клієнта',
        4: 'Кредитна картка'
      }
    }),
    []
  )

  

  const columns = useMemo(() => {
    const isAdmin = user.user_type === 'admin'

    const baseColumns = [
      {
        field: 'id',
        headerName: 'ID',
        flex: 2,
        filterable: false
      },
      {
        field: 'company_name',
        headerName: 'Офер',
        flex: 2,
        type: 'singleSelect',
        valueOptions:  [
            ...new Set(
              conversions.map(conversion => conversion.offer.company.company_name)
            )
          ],
        filterOperators:CompanyNameOperators
      },
      {
        field: 'webmaster_id',
        headerName: 'Вебмайстер',
        flex: 2,
        type: 'singleSelect',
        valueOptions:  [
          ...new Set(conversions.map(conversion => conversion.webmaster_id.identifier))
        ],
        filterOperators: WebmasterIdOperators
      },
      {
        field: 'status',
        headerName: 'Статус',
        flex: 2,
        type: 'singleSelect',
        valueOptions:  Object.values(translations.status),
        filterOperators: StatusOperators,
      },
      {
        field: 'goal',
        headerName: 'Цільова дія',
        flex: 3,
        type: 'singleSelect',
        valueOptions:   Object.values(translations.goal),
        filterOperators: GoalOperators,
      },
      {
        field: 'message_from_bank',
        headerName: 'Додаткова інформація',
        flex: 6,
        renderCell: renderCellExpand,
        filterable: false
      },
      {
        field: 'conversion_time',
        headerName: 'Час конверсії',
        flex: 3,
        type: 'dateTime',
        filterable: false,
        renderCell: params => moment(params.value).format('DD.MM.YYYY HH:mm:ss')
      },
      {
        field: 'visitor_ip',
        headerName: 'IP відвідувача',
        type: 'singleSelect',
        valueOptions: [
          ...new Set(conversions.map(conversion => conversion.visitor_ip))
        ],
        flex: 3,
        filterable: false,
        renderCell: params => {
          const countryName = params.row.visitor_country
          const countryCode = countryName ? getCode(countryName) : ''

          return (
            <div>
              {countryCode && (
                <Tooltip title={countryName} placement='bottom-end'>
                  <span style={{ cursor: 'help' }}>
                    <CountryFlag countryCode={countryCode} svg />
                  </span>
                </Tooltip>
              )}{' '}
              {params.value}
            </div>
          )
        }
      },

      {
        field: 'payment',
        headerName: 'Виплата',
        flex: 2,
        filterable: false
      }
    ]

    const userColumns = isAdmin
      ? baseColumns.filter(el => el.field !== 'payment')
      : baseColumns.filter(el => el.field !== 'webmaster_id')

    return userColumns
  }, [conversions, translations.goal, translations.status, user.user_type])

  

  useEffect(() => {
    setColumnVisibilityModel(
      Object.fromEntries(columns.map(el => [el.field, true]))
    )
  }, [columns, user.user_type])

  function getPng(country) {
    const countryCode = country ? getCode(country) : ''
    const img = CountryFlag({ countryCode })
    return img?.props.children
  }

  useEffect(() => {

    const company_name_item = {
      values: [
        ...(user.user_type === 'admin'
          ? adminOffers.map(el => el.company.company_name)
          : user.offers
          ? user.offers.map(el => el.company.company_name)
          : [])
      ]
    }

   const  webmaster_id_item = {
      values: user.user_type === 'admin'
      ? [...columns[2].valueOptions]
      : [...new Set(conversions.map(conversion => conversion.webmaster_id.identifier))]
    };

   const status_item = {
      values:
        user.user_type === 'admin'
          ? [...columns[3].valueOptions]
          : [...columns[2].valueOptions]
    };

   const goal_item = {
      values:
        user.user_type === 'admin'
          ? [...columns[4].valueOptions]
          : [...columns[3].valueOptions]
    };
  
    setCurrentFilter(prev => ({
      ...prev,
      company_name: localStorage.getItem('filtersCompany') 
       ? JSON.parse(localStorage.getItem('filtersCompany')) 
       : company_name_item,
      webmaster_id: localStorage.getItem('filtersWebmasterId') 
        ? JSON.parse(localStorage.getItem('filtersWebmasterId')) 
        : webmaster_id_item,
      status: localStorage.getItem('filtersStatus') 
        ? JSON.parse(localStorage.getItem('filtersStatus')) 
        : status_item,
      goal: localStorage.getItem('filtersGoal') 
        ? JSON.parse(localStorage.getItem('filtersGoal')) 
        : goal_item,
    }))
      
  }, [adminOffers, columns, conversions, user.offers, user.user_type, showFilterPanel])



  const rows = useMemo(() => {
    return [...currentConversions]
      .map(conversion => ({
        id: return8DigitNumber(conversion.id),
        company_name: conversion?.offer?.company?.company_name,
        status: translations.status[conversion.status],
        goal: translations.goal[conversion.goal],
        message_from_bank: conversion?.message_from_bank,
        conversion_time: conversion.conversion_time,
        visitor_ip: conversion.visitor_ip,
        payment: `${conversion.payment.toFixed(2)}₴`,
        webmaster_id: conversion.webmaster_id.identifier,
        visitor_country: conversion.visitor_country,
        visitor_country_png: getPng(conversion.visitor_country),
        visitor_city: conversion.visitor_city,
        conversion_payment: `${
          ['advertiser', 'admin'].includes(user.user_type)
            ? conversion?.offer?.conversion_payment
            : (
                conversion?.offer?.conversion_payment -
                conversion?.offer?.commission
              ).toFixed(2)
        }₴`
      }))
      .filter(row => {
        if (filterConversions || user.user_type === 'admin') {
          return row.status
            ? currentFilter.status?.values.includes(row.status)
            : true
        }
        return currentFilter.status?.values.includes(row.status)
      })
      .filter(row => {
        return row.goal 
        ? currentFilter.goal?.values.includes(row.goal)
        : true
      })
      .filter(row => {
        return row.webmaster_id 
        ? currentFilter.webmaster_id?.values.includes(row.webmaster_id)
        : true
      })
      .filter(row =>
        currentFilter.company_name?.values.includes(row.company_name)
      )
  }, [currentConversions, 
    currentFilter.company_name?.values, 
    currentFilter.goal?.values, 
    currentFilter.status?.values, 
    currentFilter.webmaster_id?.values, 
    filterConversions, 
    translations.goal, 
    translations.status, 
    user.user_type])

  useEffect(() => {
    dispatch(setFilteredConversions(rows))
  }, [rows, dispatch])

  useEffect(() => {
    if (!isLoading && conversions) {
      if (filterConversions) {
        setCurrentConversions(conversions)
      } else {
        setCurrentConversions(
          conversions.filter(
            conversion => conversion.status && conversion.status !== 'undefined'
          )
        )
      }
    }
  }, [conversions, filterConversions, isLoading, user.user_type])

  function CustomFooter() {
    return (
      <Stack
        sx={{
          flexGrow: 1,
          display: 'flex',
          flexDirection: { xs: 'column', md: 'row' },
          gap: { xs: '0', md: '30px' },
          justifyContent: { xs: 'center', md: 'space-between' },
          alignItems: { xs: 'space-between', md: 'center' }
        }}
      >
        <Stack direction='row' spacing={1} mt={2} mb={2}>
          <Button
            size='small'
            color='info'
            variant='outlined'
            value={10}
            onClick={e => {
              setPageSize(+e.target.value)
              localStorage.setItem('setPage', +e.target.value)
            }}
          >
            10
          </Button>
          <Button
            size='small'
            color='info'
            variant='outlined'
            value={20}
            onClick={e => {
              setPageSize(+e.target.value)
              localStorage.setItem('setPage', +e.target.value)
            }}
          >
            20
          </Button>
          <Button
            size='small'
            color='info'
            variant='outlined'
            value={50}
            onClick={e => {
              setPageSize(+e.target.value)
              localStorage.setItem('setPage', +e.target.value)
            }}
          >
            50
          </Button>
          <Button
            size='small'
            color='info'
            variant='outlined'
            value={100}
            onClick={e => {
              setPageSize(+e.target.value)
              localStorage.setItem('setPage', +e.target.value)
            }}
          >
            100
          </Button>
        </Stack>
        <CustomPagination />
      </Stack>
    )
  }

  function onColumnVisibilityModelChange(el) {
    if (Object.keys(el).length) {
      setColumnVisibilityModel(el)
    } else {
      setColumnVisibilityModel(
        Object.fromEntries(columns.map(el => [el.field, true]))
      )
    }
  }

  function changeColumnVisibility(name) {
    setColumnVisibilityModel(prev => ({
      ...prev,
      [name]: !prev[name]
    }))
  }

  useEffect(() => {
    dispatch(getOffersList())
  }, [dispatch])

  

  if (error) return <Alert severity='error'>{error}</Alert>
  return (
    <Box>
      {isLoading && <CircularProgress />}

      <DataGrid
        sortingOrder={['asc', 'desc']}
        initialState={{
          sorting: {
            sortModel: [{ field: 'conversion_time', sort: 'desc' }]
          },

        }}
        autoHeight
        columns={columns}
        rows={rows}
        
        pageSize={pageSize}
        rowsPerPageOptions={[pageSize]}
        columnVisibilityModel={columnVisibilityModel}
        onColumnVisibilityModelChange={onColumnVisibilityModelChange}
        density={localStorage.getItem('density') ? localStorage.getItem('density') : 'comfortable'}
        components={{
          Toolbar: CustomGridToolbar,
          Footer: CustomFooter,
          BaseSwitch: CustomCheckbox
        }}
       
        localeText={ukUA.components.MuiDataGrid.defaultProps.localeText}
        classes={{ panelHeader: 'panel-header' }}
        componentsProps={{
          baseSwitch: {
            onChange: changeColumnVisibility,
            columns: columnVisibilityModel
          },
          toolbar: {
            toggleFilterPanel: () => setShowFilterPanel(prev => !prev),
            filterPanel: {
              filters:
                user.user_type === 'admin'
                  ? [columns[1], columns[2], columns[3], columns[4]]
                  : [columns[1], columns[2], columns[3]],
              onApply: filter => setCurrentFilter(filter),
              currentFilter,
              closePanel: () => setShowFilterPanel(prev => !prev),
              showFilterPanel,
              companyNames: [
                ...(user.user_type === 'admin'
                  ? adminOffers.map(el => el.company.company_name)
                  : user.offers
                  ? user.offers.map(el => el.company.company_name)
                  : [])
              ]
            },
            rows,
            columns
          },
          filterPanel: {
            filterFormProps: {
              columnInputProps: {
                sx: { display: "none", }
              },
              operatorInputProps: {
                sx: { display: "none", }
              },
              deleteIconProps: {
                sx: { display: "none", }
              }
            },

          }
        }}
      />
    </Box>
  )
}

export default ConversionsList
