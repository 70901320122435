import React from 'react'
import { Box, Modal, useTheme } from '@mui/material'

export const modalStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 800,
  borderRadius: '4px',
  border: '1px solid #000',
  p: 4
}

const MyModal = ({ open, onClose, children }) => {
  const theme = useTheme()
  return (
    <Modal open={open} onClose={onClose}>
      <Box
        sx={{
          ...modalStyle,
          backgroundColor: theme.palette.background.paper,
          border: 1,
          borderColor: theme.palette.border,
          margin: '0 auto'
        }}
      >
        {children}
      </Box>
    </Modal>
  )
}

export default MyModal
