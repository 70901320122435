import React, { useEffect, useState } from 'react'
import axios from 'axios'
import { Typography } from '@mui/material'
import { useSelector } from 'react-redux'

const SecurityToken = () => {
  const { user } = useSelector(state => state.admin)
  const [securityToken, setSecurityToken] = useState('')

  useEffect(() => {
    axios
      .get(
        `${process.env.REACT_APP_API_URL}/users/profile/get-security-token/?partner=${user.partner}`,
        {
          headers: {
            Authorization: `JWT ${localStorage.getItem('access')}`
          }
        }
      )
      .then(response => setSecurityToken(response.data.security_token))
  }, [user.partner])

  return (
    <Typography mt={3} variant='body1'>
      Security Token: {securityToken}
    </Typography>
  )
}

export default SecurityToken
