import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import ChartsByDate from '../charts/ChartsByDate'
import ChartsByOffers from '../charts/ChartsByOffers'
import { useDispatch, useSelector } from 'react-redux'
import {
  changeFilterConversions,
  getConversionsList,
  getFilterConversions
} from 'store/reducers/admin/adminActions'
import { Checkbox, Button } from '@mui/material'
import { useTheme } from '@emotion/react'
import axios from 'axios'

const TabPanel = props => {
  const { children, value, index, ...other } = props

  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(getFilterConversions())
  }, [dispatch])

  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  )
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`
  }
}

const StatisticsPanel = () => {
  const { filterConversions, user } = useSelector(state => state.admin)
  const [value, setValue] = React.useState(0)
  const [disableRefresh, setDisableRefresh] = React.useState(false)
  const dispatch = useDispatch()
  const handleChange = (_, newValue) => {
    setValue(newValue)
  }
  const theme = useTheme()

  const handleFilterConversions = event => {
    dispatch(changeFilterConversions(event.target.checked))
  }

  async function refreshIdeaConversionsStatus() {
    setDisableRefresh(true)
    setTimeout(() => {
      setDisableRefresh(false)
      dispatch(getConversionsList())
    }, 60000)

    try {
      await axios.post(
        `${process.env.REACT_APP_API_URL}/partners/ideabank-check-status/`
      )
    } catch (e) {
      console.log(e)
    }
  }

  return (
    <Box sx={{ width: '100%' }}>
      <Box
        sx={{
          borderBottom: 1,
          borderColor: 'divider',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center'
        }}
      >
        <Box>
          <Typography color={theme.palette.mode === 'dark' ? 'white' : 'black'}>
            Статистика по:
          </Typography>
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label='basic tabs example'
          >
            <Tab label='Датам' {...a11yProps(0)} />
            <Tab label='Офферам' {...a11yProps(1)} />
          </Tabs>
        </Box>

        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            gap: '10px'
          }}
        >
          {user.user_type === 'admin' && (
            <Button
              disabled={disableRefresh}
              size='medium'
              color='info'
              variant='outlined'
              onClick={refreshIdeaConversionsStatus}
            >
              {disableRefresh
                ? 'Оновлення... '
                : 'Оновити статус конверсій Ідеябанк'}
            </Button>
          )}
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center'
            }}
          >
            <Checkbox
              checked={filterConversions}
              onChange={handleFilterConversions}
              inputProps={{ 'aria-label': 'controlled' }}
            />
            <Typography
              variant='button'
              color='primary'
              sx={{
                fontSize: '12px',
                letterSpacing: 0.2
              }}
            >
              Відображати конверсії без статусу
            </Typography>
          </Box>
        </Box>
      </Box>
      <TabPanel value={value} index={0}>
        <ChartsByDate />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <ChartsByOffers />
      </TabPanel>
    </Box>
  )
}
export default StatisticsPanel
