import { Checkbox, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import './CustomFilterColumn.scss'

function StatusInputValue(props) {
  const { item, applyValue } = props

  const status = [
    'Підтверджено',
    'В обробці',
    'Відхилено',
    'Відмінено',
    'Сплачено',
    undefined
  ]
  useEffect(() => {

    applyValue({ ...item, value: localStorage.getItem('filtersStatus') ? JSON.parse(localStorage.getItem('filtersStatus')).values : status })
     // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleChange = statusName => {
    const updatedValue =  [...item.value]
    const index = updatedValue.indexOf(statusName)

    if (index === -1) {
      updatedValue.push(statusName)
    } else {
      updatedValue.splice(index, 1)
    }
    applyValue({ ...item, value: updatedValue })
    localStorage.setItem('filtersStatus', JSON.stringify({values: updatedValue}))
  }

  return (
    <div>
      <ul className='filter-panel-column__list'>
        {status.map(statusName => (
          <li key={statusName} className='filter-panel-column__list-item'>
            {statusName !== undefined && (
              <>
                <Checkbox
                  name={statusName}
                  checked={item.value ? item.value.includes(statusName) : true}
                  onChange={() => handleChange(statusName)}
                  inputProps={{ 'aria-label': 'controlled' }}
                />
                <Typography>{statusName}</Typography>
              </>
            )}{' '}
          </li>
        ))}
      </ul>
    </div>
  )
}

export const StatusOperators = [
  {
    label: 'Above',
    value: 'above',
    getApplyFilterFn: filterItem => {
      if (!filterItem.value || !filterItem.columnField ||  !filterItem.operatorValue) {
        return null
      }
    //   localStorage.setItem('filtersStatus', JSON.stringify({values: filterItem.value}));
      return params => {
        return params.value ? filterItem.value.includes(params.value): true
      }
    },
    InputComponent: StatusInputValue,
    InputComponentProps: { type: 'singleSelect' }
  }
]

function CompanyNameInputValue(props) {
  const { user, adminOffers } = useSelector(state => state.admin)

  const { item, applyValue } = props
  const [companiNames, setCompanisNames] = useState([])

  useEffect(() => {
    const companiNamesItem = [
      ...(user.user_type === 'admin'
        ? adminOffers.map(el => el.company.company_name)
        : user.offers
        ? user.offers.map(el => el.company.company_name)
        : [])
    ]

    setCompanisNames(companiNamesItem)
    applyValue({ ...item, value: localStorage.getItem('filtersCompany') ? JSON.parse(localStorage.getItem('filtersCompany')).values : companiNamesItem })
     
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleChange = name => {
    const updatedValue = [...item.value]
    const index = updatedValue.indexOf(name)

    if (index === -1) {
      updatedValue.push(name)
    } else {
      updatedValue.splice(index, 1)
    }
    applyValue({ ...item, value: updatedValue })
    localStorage.setItem('filtersCompany', JSON.stringify({values: updatedValue}));
  }

  return (
    <div>
      <ul className='filter-panel-column__list'>
        {companiNames.map(name => (
          <li key={name} className='filter-panel-column__list-item'>
            {name !== undefined && (
              <>
                <Checkbox
                  name={name}
                  checked={item.value ? item.value.includes(name) : true}
                  onChange={() => handleChange(name)}
                  inputProps={{ 'aria-label': 'controlled' }}
                />
                <Typography>{name}</Typography>
              </>
            )}
          </li>
        ))}
      </ul>
    </div>
  )
}

export const CompanyNameOperators = [
  {
    label: 'Above',
    value: 'above',
    getApplyFilterFn: filterItem => {
      if (!filterItem.value || !filterItem.columnField ||  !filterItem.operatorValue) {
        return null
      }

      return params => {
        return filterItem.value.includes(params.value)
      }
    },
    InputComponent: CompanyNameInputValue,
    InputComponentProps: { type: 'singleSelect' },
  }
]

function GoalInputValue(props) {
  const { item, applyValue } = props
  const goals = [
    'Перший кредит',
    'Повторний кредит',
    'Реєстрація нового клієнта',
    'Кредитна картка',
  ]

  useEffect(() => {
      applyValue({ ...item, value: localStorage.getItem('filtersGoal') ? JSON.parse(localStorage.getItem('filtersGoal')).values : goals})
   
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleChange = name => {
    const updatedValue = [...item.value]
    const index = updatedValue.indexOf(name)

    if (index === -1) {
      updatedValue.push(name)
    } else {
      updatedValue.splice(index, 1)
    }
    applyValue({ ...item, value: updatedValue })
    localStorage.setItem('filtersGoal', JSON.stringify({values: updatedValue}));
  }

  return (
    <div>
      <ul className='filter-panel-column__list'>
        {goals.map(goal => (
          <li key={goal} className='filter-panel__list-item'>
           
              <>
                <Checkbox
                  name={goal}
                  checked={item.value ? item.value.includes(goal) : true}
                  onChange={() => handleChange(goal)}
                  inputProps={{ 'aria-label': 'controlled' }}
                />
                <Typography>{goal}</Typography>
              </>
          
          </li>
        ))}
      </ul>
    </div>
  )
}

export const GoalOperators = [
  {
    label: 'Above',
    value: 'above',
    getApplyFilterFn: filterItem => {
        if (!filterItem.value || !filterItem.columnField ||  !filterItem.operatorValue) {
          return null
        }
     
      return params => {
       
        return  params.value ? filterItem.value.includes(params.value) : true;
       
      }
    },
    InputComponent: GoalInputValue,
    InputComponentProps: { type: 'singleSelect' }
  }
]

function WebmasterIdValue(props) {
  const { item, applyValue } = props
  const { conversions } = useSelector(state => state.admin)
  const [webmasterId, setWebmasterId] = useState([])

  useEffect(() => {
    applyValue({
      ...item,
      value: localStorage.getItem('filtersWebmasterId') ? JSON.parse(localStorage.getItem('filtersWebmasterId')).values : [
        ...new Set(
          conversions.map(conversion => conversion.webmaster_id.identifier)
        )
      ]
    })

    setWebmasterId([
      ...new Set(
        conversions.map(conversion => conversion.webmaster_id.identifier)
      )
    ])

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleChange = name => {
    const updatedValue = [...item.value]
    const index = updatedValue.indexOf(name)

    if (index === -1) {
      updatedValue.push(name)
    } else {
      updatedValue.splice(index, 1)
    }
    applyValue({ ...item, value: updatedValue })
    localStorage.setItem('filtersWebmasterId', JSON.stringify({values: updatedValue}));
  }

  return (
    <div>
      <ul className='filter-panel-column__list'>
        {webmasterId.map(el => (
          <li key={el} className='filter-panel__list-item'>
            {el !== 'null' && (
              <>
                <Checkbox
                  name={el}
                  checked={item.value ? item.value.includes(el) : true}
                  onChange={() => handleChange(el)}
                  inputProps={{ 'aria-label': 'controlled' }}
                />
                <Typography>{el}</Typography>
              </>
            )}
          </li>
        ))}
      </ul>
    </div>
  )
}

export const WebmasterIdOperators = [
  {
    label: 'Above',
    value: 'above',
    getApplyFilterFn: filterItem => {
      if (!filterItem.value || !filterItem.columnField ||  !filterItem.operatorValue) {
        return null
      }
  
      return params => {
        return params ? filterItem.value.includes(params.value) : true
      }
    },
    InputComponent: WebmasterIdValue,
    InputComponentProps: { type: 'singleSelect' }
  }
]
