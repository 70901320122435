import { Box, Button, CircularProgress, Stack } from '@mui/material'
import { DataGrid, ukUA } from '@mui/x-data-grid'
import { CustomCheckbox } from 'UI/CustomCheckbox'
import { CustomGridToolbar } from 'UI/CustomGridToolbar'
import { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { CustomPagination } from './ConversionsList'
import './ConversionsList.scss'
import { getOffersList } from 'store/reducers/admin/adminActions'
import { setFilteredConversions } from 'store/reducers/admin/adminSlice'
import return8DigitNumber from 'utils/return8DigitNumber'

function ConversionsListByDate() {
  const {
    conversions,
    isLoadingConversions: isLoading,
    filterConversions,
    user,
    adminOffers
  } = useSelector(state => state.admin)
  const [pageSize, setPageSize] = useState(localStorage.setPage ? localStorage.setPage : 10)

  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(getOffersList())
  }, [dispatch])

  const columns = useMemo(
    () => [
      {
        field: 'date',
        headerName: 'Дата',
        flex: 1,
        type: 'dateTime',
        headerClassName: 'custom-header-cell',
        sortComparator: (v1, v2) => {
          const date1 = new Date(v1.split('.').reverse().join('-'))
          const date2 = new Date(v2.split('.').reverse().join('-'))
          return date1 - date2
        }
      },
      {
        field: 'all_visitors',
        headerName: 'Неунікальні відвідувачі',
        flex: 1,
        headerClassName: 'custom-header-cell'
      },
      {
        field: 'unique_visitors',
        headerName: 'Унікальні відвідувачі',
        flex: 1,
        headerClassName: 'custom-header-cell'
      },
      {
        field: 'conversion_percent',
        headerName: 'Відсоток конвертації',
        flex: 1,
        headerClassName: 'custom-header-cell'
      },
      {
        field: 'active_conversions',
        headerName: 'Активні конверсії',
        flex: 1,
        headerClassName: 'custom-header-cell'
      },
      {
        field: 'awaiting_conversions',
        headerName: 'Конверсії на перевірці',
        flex: 1,
        headerClassName: 'custom-header-cell'
      },
      {
        field: 'rejected_conversions',
        headerName: 'Відхилені конверсії',
        flex: 1,
        headerClassName: 'custom-header-cell'
      },
      {
        field: 'awaiting_payment',
        headerName: 'Гроші в очікуванні апрува',
        flex: 1,
        headerClassName: 'custom-header-cell'
      },
      {
        field: 'approved_payment',
        headerName: 'Заапрувлені гроші',
        flex: 1,
        headerClassName: 'custom-header-cell'
      }
    ],
    []
  )

  const translations = useMemo(
    () => ({
      status: {
        active: 'Підтверджено',
        pending: 'В обробці',
        rejected: 'Відхилено',
        paid: 'Сплачено'
      },
      goal: {
        1: 'Перший кредит',
        2: 'Повторний кредит',
        3: 'Реєстрація нового клієнта'
      }
    }),
    []
  )

  const [columnVisibilityModel, setColumnVisibilityModel] = useState(
    Object.fromEntries(columns.map(el => [el.field, true]))
  )

  function changeColumnVisibility(name) {
    setColumnVisibilityModel(prev => ({
      ...prev,
      [name]: !prev[name]
    }))
  }

  function onColumnVisibilityModelChange(el) {
    if (Object.keys(el).length) {
      setColumnVisibilityModel(el)
    } else {
      setColumnVisibilityModel(
        Object.fromEntries(columns.map(el => [el.field, true]))
      )
    }
  }

  useEffect(() => {
    dispatch(
      setFilteredConversions(
        conversions
          .map(conversion => ({
            id: return8DigitNumber(conversion.id),
            company_name: conversion?.offer?.company?.company_name,
            status: translations.status[conversion.status],
            goal: translations.goal[conversion.goal],
            message_from_bank: conversion?.message_from_bank,
            conversion_time: conversion.conversion_time,
            visitor_ip: conversion.visitor_ip,
            conversion_payment: `${
              ['advertiser', 'admin'].includes(user.user_type)
                ? conversion.payment
                : (
                    conversion.payment -
                    +conversion?.offer?.commission
                  ).toFixed(2)
            }₴`
          }))
          .filter(el => {
            if (user.user_type === 'admin') {
              return adminOffers
                .map(of => of.company.company_name)
                .includes(el.company_name)
            } else if (user.offers) {
              return user.offers
                .map(of => of.company.company_name)
                .includes(el.company_name)
            }
            return false
          })
          .filter(row => {
            if (filterConversions || user.user_type === 'admin') {
              return row
            }
            return row.status && row.status !== 'undefined'
          })
      )
    )
  }, [
    dispatch,
    conversions,
    user.user_type,
    user.offers,
    adminOffers,
    filterConversions,
    translations.status,
    translations.goal
  ])

  const groupByDate = conversions => {
    const dateRange = JSON.parse(localStorage.getItem('dateRange')) || {
      end_date: '2023-07-19',
      start_date: '2023-07-01'
    }

    const allDates = []
    for (
      let d = new Date(dateRange.start_date);
      d <= new Date(dateRange.end_date);
      d.setDate(d.getDate() + 1)
    ) {
      allDates.push(
        `${String(d.getDate()).padStart(2, '0')}.${String(
          d.getMonth() + 1
        ).padStart(2, '0')}.${d.getFullYear()}`
      )
    }

    const existingDates = conversions
      .reduce((acc, el) => {
        if (
          !filterConversions &&
          user.user_type !== 'admin' &&
          (el.status === 'undefined' || !el.status)
        ) {
          return acc
        }
        const date = new Date(el.conversion_time)
        const dateString = `${String(date.getDate()).padStart(2, '0')}.${String(
          date.getMonth() + 1
        ).padStart(2, '0')}.${date.getFullYear()}`
        const index = acc.findIndex(val => val.date === dateString)

        if (index < 0) {
          acc.push({
            date: dateString,
            all_visitors: 1,
            unique_visitors: new Set([el.visitor_ip]),
            conversion_percent:
              el.status === 'active' || el.status === 'paid' ? 1 : 0,
            active_conversions:
              el.status === 'active' || el.status === 'paid' ? 1 : 0,
            awaiting_conversions: el.status === 'pending' ? 1 : 0,
            rejected_conversions: el.status === 'rejected' ? 1 : 0,
            awaiting_payment:
              el.status === 'pending'
                ? el.payment.toFixed(2) + '₴'
                : "0.00₴",
            approved_payment:
              el.status === 'active' || el.status === 'paid'
                ? el.payment.toFixed(2) + '₴'
                : "0.00₴"
          })
        } else {
          acc[index].all_visitors += 1
          acc[index].unique_visitors.add(el.visitor_ip)
          if (el.status === 'active' || el.status === 'paid')
            acc[index].active_conversions += 1
          if (el.status === 'active' || el.status === 'paid')
            acc[index].conversion_percent += 1
          if (el.status === 'pending') acc[index].awaiting_conversions += 1
          if (el.status === 'rejected') acc[index].rejected_conversions += 1
          if (el.status === 'pending')
            acc[index].awaiting_payment =
             ( +acc[index].awaiting_payment.slice(0, -1) +
              el.payment).toFixed(2) + '₴'
          if (el.status === 'active' || el.status === 'paid')
            acc[index].approved_payment =
              (+acc[index].approved_payment.slice(0, -1) +
              el.payment).toFixed(2) + '₴'
        }
        return acc
      }, [])
      .map((item, i) => ({
        ...item,
        id: i,
        unique_visitors: item.unique_visitors.size,
        conversion_percent:
          ((item.conversion_percent / item.unique_visitors.size) * 100).toFixed(
            2
          ) + '%'
      }))

    return allDates.map(date => {
      const existingRow = existingDates.find(row => row.date === date)

      return (
        existingRow || {
          date: date,
          all_visitors: 0,
          unique_visitors: 0,
          conversion_percent: '0.00%',
          active_conversions: 0,
          awaiting_conversions: 0,
          rejected_conversions: 0,
          awaiting_payment: '0.00₴',
          approved_payment: '0.00₴',
          id: date
        }
      )
    })
  }

  const rows = groupByDate(
    conversions.filter(el => {
      if (user.user_type === 'admin') {
        return adminOffers
          .map(of => of.company.company_name)
          .includes(el.offer.company.company_name)
      } else if (user.offers) {
        return user.offers
          .map(of => of.company.company_name)
          .includes(el.offer.company.company_name)
      }
      return false
    })
  )

  function CustomFooter() {
    return (
      <Stack
        sx={{
          flexGrow: 1,
          display: 'flex',
          flexDirection: { xs: 'column', md: 'row' },
          gap: { xs: '0', md: '30px' },
          justifyContent: { xs: 'center', md: 'space-between' },
          alignItems: { xs: 'space-between', md: 'center' }
        }}
      >
        <Stack direction='row' spacing={1} mt={2} mb={2}>
          <Button
            size='small'
            color='info'
            variant='outlined'
            value={10}
            onClick={e => {
              setPageSize(+e.target.value)
              localStorage.setItem('setPage', +e.target.value)
            }}
          >
            10
          </Button>
          <Button
            size='small'
            color='info'
            variant='outlined'
            value={20}
            onClick={e => {
              setPageSize(+e.target.value)
              localStorage.setItem('setPage', +e.target.value)
            }}
          >
            20
          </Button>
          <Button
            size='small'
            color='info'
            variant='outlined'
            value={50}
            onClick={e => {
              setPageSize(+e.target.value)
              localStorage.setItem('setPage', +e.target.value)
            }}
          >
            50
          </Button>
          <Button
            size='small'
            color='info'
            variant='outlined'
            value={100}
            onClick={e => {
              setPageSize(+e.target.value)
              localStorage.setItem('setPage', +e.target.value)
            }}
          >
            100
          </Button>
        </Stack>
        <CustomPagination />
      </Stack>
    )
  }

  return (
    <Box>
      {isLoading && <CircularProgress />}
      <DataGrid
      sortingOrder={['asc', 'desc']}
      initialState={{
        sorting: {
          sortModel: [{ field: 'date', sort: 'desc' }]
        },

      }}
        localeText={ukUA.components.MuiDataGrid.defaultProps.localeText}
        pageSize={pageSize}
        autoHeight
        columns={columns}
        rows={rows}
        columnVisibilityModel={columnVisibilityModel}
        onColumnVisibilityModelChange={onColumnVisibilityModelChange}
        components={{
          Toolbar: CustomGridToolbar,
          Footer: CustomFooter,
          BaseSwitch: CustomCheckbox
        }}
        density={localStorage.getItem('density') ? localStorage.getItem('density') : 'comfortable'}
        componentsProps={{
          baseSwitch: {
            onChange: changeColumnVisibility,
            columns: columnVisibilityModel
          },
          toolbar: {
            hideFilters: true,
            rows,
            columns
          }
        }}
      />
    </Box>
  )
}

export default ConversionsListByDate
