import React, { useEffect, useState } from 'react'
import {
  Box,
  Stack,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
  useTheme
} from '@mui/material'
import { getConversionsList } from 'store/reducers/admin/adminActions'
import { useDispatch, useSelector } from 'react-redux'
import ConversionsList from 'components/admin/ConversionsList'
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import moment from 'moment'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import AdminLayoutAdapt from 'layouts/AdminLayoutAdapt'
import StatisticsList from 'components/admin/StatisticsList'
import { useNavigate } from 'react-router-dom'
import ConversionsListByDate from 'components/admin/ConversionsListByDate'
import ConversionsListByOffer from 'components/admin/ConversionsListByOffer'
import { uk } from 'date-fns/locale'

const ConversionsPage = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const { user, isAuthenticated } = useSelector(state => state.admin)

  const theme = useTheme()

  const [activeStats, setActiveStats] = useState('all')
  const [dataRange, setDataRange] = useState(
    JSON.parse(localStorage.getItem('dateRange')) || {
      start_date: moment().subtract(1, 'months').format('YYYY-MM-DD'),
      end_date: moment().format('YYYY-MM-DD')
    }
  )

  const { start_date, end_date } = dataRange

  const handleChangeStartDate = newValue => {
    setDataRange(() => {
      const date = {
        ...dataRange,
        start_date: moment(newValue).format('YYYY-MM-DD')
      }
      localStorage.setItem('dateRange', JSON.stringify(date))
      return date
    })
  }

  const handleChange = (_, newValue) => {
    setActiveStats(newValue)
  }

  const handleChangeEndDate = newValue => {
    setDataRange(() => {
      const date = {
        ...dataRange,
        end_date: moment(newValue).format('YYYY-MM-DD')
      }
      localStorage.setItem('dateRange', JSON.stringify(date))
      return date
    })
  }

  useEffect(() => {
    const date = JSON.parse(localStorage.getItem('dateRange'))
    const nowDate = moment().format('YYYY-MM-DD')
    const checkLastVisit = localStorage.last_visit_date === nowDate

    if (date && checkLastVisit) {
      setDataRange(date)
    } else {
      const currentDate = {
        start_date: moment().subtract(1, 'months').format('YYYY-MM-DD'),
        end_date: moment().format('YYYY-MM-DD')
      }
      setDataRange(currentDate)
      localStorage.setItem('last_visit_date', nowDate)
      localStorage.setItem('dateRange', JSON.stringify(currentDate))
    }
  }, [dispatch])

  useEffect(() => {
    setTimeout(() => {
      if (!isAuthenticated) navigate('/admin/login')
    }, 1000)
  }, [isAuthenticated, navigate])

  useEffect(() => {
    if (dataRange.start_date && user.user_type)
      dispatch(getConversionsList(dataRange))
  }, [dataRange, dispatch, user.user_type])

  return (
    <AdminLayoutAdapt>
      <Box sx={{ mt: theme.spacing(2) }} mb={2} ml={2} mr={2}>
        <Stack spacing={2}>
          <LocalizationProvider dateAdapter={AdapterDateFns} locale={uk}>
            <Stack direction='row' spacing={4}>
              <DesktopDatePicker
                label='Від'
                inputFormat='yyyy-MM-dd'
                value={start_date}
                onChange={handleChangeStartDate}
                renderInput={params => <TextField {...params} />}
              />
              <DesktopDatePicker
                label='До'
                inputFormat='yyyy-MM-dd'
                value={end_date}
                onChange={handleChangeEndDate}
                renderInput={params => <TextField {...params} />}
              />
            </Stack>
          </LocalizationProvider>
          <StatisticsList />
          <Typography color={theme.palette.mode === 'dark' ? 'white' : 'black'}>
            Конверсії:
          </Typography>
          <ToggleButtonGroup
            color='primary'
            value={activeStats}
            exclusive
            onChange={handleChange}
            aria-label='Platform'
            style={{ maxWidth: '300px' }}
          >
            <ToggleButton value='all'>Усі</ToggleButton>
            <ToggleButton value='date'>По датах</ToggleButton>
            <ToggleButton value='offers'>По оферах</ToggleButton>
          </ToggleButtonGroup>
          {activeStats === 'date' ? (
            <ConversionsListByDate />
          ) : activeStats === 'offers' ? (
            <ConversionsListByOffer />
          ) : (
            <ConversionsList activeStats={activeStats} />
          )}
        </Stack>
      </Box>
    </AdminLayoutAdapt>
  )
}

export default ConversionsPage
