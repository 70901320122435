import React, { useEffect, useState } from 'react'
import Box from '@mui/material/Box'
import { useSelector } from 'react-redux'
import { XAxis, YAxis, ResponsiveContainer, BarChart, Bar } from 'recharts'
import ToggleButton from '@mui/material/ToggleButton'
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup'

function CustomXAxisTick(props) {
  const { x, y, payload } = props

  return (
    <g transform={`translate(${x},${y})`}>
      <text
        x={0}
        y={0}
        dy={16}
        textAnchor='end'
        fill='#666'
        transform='rotate(-50)'
        fontSize={12}
      >
        {payload.value}
      </text>
    </g>
  )
}

const ChartsByOffers = () => {
  const { filteredConversions } = useSelector(state => state.admin)
  const [data, setData] = useState([])
  const [alignment, setAlignment] = useState('all')
  const [maxY, setMaxY] = useState(0)

  function unique(arr) {
    let result = []

    for (let str of arr) {
      if (!result.includes(str)) {
        result.push(str)
      }
    }
    return result
  }

  useEffect(() => {
    const offersObject = {}
    const ipObject = {}
    let countMaxConversions = 0

    const offers = []

    filteredConversions.forEach(element => {
      const companyName = element.company_name
      const companyIp = element.visitor_ip
      if (offersObject[companyName]) {
        offersObject[companyName] = {
          count: offersObject[companyName].count + 1
        }
      } else {
        offersObject[companyName] = { count: 1 }
      }

      if (ipObject[companyName]) {
        ipObject[companyName].push(companyIp)
      } else {
        ipObject[companyName] = []
        ipObject[companyName].push(companyIp)
      }
    })

    for (const key in offersObject) {
      const currentCount = offersObject[key].count
      if (currentCount > countMaxConversions) {
        countMaxConversions = currentCount
      }

      if (alignment === 'unique') {
        offers.push({
          name: key,
          count: unique(ipObject[key]).length
        })
      } else {
        offers.push({
          name: key,
          count: offersObject[key].count
        })
      }
    }

    setMaxY(countMaxConversions)
    setData(offers)
  }, [alignment, filteredConversions])

  const handleChange = (_, newAlignment) => {
    if (newAlignment) {
      setAlignment(newAlignment)
    }
  }

  return (
    <Box
      sx={{
        width: '100%',
        alignItems: 'center',
        display: 'flex',
        paddingBlock: 3,
        paddingRight: 3,
        gap: 2
      }}
    >
      <ResponsiveContainer width='100%' height={300}>
        <BarChart
          height={350}
          data={data}
          margin={{ top: 10, right: 5, left: 5, bottom: 50 }}
        >
          <XAxis dataKey='name' interval={0} tick={<CustomXAxisTick />} />
          <YAxis domain={[0, maxY]} tick={{ fontSize: 12 }} />
          <Bar dataKey='count' fill='#42a5f5' />
        </BarChart>
      </ResponsiveContainer>
      <ToggleButtonGroup
        color='primary'
        value={alignment}
        exclusive
        onChange={handleChange}
        aria-label='Platform'
        orientation='vertical'
      >
        <ToggleButton value='all'>Усі</ToggleButton>
        <ToggleButton value='unique'>Унікальні</ToggleButton>
      </ToggleButtonGroup>
    </Box>
  )
}
export default ChartsByOffers
