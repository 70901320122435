import React, { useEffect, useState } from 'react'
import AdminLayoutAdapt from 'layouts/AdminLayoutAdapt'
import { useDispatch, useSelector } from 'react-redux'
import {
  getOffersList,
  getPartnersList,
  getProfilesList
} from 'store/reducers/admin/adminActions'
import ProfilesList from 'components/admin/profiles/ProfilesList'
import { Box, Button, useTheme } from '@mui/material'
import CreateProfile from 'components/admin/profiles/CreateProfile'
import { useNavigate } from 'react-router-dom'

const ProfilesPage = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const theme = useTheme()

  const [createProfileModalOpen, setCreateProfileModalOpen] = useState(false)
  const { isAuthenticated } = useSelector(state => state.admin)

  const handleOpen = () => setCreateProfileModalOpen(true)
  const handleClose = () => setCreateProfileModalOpen(false)

  useEffect(() => {
    dispatch(getProfilesList())
    dispatch(getPartnersList())
    dispatch(getOffersList())
  }, [dispatch])

  useEffect(() => {
    setTimeout(() => {
      if (!isAuthenticated) navigate('/admin/login')
    }, 1000)
  }, [isAuthenticated, navigate])

  return (
    <AdminLayoutAdapt>
      <Box sx={{ mt: theme.spacing(2) }} mb={2} ml={2} mr={2}>
        <Button
          variant='contained'
          color='success'
          onClick={handleOpen}
          sx={{ mb: theme.spacing(1) }}
        >
          Створити нового користувача
        </Button>
        <CreateProfile
          open={createProfileModalOpen}
          handleClose={handleClose}
        />
        <ProfilesList />
      </Box>
    </AdminLayoutAdapt>
  )
}

export default ProfilesPage
