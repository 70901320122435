import * as React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { logoutUser } from 'store/reducers/admin/adminActions'
import {
  Container,
  AppBar,
  Box,
  Button,
  IconButton,
  Stack,
  Toolbar,
  Menu,
  MenuItem,
  styled
} from '@mui/material'
import MenuIcon from '@mui/icons-material/Menu'
import LogoutIcon from '@mui/icons-material/Logout'
import LoginIcon from '@mui/icons-material/Login'
import AccountCircleIcon from '@mui/icons-material/AccountCircle'
import Logo from '../assets/images/Logo.svg'
import { useThemeToggle } from 'index'
import FormControlLabel from '@mui/material/FormControlLabel'
import Switch from '@mui/material/Switch'

const MaterialUISwitch = styled(Switch)(({ theme }) => ({
  width: 62,
  height: 34,
  padding: 7,
  '& .MuiSwitch-switchBase': {
    margin: 1,
    padding: 0,
    transform: 'translateX(6px)',
    '&.Mui-checked': {
      color: '#fff',
      transform: 'translateX(22px)',
      '& .MuiSwitch-thumb:before': {
        backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" viewBox="0 0 20 20"><path fill="${encodeURIComponent(
          '#fff'
        )}" d="M4.2 2.5l-.7 1.8-1.8.7 1.8.7.7 1.8.6-1.8L6.7 5l-1.9-.7-.6-1.8zm15 8.3a6.7 6.7 0 11-6.6-6.6 5.8 5.8 0 006.6 6.6z"/></svg>')`
      },
      '& + .MuiSwitch-track': {
        opacity: 1,
        backgroundColor: theme.palette.mode === 'dark' ? '#8796A5' : '#aab4be'
      }
    }
  },
  '& .MuiSwitch-thumb': {
    backgroundColor: theme.palette.mode === 'dark' ? '#008dff' : '#00adff',
    width: 32,
    height: 32,
    '&:before': {
      content: "''",
      position: 'absolute',
      width: '100%',
      height: '100%',
      left: 0,
      top: 0,
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center',
      backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" viewBox="0 0 20 20"><path fill="${encodeURIComponent(
        '#fff'
      )}" d="M9.305 1.667V3.75h1.389V1.667h-1.39zm-4.707 1.95l-.982.982L5.09 6.072l.982-.982-1.473-1.473zm10.802 0L13.927 5.09l.982.982 1.473-1.473-.982-.982zM10 5.139a4.872 4.872 0 00-4.862 4.86A4.872 4.872 0 0010 14.862 4.872 4.872 0 0014.86 10 4.872 4.872 0 0010 5.139zm0 1.389A3.462 3.462 0 0113.471 10a3.462 3.462 0 01-3.473 3.472A3.462 3.462 0 016.527 10 3.462 3.462 0 0110 6.528zM1.665 9.305v1.39h2.083v-1.39H1.666zm14.583 0v1.39h2.084v-1.39h-2.084zM5.09 13.928L3.616 15.4l.982.982 1.473-1.473-.982-.982zm9.82 0l-.982.982 1.473 1.473.982-.982-1.473-1.473zM9.305 16.25v2.083h1.389V16.25h-1.39z"/></svg>')`
    }
  },
  '& .MuiSwitch-track': {
    opacity: 1,
    backgroundColor: theme.palette.mode === 'dark' ? '#8796A5' : '#d8d8d8',
    borderRadius: 20 / 2
  }
}))

const AdminLayoutAdapt = props => {
  const dispatch = useDispatch()
  const { isAuthenticated, user } = useSelector(state => state.admin)

  const navigate = useNavigate()

  const { darkMode, setDarkMode } = useThemeToggle()

  const logout = () => {
    dispatch(logoutUser())
    navigate('/')
  }

  // const redirectToLogin = useCallback(() => {
  //   navigate('/')
  // }, [navigate])

  // useEffect(() => {
  //   if (!isLoading && !isAuthenticated) {
  //     redirectToLogin()
  //   }
  // }, [isAuthenticated, isLoading, redirectToLogin])

  const [anchorElNav, setAnchorElNav] = useState(null)

  const handleOpenNavMenu = event => {
    setAnchorElNav(event.currentTarget)
  }

  const handleCloseNavMenu = () => {
    setAnchorElNav(null)
  }

  return (
    <>
      <AppBar position='static'>
        <Container maxWidth='xl'>
          <Toolbar disableGutters>
            {isAuthenticated ? (
              <>
                <Button
                  color='inherit'
                  sx={{
                    mr: 2,
                    display: { xs: 'none', md: 'flex' }
                  }}
                >
                  <Box
                    mr={3}
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      width: 150
                    }}
                  >
                    <img
                      className='header__logo-img'
                      src={Logo}
                      alt='logo'
                      width='150'
                    />
                  </Box>
                </Button>

                <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
                  <IconButton
                    size='large'
                    aria-label='account of current user'
                    aria-controls='menu-appbar'
                    aria-haspopup='true'
                    onClick={handleOpenNavMenu}
                    color='inherit'
                  >
                    <MenuIcon />
                  </IconButton>
                  <Menu
                    id='menu-appbar'
                    anchorEl={anchorElNav}
                    anchorOrigin={{
                      vertical: 'bottom',
                      horizontal: 'left'
                    }}
                    keepMounted
                    transformOrigin={{
                      vertical: 'top',
                      horizontal: 'left'
                    }}
                    open={Boolean(anchorElNav)}
                    onClose={handleCloseNavMenu}
                    sx={{
                      display: { xs: 'block', md: 'none' }
                    }}
                  >
                    <MenuItem
                      sx={{
                        display: {
                          xs: 'flex',
                          justifyContent: 'center',
                          flexDirection: 'column'
                        }
                      }}
                    >
                      <Button
                        variant='text'
                        color='inherit'
                        component={Link}
                        to='/admin/conversions/'
                      >
                        Конверсії
                      </Button>
                      {user.user_type === 'admin' && (
                        <>
                          <Button
                            variant='text'
                            color='inherit'
                            component={Link}
                            to='/admin/profiles/'
                          >
                            Профілі
                          </Button>
                          <Button
                            variant='text'
                            color='inherit'
                            component={Link}
                            to='/admin/partners/'
                          >
                            Партнери
                          </Button>
                        </>
                      )}
                      {user.user_type !== 'advertiser' && (
                        <Button
                          variant='text'
                          color='inherit'
                          component={Link}
                          to='/admin/offers/'
                        >
                          Офери
                        </Button>
                      )}
                      {/* <Button
                        variant='text'
                        color='inherit'
                        component={Link}
                        to='/admin/statistics/'
                      >
                        Статистика
                      </Button> */}
                    </MenuItem>
                  </Menu>
                </Box>

                <Button
                  color='inherit'
                  component={Link}
                  to='/'
                  sx={{
                    mr: 2,
                    display: { xs: 'flex', md: 'none' },
                    flexGrow: 1
                  }}
                >
                  <Box
                    mr={3}
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      width: 150
                    }}
                  >
                    <img
                      className='header__logo-img'
                      src={Logo}
                      alt='logo'
                      width='150'
                    />
                  </Box>
                </Button>

                <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
                  <Button
                    variant='text'
                    color='inherit'
                    onClick={handleCloseNavMenu}
                    component={Link}
                    to='/admin/conversions/'
                  >
                    Конверсії
                  </Button>
                  {user.user_type === 'admin' && (
                    <>
                      <Button
                        variant='text'
                        color='inherit'
                        onClick={handleCloseNavMenu}
                        component={Link}
                        to='/admin/profiles/'
                      >
                        Профілі
                      </Button>
                      <Button
                        variant='text'
                        color='inherit'
                        onClick={handleCloseNavMenu}
                        component={Link}
                        to='/admin/partners/'
                      >
                        Партнери
                      </Button>
                    </>
                  )}
                  {user.user_type !== 'advertiser' && (
                    <Button
                      variant='text'
                      color='inherit'
                      onClick={handleCloseNavMenu}
                      component={Link}
                      to='/admin/offers/'
                    >
                      Офери
                    </Button>
                  )}
                  {/* <Button
                    variant='text'
                    color='inherit'
                    component={Link}
                    to='/admin/statistics/'
                  >
                    Статистика
                  </Button> */}
                </Box>

                <Box sx={{ flexGrow: 0 }}>
                  <Stack direction='row'>
                    <FormControlLabel
                      control={
                        <MaterialUISwitch
                          sx={{ m: 1 }}
                          onChange={() => {
                            document.body.classList.toggle('dark-theme')
                            setDarkMode(!darkMode)
                          }}
                          defaultChecked={darkMode}
                        />
                      }
                    />
                    <Button
                      variant='text'
                      color='inherit'
                      onClick={() =>
                        window.open(
                          'https://leadleader.com.ua/docs/', '_self'
                        )
                      }
                      sx={{
                        display: { xs: 'none', md: 'flex' }
                      }}
                    >
                      Документація API
                    </Button>
                    <Button
                      variant='text'
                      color='inherit'
                      component={Link}
                      to='/'
                      sx={{
                        display: { xs: 'none', md: 'flex' }
                      }}
                    >
                      На головну сторінку
                    </Button>
                    <IconButton
                      color='inherit'
                      component={Link}
                      to='/admin/profile/'
                    >
                      <AccountCircleIcon />
                    </IconButton>
                    <IconButton color='inherit' onClick={logout}>
                      <LogoutIcon />
                    </IconButton>
                  </Stack>
                </Box>
              </>
            ) : (
              <IconButton color='inherit' component={Link} to='/'>
                <LoginIcon />
              </IconButton>
            )}
          </Toolbar>
        </Container>
      </AppBar>
      {props.children}
    </>
  )
}
export default AdminLayoutAdapt
