/* eslint-disable react/style-prop-object */

import React, { useMemo, useState, useEffect } from 'react'
import { Alert, CircularProgress, Box, Stack, Button } from '@mui/material'
import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarDensitySelector
} from '@mui/x-data-grid'
import { useSelector } from 'react-redux'
import EditOfferModal from './edit/EditOfferModal'
import LocalizedTextsMap from '../../../utils/localizedTextsMap.js'
import renderCellExpand from '../../../UI/RenderCellExpand'
import { CustomCheckbox } from 'UI/CustomCheckbox'
import './OffersList.scss'
import { CustomPagination } from '../ConversionsList'
// import { useGridApiContext } from '@mui/x-data-grid';
// import { CustomGridToolbar } from '../../../UI/CustomGridToolbar'
import { useGridApiContext } from '@mui/x-data-grid'
import ArrowUpwardOutlinedIcon from '@mui/icons-material/ArrowUpwardOutlined'
import ArrowDownwardOutlinedIcon from '@mui/icons-material/ArrowDownwardOutlined'

function CustomToolbar() {
  const gridApiContext = useGridApiContext()
  useEffect(() => {
    localStorage.setItem('density', gridApiContext.current.state.density.value)
  }, [gridApiContext, gridApiContext.current.state.density.value])
  return (
    <GridToolbarContainer>
      <GridToolbarColumnsButton />
      <GridToolbarDensitySelector />
    </GridToolbarContainer>
  )
}

const OffersList = ({
  disableCU,
  setDataNewPosition,
  openChangPosition,
  isLoadingChangPosition
}) => {
  const { user, adminOffers, isLoading, error } = useSelector(
    state => state.admin
  )

  const [pageSize, setPageSize] = useState(
    localStorage.setPage ? localStorage.setPage : 10
  )

  const [data, setData] = useState([])
  const [sortModel, setSortModel] = useState([
    {
      field: openChangPosition ? 'showcase_position' : 'id',
      sort: 'asc'
    }
  ])

  useEffect(() => {
    setSortModel([
      {
        field: openChangPosition ? 'showcase_position' : 'id',
        sort: 'asc'
      }
    ])
  }, [openChangPosition])

  const generateData = (adminOffers, user) => {
    return user.user_type === 'webmaster' || user.user_type === 'advertiser'
      ? user.offers.map(offer => ({
          id: offer.id,
          company_name: offer,
          conversion_payment:
            offer?.company?.company_id === 'ideabank' ||
            offer?.company?.company_id === 'bisbank'
              ? `5.0% від суми кредиту`
              : `${offer.conversion_payment}₴`,
          loan_info: offer.loan_info
        }))
      : adminOffers.map(offer => ({
          id: offer.id,
          company_name: offer,
          company_id: offer?.company?.company_id,
          showcase_position: offer.showcase_position,
          conversion_payment:
            offer?.company?.company_id === 'ideabank' ||
            offer?.company?.company_id === 'bisbank'
              ? `5.0% від суми кредиту`
              : `${offer.conversion_payment}₴`,
          loan_info: offer.loan_info,
          commission: `${offer.commission}₴`
        }))
  }

  useEffect(() => {
    setData(generateData(adminOffers, user))
  }, [adminOffers, user, openChangPosition])

  function findIndexClosestUp(array, target) {
    let closestIndex = -1
    let number = 0
    for (let i = 0; i < array.length; i++) {
      if (
        array[i].showcase_position < target &&
        array[i].showcase_position > number
      ) {
        closestIndex = i
        number = array[i].showcase_position
      } else if (array[i] === target) {
        return i
      }
    }
    return closestIndex
  }

  function findIndexClosestDown(array, target) {
    let closestIndex = -1
    let number = 100000
    for (let i = 0; i < array.length; i++) {
      if (
        array[i].showcase_position > target &&
        array[i].showcase_position < number
      ) {
        closestIndex = i
        number = array[i].showcase_position
      } else if (array[i] === target) {
        return i
      }
    }
    return closestIndex
  }

  const handleMoveClick = (params, direction) => {
    setData(prevData => {
      const index = prevData.findIndex(
        item => item.showcase_position === params.value
      )
      const closestIndex =
        direction === 'Up'
          ? findIndexClosestUp(prevData, params.value)
          : findIndexClosestDown(prevData, params.value)

      console.log(index, closestIndex)

      if (index !== -1 && index > 0 && closestIndex !== -1) {
        const newData = [...prevData]
        const temp = newData[index].showcase_position
        newData[index].showcase_position =
          newData[closestIndex].showcase_position
        newData[closestIndex].showcase_position = temp

        return [...newData]
      }
      return prevData
    })
  }

  useEffect(() => {
    if (openChangPosition) {
      setDataNewPosition(data)
    }
  }, [data, openChangPosition, setDataNewPosition])

  const columns = useMemo(
    () =>
      user.user_type === 'webmaster' || user.user_type === 'advertiser'
        ? [
            {
              field: 'id',
              headerName: 'ID пропозиції',
              width: 120
            },
            {
              field: 'company_name',
              headerName: 'Партнерські програми',
              width: 250,
              sortComparator: (v1, v2, param1, param2) => {
                return v1.company.company_name.localeCompare(
                  v2.company.company_name
                )
              },
              renderCell: params => (
                <Box
                  display='flex'
                  justifyContent='space-between'
                  alignItems='center'
                  gap='5px'
                  width='100%'
                >
                  {params.value.company?.company_name}
                  <Box
                    sx={{
                      width: 100,
                      marginRight: '30px'
                    }}
                  >
                    <Box
                      component='img'
                      alt='logo'
                      src={`${params.value.company_logo_url}?auto=format&w=110&dpr=21`}
                      sx={{
                        width: '100%',
                        height: 40,
                        objectFit: 'contain'
                      }}
                    ></Box>
                  </Box>
                </Box>
              )
            },

            {
              field: 'conversion_payment',
              headerName: 'Плата за конверсію',
              width: 200
            },
            {
              field: 'loan_info',
              headerName: 'Деталі',
              width: 730,
              renderCell: renderCellExpand
            }
          ]
        : [
            {
              field: 'id',
              headerName: 'ID пропозиції',
              width: 120
            },
            {
              field: 'company_name',
              headerName: 'Партнерські програми',
              width: 250,
              sortComparator: (v1, v2, param1, param2) => {
                return v1.company.company_name.localeCompare(
                  v2.company.company_name
                )
              },
              renderCell: params => (
                <Box
                  display='flex'
                  justifyContent='space-between'
                  width='100%'
                  alignItems='center'
                  gap='5px'
                >
                  {params.value.company?.company_name}

                  <Box
                    sx={{
                      width: 100,
                      marginRight: '30px'
                    }}
                  >
                    <Box
                      component='img'
                      alt='logo'
                      src={`${params.value.company_logo_url}?auto=format&w=110&dpr=21`}
                      sx={{
                        width: '100%',
                        height: 40,
                        objectFit: 'contain'
                      }}
                    ></Box>
                  </Box>
                </Box>
              )
            },
            {
              field: 'company_id',
              headerName: 'Ідентифікатор',
              width: 150
            },
            {
              field: 'showcase_position',
              headerName: 'Позиція на вітрині',
              width: 160,
              renderCell: params => (
                <Box
                  display='flex'
                  justifyContent='space-between'
                  width='100%'
                  alignItems='center'
                  gap='5px'
                >
                  {params.value}
                  <>
                    {openChangPosition && (
                      <Box
                        display='flex'
                        flexDirection='column'
                        width='100%'
                        alignItems='center'
                        gap='5px'
                      >
                        <Button onClick={() => handleMoveClick(params, 'Up')}>
                          <ArrowUpwardOutlinedIcon
                            sx={{
                              height: 15
                            }}
                          />
                        </Button>
                        <Button onClick={() => handleMoveClick(params, 'Down')}>
                          <ArrowDownwardOutlinedIcon
                            sx={{
                              height: 15
                            }}
                          />
                        </Button>
                      </Box>
                    )}
                  </>
                </Box>
              )
            },
            {
              field: 'conversion_payment',
              headerName: 'Плата за конверсію',
              width: 200
            },
            {
              field: 'loan_info',
              headerName: 'Деталі',
              width: 200,

              renderCell: renderCellExpand
            },
            {
              field: 'commission',
              headerName: 'Комісія',
              width: 200
            }
          ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [user.user_type, openChangPosition]
  )

  const [columnVisibilityModel, setColumnVisibilityModel] = useState(
    Object.fromEntries(columns.map(el => [el.field, true]))
  )

  function onColumnVisibilityModelChange(el) {
    if (Object.keys(el).length) {
      setColumnVisibilityModel(el)
    } else {
      setColumnVisibilityModel(
        Object.fromEntries(columns.map(el => [el.field, true]))
      )
    }
  }

  function changeColumnVisibility(name) {
    setColumnVisibilityModel(prev => ({
      ...prev,
      [name]: !prev[name]
    }))
  }

  const [selectedOfferData, setSelectedOfferData] = useState({})
  const [modalOpen, setModalOpen] = useState(false)
  const handleOpen = e => {
    setSelectedOfferData(adminOffers.find(offer => offer.id === +e.id))
    setModalOpen(true)
  }
  const handleClose = () => {
    setModalOpen(false)
    setSelectedOfferData({})
  }

  function CustomFooter() {
    return (
      <Stack
        sx={{
          flexGrow: 1,
          display: 'flex',
          flexDirection: { xs: 'column', md: 'row' },
          gap: { xs: '0', md: '30px' },
          justifyContent: { xs: 'center', md: 'space-between' },
          alignItems: { xs: 'space-between', md: 'center' }
        }}
      >
        <Stack direction='row' spacing={1} mt={2} mb={2}>
          <Button
            size='small'
            color='info'
            variant='outlined'
            value={10}
            onClick={e => {
              setPageSize(+e.target.value)
              localStorage.setItem('setPage', +e.target.value)
            }}
          >
            10
          </Button>
          <Button
            size='small'
            color='info'
            variant='outlined'
            value={20}
            onClick={e => {
              setPageSize(+e.target.value)
              localStorage.setItem('setPage', +e.target.value)
            }}
          >
            20
          </Button>
          <Button
            size='small'
            color='info'
            variant='outlined'
            value={50}
            onClick={e => {
              setPageSize(+e.target.value)
              localStorage.setItem('setPage', +e.target.value)
            }}
          >
            50
          </Button>
          <Button
            size='small'
            color='info'
            variant='outlined'
            value={100}
            onClick={e => {
              setPageSize(+e.target.value)
              localStorage.setItem('setPage', +e.target.value)
            }}
          >
            100
          </Button>
        </Stack>
        <CustomPagination />
      </Stack>
    )
  }

  if (isLoading || isLoadingChangPosition) return <CircularProgress />
  if (error) return <Alert severity='error'>{error}</Alert>

  return (
    <>
      <DataGrid
        sortingOrder={['desc', 'asc']}
        sortModel={openChangPosition ? sortModel : undefined}
        autoHeight
        pageSize={pageSize}
        disableColumnFilter
        columns={columns}
        rows={data}
        columnVisibilityModel={columnVisibilityModel}
        classes={{ panelHeader: 'panel-header' }}
        onColumnVisibilityModelChange={onColumnVisibilityModelChange}
        density={
          localStorage.getItem('density')
            ? localStorage.getItem('density')
            : 'comfortable'
        }
        components={{
          Toolbar: CustomToolbar,
          BaseSwitch: CustomCheckbox,
          Footer: CustomFooter
        }}
        componentsProps={{
          toolbar: {
            printOptions: { disableToolbarButton: true },
            csvOptions: { disableToolbarButton: true }
          },
          baseSwitch: {
            onChange: changeColumnVisibility,
            columns: columnVisibilityModel
          }
        }}
        onRowClick={
          user.user_type === 'admin' && !openChangPosition
            ? e => handleOpen(e)
            : () => {}
        }
        localeText={LocalizedTextsMap}
      />
      {!disableCU && !openChangPosition && (
        <EditOfferModal
          handleClose={handleClose}
          open={modalOpen}
          offer_data={selectedOfferData}
        />
      )}
    </>
  )
}

export default OffersList
