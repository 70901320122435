import React, { useEffect, useState } from 'react'
import axios from 'axios'
import { useLocation, useNavigate } from 'react-router-dom'
import { Alert } from '@mui/material'
import { useSelector } from 'react-redux'

const PostBack = () => {
  const navigate = useNavigate()
  const location = useLocation()

  const { isAuthenticated } = useSelector(state => state.admin)

  const [response, setResponse] = useState('')
  const [error, setError] = useState('')

  useEffect(() => {
    if (!isAuthenticated) navigate('/admin/login')
  }, [isAuthenticated, navigate])

  useEffect(() => {
    const getQueryParams = search => {
      return search
        ? JSON.parse(
            '{"' +
              decodeURI(
                search.substring(1).replace(/&/g, '","').replace(/=/g, '":"')
              ) +
              '"}'
          )
        : {}
    }

    const queryParams = getQueryParams(location.search)

    axios
      .get(`${process.env.REACT_APP_URL}${location.pathname}`, {
        params: queryParams
      })
      .then(r => setResponse(r.data))
      .catch(e => setError(e.response.data))
  }, [location])

  if (error) return <Alert severity='error'>{error}</Alert>
  return <Alert>{response}</Alert>
}

export default PostBack
