import React, {useEffect, useState} from 'react'
import EditPartnerInfo from './EditPartnerInfo'
import {useDispatch} from 'react-redux'
import {deletePartnerByID, updatePartnerInfo} from 'store/reducers/admin/adminActions'
import MyModal from 'UI/MyModal'
import {useConfirm} from 'material-ui-confirm'
import {Button} from '@mui/material'

const EditPartnerModal = ({partner_data, open, handleClose}) => {
  const dispatch = useDispatch()

  const confirm = useConfirm()

  const [editablePartner, setEditablePartner] = useState({
    id: 1,
    company_id: '',
    company_name: ''
  })
  const handleChange = e => setEditablePartner(
      {...editablePartner, [e.target.name]: e.target.value})
  const handleClick = () => {
    dispatch(updatePartnerInfo(editablePartner))
    handleClose()
  }
  const handleDelete = id => {
    confirm({description: 'Ви впевнені, що хочете видалити цей запис?'}).then(() => dispatch(deletePartnerByID(id)))
    handleClose()
  }

  useEffect(() => {
    setEditablePartner(partner_data)
  }, [partner_data])

  return (
      <MyModal open={open} onClose={handleClose}>
        <EditPartnerInfo partnerData={editablePartner}
                         handleChange={handleChange}
                         handleClick={handleClick}/>
        <Button color="error" variant="contained" onClick={() => handleDelete(editablePartner.id)}>Видалити</Button>
      </MyModal>
  )
}

export default EditPartnerModal