import React from 'react'
import {Box, Button, Stack, TextField, useTheme} from '@mui/material'

const EditPartnerInfo = ({partnerData, buttonDisabled, handleChange, handleClick, isCreation}) => {
  const {company_id, company_name} = partnerData
  const theme = useTheme()
  return (
      <Box display="flex" flexDirection="column" justifyContent="center"
           alignItems="center">
        <Stack spacing={2} justifyContent="center" alignItems="center">
          <TextField
              sx={{mr: theme.spacing(.5), width: '240px'}} variant="standard"
              label="ID компанії"
              type="text"
              name="company_id"
              value={company_id || ''}
              onChange={e => handleChange(e)}
          inputProps={{inputMode: 'text', pattern: '^[A-Za-z0-9]+$'}}>
          </TextField>
          <TextField
              sx={{mr: theme.spacing(.5), width: '240px'}} variant="standard"
              label="Назва компанії"
              type="text"
              name="company_name"
              value={company_name || ''}
              onChange={e => handleChange(e)}>
          </TextField>
        </Stack>
        <Button disabled={buttonDisabled} sx={{mt: theme.spacing(2)}} size="medium" variant="contained"
                color="success"
                onClick={handleClick}>{isCreation ? 'Створити' : 'Зберегти'}</Button>
      </Box>
  )
}

export default EditPartnerInfo