import { useTheme } from '@emotion/react'
import { MenuItem } from '@mui/material'

function TxtExportMenuItem({ rows }) {
  const theme = useTheme()

  const formatDate = time => {
    const date = new Date(time)
    return `${String(date.getDate()).padStart(2, '0')}.${String(
      date.getMonth() + 1
    ).padStart(2, '0')}.${date.getFullYear()} ${String(
      date.getHours()
    ).padStart(2, '0')}:${String(date.getMinutes()).padStart(2, '0')}:${String(
      date.getSeconds()
    ).padStart(2, '0')}`
  }

  // Sort the rows based on conversion_time
  const newRows = [...rows].sort(
    (a, b) => new Date(a.conversion_time) - new Date(b.conversion_time)
  )

  const formattedRows = newRows
    .map(el => {
      const { conversion_payment, ...rest } = el
      const formattedEl = {
        ...rest,
        conversion_time: formatDate(el.conversion_time)
      }
      return Object.values(formattedEl).join(', ') + '\n'
    })
    .join('')

  return (
    <MenuItem>
      <a
        style={{
          color: theme.palette.mode === 'dark' ? 'white' : 'black',
          textDecoration: 'none'
        }}
        href={
          'data:text/plain;charset=utf-8,' + encodeURIComponent(formattedRows)
        }
        download={'conversions'}
      >
        Завантажити як TXT
      </a>
    </MenuItem>
  )
}

export default TxtExportMenuItem
