import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import EditOfferInfo from './EditOfferInfo'
import {
  deleteOfferByID,
  updateOfferInfo
} from 'store/reducers/admin/adminActions'
import MyModal from 'UI/MyModal'
import { useConfirm } from 'material-ui-confirm'
import { Button } from '@mui/material'

const EditOfferModal = ({ offer_data, open, handleClose }) => {
  const dispatch = useDispatch()

  const confirm = useConfirm()

  const [editableOffer, setEditableOffer] = useState({
    id: null,
    company: null,
    company_logo_url: '',
    loan_offer_url: '',
    interest_rate: '',
    loan_info: '',
    loan_terms: '',
    max_loan_amount: null,
    showcase_position: null,
    conversion_payment: '',
    commission: '',
    send_click_id: false
  })

  const [isButtonActive, setIsButtonActive] = useState(false)

  const handleChange = e => {
    setEditableOffer({ ...editableOffer, [e.target.name]: e.target.value })
  }

  const handleChecked = e => {
    setEditableOffer({ ...editableOffer, [e.target.name]: e.target.checked })
  }

  const handleClick = () => {
    dispatch(updateOfferInfo(editableOffer))
    handleClose()
  }

  const handleDelete = id => {
    confirm({ description: 'Ви впевнені, що хочете видалити цей запис?' }).then(
      () => dispatch(deleteOfferByID(id))
    )
    handleClose()
  }

  useEffect(() => {
    setEditableOffer({ ...offer_data, company: offer_data?.company?.id })
  }, [offer_data])

  useEffect(() => {
    setIsButtonActive(
      Object.values(editableOffer).every(x => x !== null && x !== '')
    )
  }, [editableOffer])

  return (
    <MyModal open={open} onClose={handleClose}>
      <EditOfferInfo
        offerData={editableOffer}
        handleChange={handleChange}
        handleChecked={handleChecked}
        handleClick={handleClick}
        buttonDisabled={!isButtonActive}
      />
      <Button
        color='error'
        variant='contained'
        onClick={() => handleDelete(editableOffer.id)}
      >
        Видалити
      </Button>
    </MyModal>
  )
}

export default EditOfferModal
