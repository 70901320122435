import React from 'react'
import {
  Box,
  Button,
  Checkbox,
  Grid,
  MenuItem,
  Stack,
  TextField,
  Typography,
  useTheme
} from '@mui/material'
import { useSelector } from 'react-redux'

const EditOfferInfo = ({
  offerData,
  handleChange,
  handleChecked,
  handleClick,
  isCreation,
  buttonDisabled
}) => {
  const {
    company,
    company_logo_url,
    interest_rate,
    loan_info,
    loan_offer_url,
    loan_terms,
    max_loan_amount,
    showcase_position,
    conversion_payment,
    commission,
    send_click_id
  } = offerData

  const theme = useTheme()

  const { partners } = useSelector(state => state.admin)

  return (
    <Box
      display='flex'
      flexDirection='column'
      justifyContent='center'
      alignItems='center'
    >
      <Stack spacing={2} maxWidth='549px' width='100%'>
        <TextField
          required
          id='outlined-select-currency'
          select
          label='Компанія'
          name='company'
          value={company || ''}
          onChange={e => handleChange(e)}
        >
          {partners?.map(partner => (
            <MenuItem key={partner.id} value={partner.id}>
              {partner.company_name}
            </MenuItem>
          ))}
        </TextField>
        <TextField
          sx={{ mr: theme.spacing(0.5) }}
          variant='standard'
          required
          label='Посилання на логотип компанії'
          name='company_logo_url'
          value={company_logo_url || ''}
          onChange={e => handleChange(e)}
        ></TextField>
        <TextField
          sx={{ mr: theme.spacing(0.5) }}
          variant='standard'
          required
          label='Посилання на пропозицію кредиту'
          name='loan_offer_url'
          value={loan_offer_url || ''}
          onChange={e => handleChange(e)}
        ></TextField>
        <TextField
          sx={{ mr: theme.spacing(0.5) }}
          variant='standard'
          required
          label='Інформація про кредит'
          name='loan_info'
          value={loan_info || ''}
          onChange={e => handleChange(e)}
        ></TextField>
        <Grid container>
          <Grid item xs={6}>
            <TextField
              sx={{ mr: theme.spacing(0.5), width: '85%' }}
              variant='standard'
              required
              label='Процентна ставка'
              name='interest_rate'
              type='number'
              value={interest_rate || ''}
              onChange={e => handleChange(e)}
            />
            <TextField
              sx={{ mr: theme.spacing(0.5), width: '85%' }}
              variant='standard'
              required
              label='Термін кредитування'
              name='loan_terms'
              type='number'
              value={loan_terms || ''}
              onChange={e => handleChange(e)}
            />
            <TextField
              sx={{ mr: theme.spacing(0.5), width: '85%' }}
              variant='standard'
              required
              label='Максимальна сума кредиту'
              name='max_loan_amount'
              type='number'
              value={max_loan_amount || ''}
              onChange={e => handleChange(e)}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              sx={{ mr: theme.spacing(0.5), width: '85%' }}
              variant='standard'
              required
              label='Позиція на вітрині'
              type='number'
              name='showcase_position'
              value={showcase_position || ''}
              onChange={e => handleChange(e)}
            />
            <TextField
              sx={{ mr: theme.spacing(0.5), width: '85%' }}
              variant='standard'
              required
              label='Плата за конверсію'
              type='number'
              name='conversion_payment'
              value={conversion_payment || ''}
              onChange={e => handleChange(e)}
            />
            <TextField
              sx={{ mr: theme.spacing(0.5), width: '85%' }}
              variant='standard'
              required
              label='Комісія'
              type='number'
              name='commission'
              value={commission || ''}
              onChange={e => handleChange(e)}
            />
          </Grid>
        </Grid>
      </Stack>
      <Stack direction='row' alignItems='center' mt={2}>
        <Typography variant='body1' style={{ color: theme.palette.border }}>
          Відправляти click_id:{' '}
        </Typography>
        <Checkbox
          sx={{ mr: theme.spacing(0.5) }}
          variant='standard'
          required
          label='Відправляти Click ID'
          name='send_click_id'
          checked={send_click_id || false}
          onChange={e => handleChecked(e)}
        ></Checkbox>
      </Stack>
      <Button
        disabled={buttonDisabled}
        sx={{ mt: theme.spacing(2), alignSelf: 'center' }}
        size='medium'
        variant='contained'
        color='success'
        onClick={handleClick}
      >
        {isCreation ? 'Створити' : 'Зберегти'}
      </Button>
    </Box>
  )
}

export default EditOfferInfo
