import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  loans: [],
  isLoading: false,
  isRegistration: false,
  error: '',
  count: 20
}

export const loanSlice = createSlice({
  name: 'loans',
  initialState,
  reducers: {
    getLoans: state => {
      state.isLoading = true
    },
    getLoansSuccess: (state, action) => {
      state.isLoading = false
      const newLoans = action.payload.results || []

      if (action.payload.page) {
        if (
          JSON.stringify(state.loans.slice(-newLoans.length)) !==
          JSON.stringify(newLoans)
        ) {
          state.loans = [...state.loans, ...newLoans]
        }
      } else {
        if (JSON.stringify(state.loans) !== JSON.stringify(newLoans)) {
          state.loans = newLoans
          state.count = action.payload.count
        }
      }
    },

    getLoansFailure: (state, action) => {
      state.isLoading = false
      state.error = action.payload
    },

    registrationIdeabank: state => {
      state.isRegistration = true
    },

    registrationIdeabankSuccess: state => {
      state.isRegistration = false
    },

    registrationIdeabankFailure: state => {
      state.isRegistration = false
    },

    registrationBisbank: state => {
      state.isRegistration = true
    },

    registrationBisbankSuccess: state => {
      state.isRegistration = false
    },

    registrationBisbankFailure: state => {
      state.isRegistration = false
    }
  }
})

export const {
  getLoans,
  getLoansSuccess,
  getLoansFailure,
  registrationIdeabank,
  registrationIdeabankSuccess,
  registrationIdeabankFailure,
  registrationBisbank,
  registrationBisbankSuccess,
  registrationBisbankFailure
} = loanSlice.actions

export default loanSlice.reducer
