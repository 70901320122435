import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Alert, Grid } from '@mui/material'
import LoanItem from './LoanItem'
import { getLoanOffers } from 'store/reducers/loans/loanActions'

const LoanList = () => {
  const { loans, error, count } = useSelector(state => state.loans)

  const dispatch = useDispatch()
  const [page, setPage] = useState(0)

  useEffect(() => {
    if (count > (page - 1) * 10) {
      dispatch(
        getLoanOffers({
          limit: 10,
          offset: page * 10
        })
      )
    }
  }, [count, dispatch, page])

  useEffect(() => {
    if (loans.length) setPage(Math.ceil(loans.length / 10))
  }, [loans])

  if (error) return <Alert severity='error'>{error}</Alert>

  return (
    <Grid container spacing='25px' alignItems='center' justifyContent='center'>
      {loans &&
        loans.map(loan => {
          return <LoanItem key={loan.id} loan={loan} />
        })}
    </Grid>
  )
}

export default LoanList
