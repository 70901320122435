import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

const AdminPage = () => {
  const navigate = useNavigate()

  useEffect(() => {
    return navigate('conversions')
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return <></>
}

export default AdminPage
