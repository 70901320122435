import React, { useMemo, useState, useEffect } from 'react'
import { DataGrid, GridToolbarFilterButton, GridToolbarExport, GridToolbarContainer, GridToolbarDensitySelector, GridToolbarColumnsButton } from '@mui/x-data-grid'
import { useSelector } from 'react-redux'
import { Alert, Button, CircularProgress, Stack } from '@mui/material'
import EditPartnerModal from './edit/EditPartnerModal'
import LocalizedTextsMap from '../../../utils/localizedTextsMap.js'
import { CustomPagination } from '../ConversionsList'
import { useGridApiContext } from '@mui/x-data-grid';

function CustomToolbar() {

  const gridApiContext = useGridApiContext();
  useEffect(() => {
    localStorage.setItem('density', gridApiContext.current.state.density.value)
  }, [gridApiContext, gridApiContext.current.state.density.value])
  return (
    <GridToolbarContainer>
      <GridToolbarColumnsButton />
      <GridToolbarFilterButton />
      <GridToolbarDensitySelector />
      <GridToolbarExport />
    </GridToolbarContainer>
  );
}

const PartnersList = () => {
  const { partners, isLoading, error } = useSelector(state => state.admin)
  const [pageSize, setPageSize] = useState(localStorage.setPage ? localStorage.setPage : 10)

  const columns = [
    { field: 'id', headerName: 'ID партнера', width: 160 },
    { field: 'company_id', headerName: 'ID компанії', width: 320 },
    { field: 'company_name', headerName: 'Назва компанії', width: 120 }
  ]

  const rows = useMemo(
    () =>
      partners.map(partner => ({
        id: partner.id,
        company_name: partner.company_name,
        company_id: partner.company_id
      })),
    [partners]
  )

  const [selectedPartnerData, setSelectedPartnerData] = useState({})
  const [modalOpen, setModalOpen] = useState(false)
  const handleOpen = e => {
    setSelectedPartnerData(partners.find(offer => offer.id === e.id))
    setModalOpen(true)
  }
  const handleClose = () => setModalOpen(false)

  function CustomFooter() {
    return (
      <Stack
        sx={{
          flexGrow: 1,
          display: 'flex',
          flexDirection: { xs: 'column', md: 'row' },
          gap: { xs: '0', md: '30px' },
          justifyContent: { xs: 'center', md: 'space-between' },
          alignItems: { xs: 'space-between', md: 'center' }
        }}
      >
        <Stack direction='row' spacing={1} mt={2} mb={2}>
          <Button
            size='small'
            color='info'
            variant='outlined'
            value={10}
            onClick={e => {
              setPageSize(+e.target.value)
              localStorage.setItem('setPage', +e.target.value)
            }}
          >
            10
          </Button>
          <Button
            size='small'
            color='info'
            variant='outlined'
            value={20}
            onClick={e => {
              setPageSize(+e.target.value)
              localStorage.setItem('setPage', +e.target.value)
            }}
          >
            20
          </Button>
          <Button
            size='small'
            color='info'
            variant='outlined'
            value={50}
            onClick={e => {
              setPageSize(+e.target.value)
              localStorage.setItem('setPage', +e.target.value)
            }}
          >
            50
          </Button>
          <Button
            size='small'
            color='info'
            variant='outlined'
            value={100}
            onClick={e => {
              setPageSize(+e.target.value)
              localStorage.setItem('setPage', +e.target.value)
            }}
          >
            100
          </Button>
        </Stack>
        <CustomPagination />
      </Stack>
    )
  }

  if (isLoading) return <CircularProgress />
  if (error) return <Alert severity='error'>{error}</Alert>

  return (
    <>
      <DataGrid
        sortingOrder={['desc', 'asc']}
        autoHeight
        columns={columns}
        onRowClick={handleOpen}
        rows={rows}
        pageSize={pageSize}
        rowsPerPageOptions={[10]}
        components={{ Toolbar: CustomToolbar, Footer: CustomFooter }}
        localeText={LocalizedTextsMap}
        density={localStorage.getItem('density') ? localStorage.getItem('density') : 'comfortable'}
      />
      <EditPartnerModal
        open={modalOpen}
        handleClose={handleClose}
        partner_data={selectedPartnerData}
      />
    </>
  )
}

export default PartnersList
