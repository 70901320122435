import React, { useState, useRef, useEffect } from 'react'
import { Box, MenuItem, Stack, Typography } from '@mui/material'
import 'index.css'
import PhoneNumber from 'awesome-phonenumber'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as Yup from 'yup'
import Layout from '../layouts/Layout'

import OschadInfoModal from '../components/oschadbank/OschadInfoModal'
import axios from 'axios'
import './Dots.scss'
import Header1 from 'components/common/Header1'
import { ColoredTextField, MyButton } from 'styled-components/Styled.styled'
import Footer from 'components/common/Footer'

const Oschadbank = () => {
  const start = useRef(null)

  const [modalOpen, setModalOpen] = useState(false)
  const [enableRequest, setEnableRequest] = useState(true)
  const [isLoading, setIsLoading] = useState(false)
  const [userData, setUserData] = useState({
    name: '',
    phone: '',
    idenf_code: '',
    address: '',
    email: '',
    amount_credit: '',
    credit_term: '',
    activity_type: ''
  })

  const [modalTitle, setModalTitle] = useState(
    'Вітаємо! Ви отримали попереднє погодження від ІдеяБанку.'
  )
  const [modalText, setModalText] = useState(
    'Очікуйте дзвінка представника банку найближчим часом.'
  )
  const [modalTextButton, setModalTextButton] = useState('Продовжити')
  const [checked, setChecked] = useState(true)
  const [error, setError] = useState(false)
  const [showModalTitle, setShowModalTitle] = useState(true)
  const { name, phone, idenf_code, address, email, amount_credit } = userData
  const handleChange = event => {
    setUserData({ ...userData, [event.target.name]: event.target.value })
  }

  const scrollToStart = errors => {
    trigger(undefined, { shouldFocus: false })
    start.current.scrollIntoView({ behavior: 'smooth' })
    if (errors.name) {
      errors.name.ref.focus({ preventScroll: true })
      return
    }
    if (errors.phone) {
      errors.phone.ref.focus({ preventScroll: true })
      return
    }
    if (errors.idenf_code) {
      errors.idenf_code.ref.focus({ preventScroll: true })
      return
    }
    if (errors.address) {
      errors.address.ref.focus({ preventScroll: true })
      return
    }
    if (errors.email) {
      errors.email.ref.focus({ preventScroll: true })
      return
    }
    if (errors.amount_credit) {
      errors.amount_credit.ref.focus({ preventScroll: true })
      return
    }
    if (errors.credit_term) {
      errors.credit_term.ref.focus({ preventScroll: true })
      return
    }
    if (errors.activity_type) {
      errors.activity_type.ref.focus({ preventScroll: true })
      return
    }
  }

  const saveLocalStorage = (
    modalTitle,
    modalText,
    modalTextButton,
    idenf_code = 0
  ) => {
    const timeRequest = new Date()
    if (idenf_code === 0) {
      localStorage.setItem('timeRequest', timeRequest.getTime())
    }
    localStorage.setItem('modalTitle', modalTitle)
    localStorage.setItem('modalText', modalText)
    localStorage.setItem('modalTextButton', modalTextButton)
    localStorage.setItem('idenf_code', idenf_code)
  }

  useEffect(() => {
    if (!localStorage.timeRequest) return
    const currentTime = new Date()
    if (currentTime.getTime() - localStorage.timeRequest > 2592000000) {
      setEnableRequest(true)
    } else {
      setEnableRequest(false)
      setModalText(localStorage.modalTitle)
      setModalTitle(localStorage.modalTitle)
      setModalTextButton(localStorage.modalTextButton)
    }
  }, [])

  async function fetchResult(id) {
    return new Promise((resolve, reject) => {
      const interval = setInterval(async () => {
        try {
          const response = await axios.get(
            `${process.env.REACT_APP_API_URL}/partners/ideabank-request/${id}`
          )
          const data = response.data
          if (data.error_message) {
            clearInterval(interval)
            clearTimeout(timeout)
            resolve(data)
          }
        } catch (error) {
          clearInterval(interval)
          clearTimeout(timeout)
          reject(error)
        }
      }, 5000)

      const timeout = setTimeout(() => {
        clearInterval(interval)
        setShowModalTitle(false)
        setModalTitle("Помилка зв'язку")
        setModalTextButton('Спробувати знову')
        setModalOpen(true)
        setEnableRequest(true)
        setIsLoading(false)
      }, 120000)
    })
  }

  const handleClickIdea = async () => {
    const values = getValues()
    setIsLoading(true)
    if (enableRequest && +userData.idenf_code !== +localStorage.idenf_code) {
      if (checked) {
        try {
          const IP = await axios.get('https://api.ipify.org?format=json')
          await axios.post(
            `${process.env.REACT_APP_API_URL}/partners/request/`,
            { obj: values, ip_address: IP.data.ip }
          )
        } catch (e) {
          console.log(e)
        }
        try {
          const response = await axios.post(
            `${process.env.REACT_APP_API_URL}/partner-site-visit/`,
            {
              company_id: 'ideabank',
              loan_offer_url: 'https://ideabank.ua/uk/product/lead/credits-sd',
              loan_pk: 19,
              return_click_id: true,
              webmaster_id: 'oschadbank',
              session_id: localStorage.uuid
            }
          )
          const data = await axios.post(
            `${process.env.REACT_APP_API_URL}/partners/ideabank/`,
            {
              ...userData,
              activity_type: values.activity_type,
              credit_term: values.credit_term,
              id_conversion: response.data
            }
          )

          const result = await fetchResult(data.data.object_id)

          if (+result.return_value === 0) {
            setModalTitle(
              'Вітаємо! Ви отримали попереднє погодження від ІдеяБанку.'
            )
            setModalText(
              'Очікуйте дзвінка представника банку найближчим часом.'
            )
            setModalTextButton('Продовжити')
            setModalOpen(true)
            setShowModalTitle(true)
            saveLocalStorage(
              'Вітаємо! Ви отримали попереднє погодження від ІдеяБанку.',
              'Очікуйте дзвінка представника банку найближчим часом.',
              'Продовжити',
              0
            )
            setEnableRequest(false)
          } else if (+result.return_value === 100) {
            setModalTitle(
              'Ви вказали невірний ІПН. Будь ласка, заповніть заявку повторно.'
            )
            setModalText('')
            setModalTextButton('Зрозуміло')
            setModalOpen(true)
            setShowModalTitle(true)
            saveLocalStorage(
              'Ви вказали невірний ІПН. Будь ласка, заповніть заявку повторно.',
              '',
              'Зрозуміло',
              userData.idenf_code
            )
          } else {
            setModalTitle(
              'Для отримання кредиту встановіть мобільний додаток izibank та пройдіть коротку реєстрацію, слідуючи підказкам і використовуючи паспорт та ІПН або ДІЯ.'
            )
            setModalText('')
            // setModalTitle(
            //   'На жаль, на даний час у нас немає пропозиції для вас.'
            // )
            // setModalTextButton('Зрозуміло')
            saveLocalStorage(
              'Для отримання кредиту встановіть мобільний додаток izibank та пройдіть коротку реєстрацію, слідуючи підказкам і використовуючи паспорт та ІПН або ДІЯ.',
              '',
              'Продовжити',
              0
            )
            setModalOpen(true)
            setShowModalTitle(false)
            setEnableRequest(false)
          }
        } catch (e) {
          console.log(e)
          setModalTitle(
            'Для отримання кредиту встановіть мобільний додаток izibank та пройдіть коротку реєстрацію, слідуючи підказкам і використовуючи паспорт та ІПН або ДІЯ.'
          )

          setModalText('')
          saveLocalStorage(
            'Для отримання кредиту встановіть мобільний додаток izibank та пройдіть коротку реєстрацію, слідуючи підказкам і використовуючи паспорт та ІПН або ДІЯ.',
            '',
            'Продовжити',
            0
          )
          setModalOpen(true)
          setShowModalTitle(false)
          setEnableRequest(false)
        }
      }
    } else {
      setModalOpen(true)
    }
    setIsLoading(false)
  }

  const phoneNumberValidation = Yup.string().test(
    'is-valid-phone',
    'Введіть дійсний номер телефону',
    value => {
      const phone = new PhoneNumber(value)
      return phone.isValid()
    }
  )

  const validationSchema = Yup.object().shape({
    name: Yup.string()
      .required('Необхідно ввести ПІБ повністю')
      .test(
        'len',
        'Необхідно ввести ПІБ повністю',
        val =>
          val
            .toString()
            .trim()
            .split(' ')
            .filter(el => el !== '').length > 2
      ),
    phone: phoneNumberValidation
      .length(13, 'Введіть дійсний номер телефону')
      .required('Введіть дійсний номер телефону'),
    idenf_code: Yup.string().required().length(10, 'Невірний код'),
    address: Yup.string().required(),
    email: Yup.string()
      .required()
      .matches(/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/)
      .email('Невірний Email'),
    amount_credit: Yup.number().required(),
    credit_term: Yup.number().required(),
    activity_type: Yup.string().required()
  })

  const {
    register,
    handleSubmit,
    formState: { errors },
    trigger,
    getValues
  } = useForm({
    resolver: yupResolver(validationSchema),
    shouldFocusError: false,
    defaultValues: {
      credit_term: '',
      activity_type: ''
    }
  })

  const handleClose = () => setModalOpen(false)

  const { onBlur, name: phoneName } = register('phone', {
    onBlur: () => {
      setUserData(prev => {
        if (prev.phone === '+380') {
          return { ...prev, phone: '' }
        }
        return prev
      })
    }
  })

  return (
    <>
      <Header1 />
      <Layout>
        <Box
          maxWidth='340px'
          m={0}
          display='flex'
          flexDirection='column'
          pt='25px'
          pb='30px'
        >
          <OschadInfoModal
            retry={handleClickIdea}
            open={modalOpen}
            handleClose={handleClose}
            title={modalTitle}
            text={modalText}
            showTitle={showModalTitle}
            textButton={modalTextButton}
          />

          <Box
            height='100%'
            display='flex'
            flexDirection='column'
            justifyContent='space-around'
          >
            <Typography
              fontWeight={500}
              textAlign='left'
              color='#0463EF'
              fontSize='34px'
              sx={{ fontFamily: 'Raleway' }}
              lineHeight={'120%'}
              marginBottom={'11px'}
            >
              Кращі банківські кредити з низькою процентною ставкою
            </Typography>
            <Typography
              fontWeight={400}
              textAlign='left'
              color='#5F5F68'
              fontSize='19px'
              sx={{ fontFamily: 'Raleway' }}
              lineHeight={'150%'}
              marginBottom={'11px'}
            >
              Попереднє рішення за одну хвилину
            </Typography>
            <Typography
              fontWeight={400}
              textAlign='left'
              color='#5F5F68'
              fontSize='19px'
              sx={{ fontFamily: 'Raleway' }}
              lineHeight={'150%'}
              marginBottom={'21px'}
            >
              Оформлення кредиту онлайн, без відвідування відділення
            </Typography>
            <Typography
              fontWeight={400}
              textAlign='left'
              color='#060B19'
              fontSize='30px'
              sx={{ fontFamily: 'Roboto' }}
              lineHeight={'150%'}
              marginBottom={'14px'}
              ref={start}
            >
              Подати заявку:
            </Typography>
            <Stack gap='18px' mb='18px'>
              <ColoredTextField
                label="Прізвище, ім'я, по батькові"
                variant='outlined'
                type='text'
                name='name'
                value={name}
                required
                size='small'
                {...register('name')}
                error={!!errors.name}
                helperText={errors.name?.message}
                onInput={e => handleChange(e)}
                sx={{ fontFamily: 'Roboto' }}
                InputLabelProps={{
                  sx: {
                    '&[data-shrink=true]': {
                      transform: 'translate(25px, -13px) scale(1)',
                      fontSize: 14,
                      backgroundColor: 'white !important',
                      paddingRight: '10px'
                    }
                  }
                }}
              />
              <ColoredTextField
                onFocus={() =>
                  setUserData(prev => {
                    if (!prev.phone) {
                      return {
                        ...prev,
                        phone: '+380'
                      }
                    }
                    return prev
                  })
                }
                inputProps={{ maxLength: 13 }}
                label='Мобільний телефон'
                variant='outlined'
                size='small'
                value={phone}
                helperText={
                  errors.phone && !errors.name && errors.phone?.message
                }
                name={phoneName}
                onBlur={onBlur}
                required
                {...register('phone')}
                error={errors.phone && !errors.name}
                onInput={e => {
                  const value = e.target.value.replace(
                    /[^\d]/g,
                    (match, index) => (index === 0 && match === '+' ? '+' : '')
                  )

                  if (!value.startsWith('+380')) {
                    if (value.startsWith('+38')) {
                      setUserData(prev => ({
                        ...prev,
                        phone: '+380' + value.replace(/^\+38/, '')
                      }))
                    } else if (
                      value.startsWith('+3') ||
                      value.startsWith('+')
                    ) {
                      setUserData(prev => ({
                        ...prev,
                        phone: '+380'
                      }))
                    } else {
                      setUserData(prev => ({
                        ...prev,
                        phone: '+380' + value
                      }))
                    }
                  } else {
                    if (value.length < 14) {
                      setUserData(prev => ({ ...prev, phone: value }))
                    }
                  }
                }}
                InputLabelProps={{
                  sx: {
                    '&[data-shrink=true]': {
                      transform: 'translate(25px, -13px) scale(1)',
                      fontSize: 14,
                      backgroundColor: 'white !important',
                      paddingRight: '10px'
                    }
                  }
                }}
                onKeyDown={e => {
                  const currentValue = e.target.value

                  if (
                    currentValue.startsWith('+380') &&
                    (e.key === 'Backspace' || e.key === 'Delete') &&
                    e.target.selectionStart <= 4
                  ) {
                    e.preventDefault()
                  }
                }}
              />
              <ColoredTextField
                label='Ідентифікаційний код'
                variant='outlined'
                size='small'
                name='idenf_code'
                value={idenf_code}
                required
                {...register('idenf_code')}
                error={
                  errors.idenf_code &&
                  !Object.keys(errors).some(el =>
                    ['name', 'phone'].includes(el)
                  )
                }
                onInput={e => {
                  const inputValue = e.target.value.replace(/[^\d]/g, '')
                  if (inputValue.length < 11) {
                    setUserData(prev => ({
                      ...prev,
                      idenf_code: inputValue
                    }))
                  }
                }}
                InputLabelProps={{
                  sx: {
                    '&[data-shrink=true]': {
                      transform: 'translate(25px, -13px) scale(1)',
                      fontSize: 14,
                      backgroundColor: 'white !important',
                      paddingRight: '10px'
                    }
                  }
                }}
              />
              <ColoredTextField
                label='Населенний пункт'
                variant='outlined'
                size='small'
                type='text'
                value={address}
                name='address'
                required
                {...register('address')}
                error={
                  errors.address &&
                  !Object.keys(errors).some(el =>
                    ['name', 'phone', 'idenf_code'].includes(el)
                  )
                }
                onInput={e => handleChange(e)}
                InputLabelProps={{
                  sx: {
                    '&[data-shrink=true]': {
                      transform: 'translate(25px, -13px) scale(1)',
                      fontSize: 14,
                      backgroundColor: 'white !important',
                      paddingRight: '10px'
                    }
                  }
                }}
              />
              <ColoredTextField
                label='Електронна пошта'
                variant='outlined'
                size='small'
                name='email'
                value={email}
                required
                {...register('email')}
                error={
                  errors.email &&
                  !Object.keys(errors).some(el =>
                    ['name', 'phone', 'idenf_code', 'address'].includes(el)
                  )
                }
                onInput={e => handleChange(e)}
                InputLabelProps={{
                  sx: {
                    '&[data-shrink=true]': {
                      transform: 'translate(25px, -13px) scale(1)',
                      fontSize: 14,
                      backgroundColor: 'white !important',
                      paddingRight: '10px'
                    }
                  }
                }}
              />
              <Box
                display='grid'
                gridTemplateColumns={'175px 149px'}
                columnGap={2}
              >
                <ColoredTextField
                  label='Сума, грн.'
                  variant='outlined'
                  size='small'
                  value={amount_credit}
                  name='amount_credit'
                  required
                  {...register('amount_credit')}
                  error={
                    errors.amount_credit &&
                    !Object.keys(errors).some(el =>
                      [
                        'name',
                        'phone',
                        'idenf_code',
                        'address',
                        'email'
                      ].includes(el)
                    )
                  }
                  onInput={e => {
                    const inputValue = e.target.value.replace(/[^\d]/g, '')
                    if (inputValue.length < 7) {
                      setUserData(prev => ({
                        ...prev,
                        amount_credit: inputValue
                      }))
                    }
                  }}
                  InputLabelProps={{
                    sx: {
                      '&[data-shrink=true]': {
                        transform: 'translate(25px, -13px) scale(1)',
                        fontSize: 14,
                        backgroundColor: 'white !important',
                        paddingRight: '10px'
                      }
                    }
                  }}
                />
                <ColoredTextField
                  label='Термін'
                  variant='outlined'
                  select
                  size='small'
                  name='credit_term'
                  required
                  {...register('credit_term')}
                  error={
                    errors.credit_term &&
                    !Object.keys(errors).some(el =>
                      [
                        'name',
                        'phone',
                        'idenf_code',
                        'address',
                        'email',
                        'amount_credit'
                      ].includes(el)
                    )
                  }
                  onChange={e => {
                    register('credit_term').onChange(e)
                  }}
                  defaultValue=''
                  value={register('credit_term').value}
                  InputLabelProps={{
                    sx: {
                      '&[data-shrink=true]': {
                        transform: 'translate(25px, -13px) scale(1)',
                        fontSize: 14,
                        backgroundColor: 'white !important',
                        paddingRight: '10px'
                      }
                    }
                  }}
                >
                  <MenuItem value='6'>6 місяців</MenuItem>
                  <MenuItem value='9'>9 місяців</MenuItem>
                  <MenuItem value='12'>12 місяців</MenuItem>
                  <MenuItem value='18'>18 місяців</MenuItem>
                  <MenuItem value='24'>24 місяців</MenuItem>
                </ColoredTextField>
              </Box>
              <ColoredTextField
                defaultValue=''
                label='Працевлаштування'
                variant='outlined'
                size='small'
                type='text'
                name='activity_type'
                onChange={e => {
                  register('credit_term').onChange(e)
                }}
                value={register('credit_term').value}
                required
                {...register('activity_type')}
                error={
                  errors.activity_type &&
                  !Object.keys(errors).some(el =>
                    [
                      'name',
                      'phone',
                      'idenf_code',
                      'address',
                      'email',
                      'amount_credit',
                      'credit_term'
                    ].includes(el)
                  )
                }
                select
                InputLabelProps={{
                  sx: {
                    '&[data-shrink=true]': {
                      transform: 'translate(25px, -13px) scale(1)',
                      fontSize: 14,
                      backgroundColor: 'white !important',
                      paddingRight: '10px'
                    }
                  }
                }}
              >
                <MenuItem value='1'>Працюю офіційно / не офіційно</MenuItem>
                <MenuItem value='2'>Підприємець (ФОП)</MenuItem>
                <MenuItem value='3'>Пенсіонер</MenuItem>
                <MenuItem value='4'>Студент</MenuItem>
                <MenuItem value='5'>Декретна відпустка</MenuItem>
                <MenuItem value='6'>Тимчасово не працюю</MenuItem>
              </ColoredTextField>
            </Stack>
            <Stack
              direction='row'
              justifyContent='center'
              alignItems='center'
              spacing={2}
              mb='18px'
            >
              <MyButton onClick={handleSubmit(handleClickIdea, scrollToStart)}>
                {isLoading ? (
                  <div id='dots4'>
                    <span></span>
                    <span></span>
                    <span></span>
                    <span></span>
                  </div>
                ) : (
                  'ОТРИМАТИ КРЕДИТ'
                )}
              </MyButton>
            </Stack>
            <Box position='relative' mb='21px' style={{ cursor: 'pointer' }}>
              <svg
                onClick={() => {
                  setChecked(prev => !prev)
                  setError(prev => !prev)
                }}
                width='24'
                height='24'
                viewBox='0 0 24 24'
                fill='none'
                xmlns='http://www.w3.org/2000/svg'
                style={{
                  color: error ? 'red' : '#060B19',
                  padding: 0,
                  marginRight: 5,
                  transform: 'translate(0px, 7px)',
                  borderRadius: 10,
                  cursor: 'pointer'
                }}
              >
                <g id='vuesax/linear/tick-square'>
                  <g id='tick-square'>
                    <path
                      id='Vector'
                      d='M9 22H15C20 22 22 20 22 15V9C22 4 20 2 15 2H9C4 2 2 4 2 9V15C2 20 4 22 9 22Z'
                      stroke={error ? 'red' : '#060B19'}
                      strokeWidth='1.5'
                      strokeLinecap='round'
                      strokeLinejoin='round'
                    />
                    <path
                      id='Vector_2'
                      d='M7.75 12L10.58 14.83L16.25 9.16998'
                      stroke={error ? 'red' : '#060B19'}
                      strokeWidth='1.5'
                      strokeLinecap='round'
                      strokeLinejoin='round'
                    />
                  </g>
                </g>
              </svg>

              <div
                style={{
                  position: 'absolute',
                  top: 10,
                  left: 4,
                  width: 17,
                  height: 17,
                  backgroundColor: 'white',
                  borderRadius: 10,
                  opacity: checked ? 0 : 1,
                  transition: 'opacity 250ms ease-in-out',
                  pointerEvents: 'none'
                }}
              ></div>
              <Typography
                display='inline'
                fontWeight={400}
                textAlign='left'
                color='#060B19'
                fontSize='15px'
                sx={{ fontFamily: 'Roboto' }}
                lineHeight={'150%'}
              >
                Натискаючи “Отримати кредит”, я надаю{' '}
                <a href='/privacy-policy'>
                  згоду на обробку моїх персональних даних
                </a>{' '}
                для оформлення заявки.
              </Typography>
            </Box>
          </Box>
        </Box>
      </Layout>
      <Footer />
    </>
  )
}

export default Oschadbank
