import React, { useEffect, useState } from 'react'
import EditOfferInfo from './edit/EditOfferInfo'
import { useDispatch } from 'react-redux'
import { createNewOffer } from 'store/reducers/admin/adminActions'
import MyModal from 'UI/MyModal'

const initOfferDataState = {
  company: null,
  company_logo_url: '',
  loan_offer_url: '',
  interest_rate: '',
  loan_info: '',
  loan_terms: '',
  max_loan_amount: null,
  showcase_position: null,
  conversion_payment: '',
  commission: '',
  send_click_id: false
}

const CreateOffer = ({ open, handleClose }) => {
  const dispatch = useDispatch()

  const [offerData, setOfferData] = useState(initOfferDataState)

  const [isButtonActive, setIsButtonActive] = useState(false)

  const handleChange = e =>
    setOfferData({ ...offerData, [e.target.name]: e.target.value })

  const handleChecked = e =>
    setOfferData({ ...offerData, [e.target.name]: e.target.checked })

  const closeModalAndClearFields = () => {
    handleClose()
    setOfferData(initOfferDataState)
  }

  const handleCreate = () => {
    dispatch(createNewOffer(offerData))
    setOfferData(initOfferDataState)
    closeModalAndClearFields()
  }

  useEffect(() => {
    setIsButtonActive(
      Object.values(offerData).every(x => x !== null && x !== '')
    )
  }, [offerData])

  return (
    <MyModal open={open} onClose={closeModalAndClearFields}>
      <EditOfferInfo
        offerData={offerData}
        handleChange={handleChange}
        handleChecked={handleChecked}
        handleClick={handleCreate}
        isCreation={true}
        buttonDisabled={!isButtonActive}
      />
    </MyModal>
  )
}

export default CreateOffer
