import { Box, Typography } from '@mui/material'
import { GrayBtn } from 'styled-components/Styled.styled'
import Link from '@mui/material/Link'
import {  useLocation } from 'react-router-dom'

function Footer() {
  const location = useLocation();
  return (
    <Box backgroundColor='#F3F3F3'>
      <Box width='340px' mx='auto' pt='36px' pb='32px'>
        <GrayBtn
          onClick={() => {
            window.location.href = 'mailto:office@leadleader.com.ua'
          }}
        >
          Зв'язатися з нами
        </GrayBtn>
        <Typography
          fontWeight={400}
          textAlign='left'
          color='#060B19'
          fontSize='15px'
          sx={{ fontFamily: 'Roboto' }}
          lineHeight='150%'
          marginTop='26px'
          marginBottom='18px'
          width='250px'
        >
          ТОВАРИСТВО З ОБМЕЖЕНОЮ ВІПОВІДАЛЬНІСТЮ «ЛІД ЛІДЕР» Код за ЄДРПОУ
          44740684
        </Typography>
        <Typography
          fontWeight={400}
          textAlign='left'
          color='#060B19'
          fontSize='15px'
          sx={{ fontFamily: 'Roboto' }}
          lineHeight={'150%'}
          margintop={'21px'}
          width='180px'
        >
          © 2022-2023 Lead Leader All Rights Reserved
        </Typography>
        {(location.pathname === '/form' || location.pathname === '/oschadbank') && <>
        <Link href="https://ideabank.ua/sites/default/files/2023-11/Istotni_Mobilnuy_dlya_lidogeneratoriv_.pdf"
        display="block"          
        fontWeight={400}
          textAlign='left'
          color='#060B19'
          fontSize='15px'
          sx={{ fontFamily: 'Roboto' }}
          lineHeight={'150%'}
          marginTop='25px'
          target="_blank"
          // underline="hover"
        >Інформація про істотні характеристики продукту «Мобільний»</Link>
         <Link href="https://ideabank.ua/sites/default/files/2023-11/Calculator_Mobilnuy_dlya_lidogeneratoriv_SK.xlsx"
        display="block"          
        fontWeight={400}
          textAlign='left'
          color='#060B19'
          fontSize='15px'
          sx={{ fontFamily: 'Roboto' }}
          lineHeight={'150%'}
          marginTop='25px'
        >Калькулятор продукту «Мобільний» з СК </Link>
         <Link href="https://ideabank.ua/sites/default/files/2023-11/Calculator_Mobilnuy_dlya_lidogeneratoriv_bez_SK.xlsx"
        display="block"          
        fontWeight={400}
          textAlign='left'
          color='#060B19'
          fontSize='15px'
          sx={{ fontFamily: 'Roboto' }}
          lineHeight={'150%'}
          marginTop='25px'
        >Калькулятор продукту «Мобільний» без СК</Link>
          <Link href="https://ideabank.ua/uk/cash-credits/hotivkovyy-kredyt-mobilnyy"
        display="block"          
        fontWeight={400}
          textAlign='left'
          color='#060B19'
          fontSize='15px'
          sx={{ fontFamily: 'Roboto' }}
          lineHeight={'150%'}
          marginTop='25px'
          target="_blank"
        >Попередження про можливі наслідки для клієнта в разі користування продуктом «Мобільний»</Link>
        </>
        }        
      </Box>
    </Box>
  )
}

export default Footer
