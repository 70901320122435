import './Menu.scss'
import { Link } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import React, { useEffect, useState } from 'react'
// import { checkUserAuthenticated } from 'store/reducers/admin/adminActions'

export const Menu = ({
  updateMenu,
  // webDewelopScroll,
  // advertriseScroll,
  contactsScroll
}) => {
  const { isAuthenticated } = useSelector(state => state.admin)
  const [chandgeAuthenticated, setChandgeAuthenticated] = useState(false)
  const dispatch = useDispatch()

  // const handleWebDevelop = () => {
  //   if(updateMenu) {
  //     updateMenu();
  //   }
  //     //  webDewelopScroll();
  // }

  // const handleAdvertrise = () => {
  //   if(updateMenu) {
  //     updateMenu();
  //   }
  //   // advertriseScroll();
  // }

  const handleContacts = () => {
    if (updateMenu) {
      updateMenu()
    }
    contactsScroll()
  }

  useEffect(() => {
    // dispatch(checkUserAuthenticated())
    if (isAuthenticated) {
      setChandgeAuthenticated(true)
    }
  }, [dispatch, isAuthenticated])

  return (
    <menu className='menu'>
      <div className='menu__container'>
        <div>
          {chandgeAuthenticated && (
            <Link
              to={'/admin/conversions/'}
              className='menu__for-user-link menu__for-user-link-cabinet'
            >
              Кабінет
            </Link>
          )}
        </div>

        {!chandgeAuthenticated && (
          <div className='menu__for-user'>
            <Link to={'/admin/login/'} className='menu__for-user-link'>
              Вхід
            </Link>

            <Link to={'/admin/registration/'} className='menu__for-user-link'>
              Реєстрація
            </Link>
          </div>
        )}
        <ul className='menu__nav'>
          {/* <li className='menu__nav-item'>
            <div className='menu__nav-link' onClick={handleWebDevelop}>
              Вебмайстрам
            </div>
          </li>
          <li className='menu__nav-item'>
            <div className='menu__nav-link' onClick={handleAdvertrise}>
              Рекламодавцям
            </div>
          </li> */}
          <li className='menu__nav-item'>
            <div className='menu__nav-link' onClick={handleContacts}>
              Контакти
            </div>
          </li>
        </ul>
      </div>
    </menu>
  )
}
