const LocalizedTextsMap = {
      columnMenuUnsort: "Не сортувати",
      columnMenuSortAsc: "В порядку зростання",
      columnMenuSortDesc: "В порядку спадання",
      columnMenuFilter: "Фільтр",
      columnMenuHideColumn: "Приховати",
      columnMenuShowColumns: "Показати стовпці",
  
      toolbarDensity: 'Вид',
      toolbarDensityLabel: 'Вид',
      toolbarDensityCompact: 'Компактний',
      toolbarDensityStandard: 'Стандартний',
      toolbarDensityComfortable: 'Комфортний',
  
      toolbarColumns: 'Стовпці',
      toolbarColumnsLabel: 'Виберіть стовпці',
  
      toolbarFilters: 'Фільтри',
      toolbarFiltersLabel: 'Показати фільтри',
      toolbarFiltersTooltipHide: 'Сховати фільтри',
      toolbarFiltersTooltipShow: 'Показати фільтри',
      toolbarFiltersTooltipActive: (count) =>
        count !== 1 ? `${count} активні фільтри` : `${count} активні фільтри`,
  
      toolbarExport: 'Експорт',
      toolbarExportLabel: 'Експорт',
      toolbarExportCSV: 'Завантажити як CSV',
      toolbarExportPrint: 'Роздрукувати',
      toolbarExportExcel: 'Завантажити як Excel',  
  
      columnsPanelTextFieldLabel: 'Знайти стовпець',
      columnsPanelTextFieldPlaceholder: 'Назва колонки',
      columnsPanelDragIconLabel: 'Перевпорядкувати колонку',
      columnsPanelShowAllButton: 'Показати все',
      columnsPanelHideAllButton: 'Приховати все',
  
      filterPanelAddFilter: 'Додати фільтр',
      filterPanelDeleteIconLabel: 'Видалити',
      filterPanelLinkOperator: 'Логічний оператор',
      filterPanelOperators: 'Оператор', // TODO v6: rename to filterPanelOperator
      filterPanelOperatorAnd: 'i',
      filterPanelOperatorOr: 'або',
      filterPanelColumns: 'Стовпці',
      filterPanelInputLabel: 'Значення',
      filterPanelInputPlaceholder: 'Значення фільтра',
  
      filterOperatorContains: 'містить',
      filterOperatorEquals: 'дорівнює',
      filterOperatorStartsWith: 'починається з',
      filterOperatorEndsWith: 'закінчується на',
      filterOperatorIs: 'є',
      filterOperatorNot: 'не',
      filterOperatorAfter: 'є після',
      filterOperatorOnOrAfter: 'на або після',
      filterOperatorBefore: 'є раніше',
      filterOperatorOnOrBefore: 'увімкнено або раніше',
      filterOperatorIsEmpty: 'пусто',
      filterOperatorIsNotEmpty: 'не пусто',
      filterOperatorIsAnyOf: 'є будь-яким із',
}

export default LocalizedTextsMap
