import { Checkbox, Typography } from '@mui/material'
import './CustomFilterPanel.scss'
import CloseIcon from '@mui/icons-material/Close'

export const CustomFilterPanel = ({ props }) => {
  const {
    onApply,
    filters,
    currentFilter,
    closePanel,
    showFilterPanel,
    companyNames
  } = props

  function updateFilter(option, field) {
    let newValues
    if (currentFilter[field].values.includes(option)) {
      newValues = [...currentFilter[field].values.filter(el => el !== option)]
    } else {
      newValues = [...currentFilter[field].values, option]
    }

    if (field === 'company_name') {
      localStorage.setItem(
        'filtersCompany',
        JSON.stringify({ values: newValues })
      )
    }
    if (field === 'webmaster_id') {
      localStorage.setItem(
        'filtersWebmasterId',
        JSON.stringify({ values: newValues })
      )
    }
    if (field === 'status') {
      localStorage.setItem(
        'filtersStatus',
        JSON.stringify({ values: newValues })
      )
    }
    if (field === 'goal') {
      localStorage.setItem('filtersGoal', JSON.stringify({ values: newValues }))
    }

    onApply(prev => ({
      ...prev,
      [field]: {
        ...prev[field],
        values: [...newValues]
      }
    }))
  }

  return (
    <>
      {showFilterPanel && (
        <div className='filter-panel__grid'>
          {filters &&
            filters.map(el => (
              <div key={el.field}>
                <Typography className='filter-panel__list-header'>
                  {el.headerName}
                </Typography>
                <ul className='filter-panel__list'>
                  {el.field === 'company_name'
                    ? companyNames.map(company => (
                        <li key={company} className='filter-panel__list-item'>
                          <Checkbox
                            onChange={() => updateFilter(company, el.field)}
                            value={company}
                            checked={currentFilter[el.field].values.includes(
                              company
                            )}
                          />
                          <Typography>{company}</Typography>
                        </li>
                      ))
                    : el.valueOptions?.map(option => (
                        <li key={option} className='filter-panel__list-item'>
                          <Checkbox
                            onChange={() => updateFilter(option, el.field)}
                            value={option}
                            checked={currentFilter[el.field].values.includes(
                              option
                            )}
                          />
                          <Typography>{option}</Typography>
                        </li>
                      ))}
                </ul>
              </div>
            ))}
          <button className='filter-panel__close' onClick={closePanel}>
            <CloseIcon />
          </button>
        </div>
      )}
    </>
  )
}
