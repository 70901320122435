import LoanList from 'components/loans/LoanList'
import { useSelector } from 'react-redux'
import { Box, Typography } from '@mui/material'
import Header from 'components/common/Header1'
import Footer from 'components/common/Footer'
import './Dots.scss'

const Credit = () => {
  const { loans } = useSelector(state => state.loans)

  // const observer = useRef()

  // const lastLoanElementRef = useCallback(
  //   node => {
  //     if (observer.current) {
  //       observer.current.disconnect()
  //     }
  //     observer.current = new IntersectionObserver(entries => {
  //       if (entries[0].isIntersecting && loans.length > 0) {
  //         setPage(prevPage => prevPage + 1)
  //       }
  //     })
  //     if (node) observer.current.observe(node)
  //   },
  //   [loans]
  // )

  return (
    <>
      <Header />
      <Box pt='25px' pb='47px' maxWidth='340px' mx='auto'>
        <Typography
          color='#0463EF'
          sx={{ fontFamily: 'Raleway' }}
          fontSize='34px'
          fontWeight='500'
          lineHeight='120%'
          mb='25px'
        >
          Кращі кредитні пропозиції
        </Typography>
        {loans.length === 0 && (
          <div
            style={{
              width: '100%',
              height: 300,
              display: 'grid',
              placeItems: 'center'
            }}
          >
            <div id='dots4' className='blue'>
              <span></span>
              <span></span>
              <span></span>
              <span></span>
            </div>
          </div>
        )}
        <LoanList />
      </Box>
      {/* <div ref={lastLoanElementRef}></div> */}
      <Footer />
    </>
  )
}

export default Credit
