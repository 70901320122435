import React from 'react'
import { Box, Button, Grid, styled, Typography } from '@mui/material'

import { createConversationsVisit } from 'store/reducers/loans/loanActions'
import { useDispatch } from 'react-redux'

const Item = styled(Box)(({ theme }) => ({
  paddingInline: '22px 27px',
  paddingBlock: 11,
  textAlign: 'center',
  color: theme.palette.text.secondary,
  border: `2px solid #0AD5FF`,
  borderRadius: 4,
  height: 100,
  display: 'grid',
  gridTemplateColumns: '1fr 1fr',
  alignItems: 'center',
  columnGap: 24
}))

const LoanItem = ({ loan }) => {
  const dispatch = useDispatch()

  const visitPartner = () => {
    const loanItem = {
      company_id: loan.company.company_id,
      loan_offer_url: loan.loan_offer_url,
      loan_pk: loan.id,
      return_click_id: loan.send_click_id,
      webmaster_id: 'ocean',
      session_id: localStorage.uuid
    }
    dispatch(createConversationsVisit(loanItem))
  }

  return (
    <>
      <Grid item xs={12}>
        <Item>
          <div
            style={{
              height: 36,
              display: 'grid',
              justifyContent: 'center',
              alignItems: 'center',
              marginBottom: 5
            }}
          >
            <img
              src={loan.company_logo_url}
              alt='company logo'
              style={{
                maxWidth: '132px',
                maxHeight: '36px',
                display: 'block',
                width: '100%',
                height: 'auto'
              }}
            />
          </div>
          <Typography
            variant='h6'
            sx={{ gridColumn: 1, fontFamily: 'Rubik Medium', mb: '4px' }}
            color='#5F5F68'
            fontSize='18px'
            lineHeight='100%'
          >
            до {loan.max_loan_amount.toLocaleString('en-US')} грн
          </Typography>
          <Typography
            sx={{ gridColumn: 1, fontFamily: 'Rubik Regular' }}
            color='#0463EF'
            fontSize='14px'
            lineHeight='1'
          >
            від {loan.interest_rate}%
          </Typography>
          <Button
            sx={{
              fontSize: 18,
              backgroundColor: '#0463EF',
              gridRow: '1/4',
              gridColumn: 2,
              height: '50px',
              fontFamily: 'Rubik Regular',
              color: '#fff',
              borderRadius: '4px',
              '&:hover': {
                backgroundColor: '#0463EF',
                color: '#fff'
              },
              '&:focus': {
                backgroundColor: '#0463EF',
                color: '#fff'
              },
              '&:active': {
                backgroundColor: '#0463EF',
                color: '#fff'
              }
            }}
            onClick={() => visitPartner()}
          >
            ОТРИМАТИ
          </Button>
        </Item>
      </Grid>
    </>
  )
}

export default LoanItem
