import { Box, Typography } from '@mui/material'
import Header1 from '../components/common/Header1'
import Footer from 'components/common/Footer'

function PrivacyPolicy() {
  return (
    <>
      <Header1 />
      <Box maxWidth='340px' mx='auto' pt='26px' pb='54px'>
        <Typography
          color='#0463EF'
          sx={{ fontFamily: 'Raleway' }}
          fontSize='24px'
          fontWeight={500}
          lineHeight={'120%'}
          mb='26px'
        >
          Згода на обробку/передачу персональних даних
        </Typography>
        <Typography color='#060B19' sx={{ fontFamily: 'Roboto' }} mb='19px'>
          Враховуючи вимоги Закону України «Про захист персональних даних»,
          Користувач:
        </Typography>
        <ul style={{ paddingLeft: 20 }}>
          <li>
            <Typography color='#060B19' sx={{ fontFamily: 'Roboto' }}>
              Надає ТОВ «ЛІД ЛІДЕР» необмежену строком згоду на доступ, обробку,
              збирання, передачу, зберігання (далі – обробка), будь-якими іншими
              фінансовими установами-кредитодавцями (далі – ТОВ «ЛІД ЛІДЕР» та
              вказані фінансові установи-кредитодавці – Установи), персональних
              даних щодо Користувача, які були передані ТОВ «ЛІД ЛІДЕР», у тому
              числі, третіми особами, у зв’язку з розглядом питання можливості
              надання Користувачу споживчого кредиту (у тому числі, але не
              обмежуючись, з метою виконання ідентифікації клієнта), в обсязі,
              що міститься в кредитній заяві та інших документах,
              підписаних/засвідчених та поданих Користувачем та/або третіми
              особами. Метою обробки персональних даних поданих Користувача є
              обмін вказаною інформацією в процесі взаємодії з іншими Установами
              для забезпечення здійснення підбору для Користувача пропозицій з
              різними умовами кредитування від інших Установ, а також обміну та
              систематизації такої інформації для врахування в діяльності
              задіяних в процесі Установ.
            </Typography>
          </li>
          <li>
            <Typography color='#060B19' sx={{ fontFamily: 'Roboto' }} mb='19px'>
              Підтверджує, що він поінформований про своє право щодо можливості
              відкликання згоди на обробку персональних даних для цілей,
              зазначених вище, шляхом подання до ТОВ «ЛІД ЛІДЕР» заяви про
              відкликання згоди на обробку персональних даних.
            </Typography>
          </li>
        </ul>
        <Typography color='#060B19' sx={{ fontFamily: 'Roboto' }} mb='19px'>
          Користувач усвідомлює, що його інформування про можливість отримання
          кредиту в інших Установах здійснюється шляхом:
        </Typography>
        <ul style={{ paddingLeft: 20 }}>
          <li>
            <Typography color='#060B19' sx={{ fontFamily: 'Roboto' }}>
              Надсилання Користувачу електронних повідомлень (SMS, VIBER), які
              містять рекламно-інформаційний характер про можливість отримання
              фінансових послуг (споживчого кредитування) від інших фінансових
              установ із посиланням на веб сайт таких установ від ТОВ «ЛІД
              ЛІДЕР» та/або інших фінансових установ-кредитодавців на наданий
              Користувачем номер мобільного телефону чи іншими комунікаційними
              засобами (в тому числі за допомогою третіх осіб), обраними на
              власний розсуд Банком/фінансовими установами;
            </Typography>
          </li>
          <li>
            <Typography color='#060B19' sx={{ fontFamily: 'Roboto' }} mb='19px'>
              Здійснення телефонних дзвінків Клієнту на наданий клієнтом номер
              мобільного телефону, що містять рекламно-інформаційний характер з
              пропозиціями отримання послуг від будь-яких інших Установ
              (кредитодавців, ТОВ «ЛІД ЛІДЕР»).
            </Typography>
          </li>
        </ul>
      </Box>

      <Footer />
    </>
  )
}

export default PrivacyPolicy
