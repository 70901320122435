import styled from '@emotion/styled'
import logo1 from '../../assets/images/Logo-header.svg'
import menu from '../../assets/images/List.svg'
import { useNavigate } from 'react-router-dom'

const Background = styled.div`
  background-color: white;
  border-bottom: 1px solid #e3e6eb;
`

const HeaderStyled = styled.div`
  width: 100%;
  padding-block: 12px;
  max-width: 340px;
  margin-inline: auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 56px;
  & img:nth-of-type(1) {
    cursor: pointer;
    height: 38px;
    width: 222px;
    margin-top: 8px;
  }
`

const Header = () => {
  const navigate = useNavigate()

  return (
    <Background>
      <HeaderStyled>
        <img
          src={logo1}
          alt='logo'
          onClick={() => navigate('/')}
          width='222'
          height='38'
        />
        <img
          style={{ cursor: 'pointer' }}
          src={menu}
          alt='menu'
          width='32'
          height='32'
          onClick={() => {
            setTimeout(
              () =>
                window.scrollTo({
                  top: document.body.scrollHeight,
                  behavior: 'smooth'
                }),
              10
            )
          }}
        />
      </HeaderStyled>
    </Background>
  )
}

export default Header
