import React from 'react'
import CssBaseline from '@mui/material/CssBaseline'
// import Container from '@mui/material/Container'
import {
  Box  
} from '@mui/material'
// import { DriveEta } from '@mui/icons-material'

const Layout = props => {

  return (
    <>
      <CssBaseline />
      <Box 
        sx={{fontFamily: 'Futura', display: 'flex', justifyContent: 'center', alignItems: 'center' }}
      >
        {props.children}
      </Box>
    </>
  )
}

export default Layout
