import './ResetPassword.scss'
import Logo from '../../../../assets/images/Logo-registration.svg'
import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { Header } from '../../../../components/homeNew/Header'
import { useDispatch, useSelector } from 'react-redux'
import { resetPasswordUser } from 'store/reducers/admin/adminActions'

export const ResetPassword = () => {
  const { message } = useSelector(state => state.admin);
  
  const [togleResetPassord, setTogleResetPassord] = useState(false);
  const dispatch = useDispatch();
  const [email, setEmail] = useState('');

  const handleChange = e =>
  setEmail( e.target.value );

  const handleClick = () => {
    dispatch(resetPasswordUser(email));  
    setTogleResetPassord(true);
  }

  const handleSubmit = (event) => {
    event.preventDefault();  
  }

  return (
    <main className='reset-password'>
       <div className='reset-password__header'>
        <Header/>
      </div>
      <div className='reset-password__container'>
        <div className='reset-password__block-menu'>
          <Link className='reset-password__logo' to='/'>
            <img className='reset-password__logo-img' src={Logo} alt='logo' />
          </Link>
          <Link  
            to='/'           
            className='reset-password__button-close'
          ></Link>
        </div>
        <h2 className='reset-password__title'>Відновлення пароля</h2>
        <form 
          onSubmit={handleSubmit}  
          className='reset-password__form'
        >
          <div className='reset-password__personal-information'>
            <input
              type='email'
              name='email-reset-pasworf'
              className='reset-password__item'
              placeholder='E-mail'
              required
              value={email}
              onChange={handleChange}
            />
          </div>
          {(togleResetPassord && message) && <p className='reset-password__messege'>{message}</p>}
          {(togleResetPassord && message === "Новий пароль було надіслано на вашу електронну адресу") ? (
            <div onClick={() => {setTogleResetPassord(false)}}  >
              <Link  
            to='/'  
           className='reset-password__button'  
        > Продовжити
        </Link>
            </div>            
           ) : (
            <button
            onClick={handleClick}
            className='reset-password__button'                 
          > Відправити 
          </button>
          )} 
        </form>
      </div>
    </main>
  )
}

export default ResetPassword 