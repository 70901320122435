import React, { useEffect} from 'react'
import axios from 'axios'
import {
  checkUserAuthenticated,
  loadUserData
} from 'store/reducers/admin/adminActions'
import { useDispatch, useSelector } from 'react-redux'
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom'
import useQuery from 'hooks/react-router-dom'
import Page404 from './pages/404'
import Credit from './pages/Credit'
import AdminPage from './pages/admin/AdminPage'
import ProfilePage from './pages/admin/ProfilePage'
import ConversionsPage from './pages/admin/main/ConversionsPage'
import Login from './pages/admin/auth/Login'
import Registration from './pages/admin/auth/Registration/Registration'
import ResetPassword from './pages/admin/auth/ResetPassword/ResetPassword'
import ProfilesPage from './pages/admin/main/ProfilesPage'
import OffersPage from './pages/admin/main/OffersPage'
import PartnersPage from './pages/admin/main/PartnersPage'
import PostBack from './pages/PostBack'
import Home from 'pages/Home'
import Oschadbank from './pages/Oschadbank'
import Oschadbank1 from './pages/Oschadbank1'
import PrivacyPolicy from 'pages/PrivacyPolicy'
import { v4 as uuidv4 } from 'uuid';
 

const DocsPage = () => {
  useEffect(() => {
    window.location.reload()
  }, [])

  return (
    <iframe
      src='/docs/index.html'
      title='docs'
      style={{ width: '100%', height: '100%' }}
    />
  )
}

const App = () => {
  const utm_source = useQuery().get('utm_source')
  const utm_tags = useLocation().search

  const { isAuthenticated } = useSelector(state => state.admin)
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const location = useLocation()

  useEffect(() => {
    if(!localStorage.uuid) {
      localStorage.setItem("uuid", uuidv4())
    } 
  }, [])

  useEffect(() => {
    if (location.pathname === '/form') {
      document.title = 'Кращі банківські кредити з низькою процентною ставкою'
    } else {
      document.title = 'Lead Leader'
    }
  }, [location.pathname])

  useEffect(() => {
    const fetchData = () => {
      dispatch(checkUserAuthenticated())
      if (isAuthenticated) dispatch(loadUserData())
    }
    fetchData()
  }, [dispatch, isAuthenticated, navigate])

  useEffect(() => {

    localStorage.setItem('webmaster_id', utm_source)
    axios.post(`${process.env.REACT_APP_API_URL}/site-call/`, {
      utm_source,
      utm_tags,
      visit_url: window.location.href,
      session_id: localStorage.getItem("uuid")
    })

  }, [utm_source, utm_tags])

  return (
    <Routes>
      <Route path='/' element={<Home />} />
      <Route path='/credit' element={<Credit />} />
      <Route path='/oschadbank' element={<Oschadbank1 />} />
      <Route path='/form1' element={<Oschadbank />} />
      <Route path='/form' element={<Oschadbank1 />} />
      <Route path='/docs' component={DocsPage} />
      <Route path='/privacy-policy' element={<PrivacyPolicy />} />
      <Route path='admin/'>
        <Route index element={<AdminPage />} />
        <Route path='registration/' element={<Registration />} />
        <Route path='resetpassword/' element={<ResetPassword />} />
        <Route path='login/' element={<Login />} />
        <Route path='conversions/' element={<ConversionsPage />} />
        <Route path='profile/' element={<ProfilePage />} />
        <Route path='profiles/' element={<ProfilesPage />} />
        <Route path='offers/' element={<OffersPage />} />
        <Route path='partners/' element={<PartnersPage />} />
      </Route>
      <Route path='postback/' element={<PostBack />} />
      <Route path='sd-postback/' element={<PostBack />} />
      <Route path='*' element={<Page404 />} />
    </Routes>
  )
}

export default App
