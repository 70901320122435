import React, { useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { Alert, Box, Button, Stack } from '@mui/material'
import { DataGrid } from '@mui/x-data-grid'
import EditProfileModal from './edit/EditProfileModal'
import LocalizedTextsMap from '../../../utils/localizedTextsMap.js'
import { CustomPagination } from '../ConversionsList'

const ProfilesList = () => {
  const { error, profiles, user } = useSelector(state => state.admin)
  const [pageSize, setPageSize] = useState(localStorage.setPage ? localStorage.setPage : 10)

  const translations = useMemo(
    () => ({
      admin: 'Адміністратор',
      webmaster: 'Вебмайстер',
      advertiser: 'Рекламодавець'
    }),
    []
  )

  const columns = useMemo(
    () => [
      { field: 'id', headerName: 'ID користувача', width: 120 },
      { field: 'email', headerName: 'Електронна адреса', width: 240 },
      { field: 'first_name', headerName: "Ім'я", width: 120 },
      { field: 'last_name', headerName: 'Прізвище', width: 120 },
      { field: 'offers', headerName: 'Офери', width: 240 },
      { field: 'partner', headerName: 'Партнер', width: 120 },
      { field: 'webmaster_id', headerName: 'ID вебмайстра', width: 120 },
      { field: 'address', headerName: 'Адреса', width: 160 },
      { field: 'phone', headerName: 'Телефон', width: 240 },
      { field: 'balance', headerName: 'Баланс', width: 120 },
      {
        field: 'conversion_payment',
        headerName: 'Плата за конверсію',
        width: 120
      },
      { field: 'user_type', headerName: 'Тип користувача', width: 240 }
    ],
    []
  )
  const rows = useMemo(
    () =>
      profiles.map(profile => ({
        id: profile.id,
        email: profile.email,
        first_name: profile.first_name,
        last_name: profile.last_name,
        offers: profile.offers.map(o => o.company.company_name).join(', '),
        partner: profile.partner,
        webmaster_id: profile.webmaster_id,
        address: profile.address,
        phone: profile.phone,
        balance: `${profile.balance}₴`,
        conversion_payment: `${profile.conversion_payment}₴`,
        user_type: translations[profile.user_type]
      })),
    [profiles, translations]
  )

  const [selectedProfileData, setSelectedProfileData] = useState({})

  const [modalOpen, setModalOpen] = useState(false)

  const handleOpen = e => {
    setSelectedProfileData(profiles.find(profile => profile.id === e.id))
    setModalOpen(true)
  }
  const handleClose = () => setModalOpen(false)

  useEffect(() => {
    profiles.length > 0 && setSelectedProfileData(profiles[0].id)
  }, [profiles])

  function CustomFooter() {
    return (
      <Stack
        sx={{
          flexGrow: 1,
          display: 'flex',
          flexDirection: { xs: 'column', md: 'row' },
          gap: { xs: '0', md: '30px' },
          justifyContent: { xs: 'center', md: 'space-between' },
          alignItems: { xs: 'space-between', md: 'center' }
        }}
      >
        <Stack direction='row' spacing={1} mt={2} mb={2}>
          <Button
            size='small'
            color='info'
            variant='outlined'
            value={10}
            onClick={e => {
              setPageSize(+e.target.value)
              localStorage.setItem('setPage', +e.target.value)
            }}
          >
            10
          </Button>
          <Button
            size='small'
            color='info'
            variant='outlined'
            value={20}
            onClick={e => {
              setPageSize(+e.target.value)
              localStorage.setItem('setPage', +e.target.value)
            }}
          >
            20
          </Button>
          <Button
            size='small'
            color='info'
            variant='outlined'
            value={50}
            onClick={e => {
              setPageSize(+e.target.value)
              localStorage.setItem('setPage', +e.target.value)
            }}
          >
            50
          </Button>
          <Button
            size='small'
            color='info'
            variant='outlined'
            value={100}
            onClick={e => {
              setPageSize(+e.target.value)
              localStorage.setItem('setPage', +e.target.value)
            }}
          >
            100
          </Button>
        </Stack>
        <CustomPagination />
      </Stack>
    )
  }

  if (error) return <Alert severity='error'>{error}</Alert>

  return (
    <Box>
      <DataGrid
        sortingOrder={['desc', 'asc']}
        onRowClick={user.user_type === 'admin' ? e => handleOpen(e) : () => {}}
        autoHeight
        columns={columns}
        rows={rows}
        pageSize={pageSize}
        components={{
          Footer: CustomFooter
        }}
        rowsPerPageOptions={[10]}
        localeText={LocalizedTextsMap}
      />
      <EditProfileModal
        handleClose={handleClose}
        open={modalOpen}
        profile_data={selectedProfileData}
      />
    </Box>
  )
}

export default ProfilesList
