import * as React from 'react'
import { useEffect, useState } from 'react'
import { Checkbox } from '@mui/material'
import './CustomCheckbox.scss'

export const CustomCheckbox = ({ name, onChange, columns }) => {
  const [checked, setChecked] = useState(true)
  useEffect(() => {
    setChecked(columns[name])
  }, [columns, name])
  const handleChange = () => {
    onChange(name)
  }
  return <Checkbox color='primary' onChange={handleChange} checked={checked} />
}
