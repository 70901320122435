import './Header.scss'
import Logo from '../../../assets/images/Logo.svg'
import { Menu } from '../Menu/'
import { Link } from 'react-router-dom'
import { useState } from 'react'


export const Header = ({   
  // webDewelopScroll,
  // advertriseScroll,
  contactsScroll, 
  
}) => {
  const [menuClass, setMenuClass] = useState('header__menu-phone--hidden')
  const [buttonClass, setButtonClass] = useState('header__button-menu--open')
  const [isMenuCliked, setIsMenuClicked] = useState(false)


  const updateMenu = () => {
    if (!isMenuCliked) {
      setMenuClass('header__menu-phone--visible')
      setButtonClass('header__button-menu--close')
    } else {
      setMenuClass('header__menu-phone--hidden')
      setButtonClass('header__button-menu--open')
    }
    setIsMenuClicked(!isMenuCliked)
  }

  return (
    <header className='header' >
      <div className='header__container'>
        <div className='header__block-menu'>
          <Link className='header__logo' to='/'>
            <img className='header__logo-img' src={Logo} alt='logo' />
          </Link>
          <div className='header__menu-desktop'>
            <Menu
              // webDewelopScroll={webDewelopScroll}
              // advertriseScroll={advertriseScroll}
              contactsScroll={contactsScroll}
            />
          </div>
          <button onClick={updateMenu} className={buttonClass}></button>
        </div>
        <h1 className='header__slogan'>
          <span className='header__slogan-start'>Lead Leader</span> — платформа
          маркетингових рішень
        </h1>
        <Link to={'/admin/registration/'}
          className='header__partnership-button'          
        >
          <div className='header__partnership-link'>Почати співпрацю</div>
          <div className='header__arrow'>
            <div className='header__arrow-line'></div>
            <div className='header__arrow-line-bottom'></div>
          </div>
        </Link>
      </div>
      <div className={menuClass} id='menu-fone'>
        <Menu
          updateMenu={updateMenu}
          // webDewelopScroll={webDewelopScroll}
          // advertriseScroll={advertriseScroll}
          contactsScroll={contactsScroll}
        />
      </div>
    </header>
  )
}
