import React, { useState } from 'react'
import { Button, Stack, TextField, useTheme } from '@mui/material'
import { useDispatch, useSelector } from 'react-redux'
import { createCustomUser } from 'store/reducers/admin/adminActions'
import { userTypes } from 'data/constants'
import MyModal from 'UI/MyModal'
import EditProfileInfo from 'components/admin/profiles/edit/EditProfileInfo'

const initUserDataState = {
  email: '',
  first_name: '',
  last_name: '',
  partner: -1,
  webmaster_id: '',
  offers: [],
  address: '',
  phone: '',
  balance: 0,
  conversion_payment: 0,
  user_type: userTypes[0].value,
  password: ''
}

const CreateProfile = ({ open, handleClose }) => {
  const { partners, adminOffers } = useSelector(state => state.admin)
  const dispatch = useDispatch()

  const theme = useTheme()

  const [userData, setUserData] = useState(initUserDataState)
  const {
    email,
    first_name,
    last_name,
    offers,
    partner,
    webmaster_id,
    address,
    phone,
    balance,
    conversion_payment,
    user_type,
    password
  } = userData

  const handleChange = e => {
    if (e.target.name === 'offers') {
      if (offers.includes(e.target.value)) {
        setUserData({
          ...userData,
          offers: offers
            .filter(o => o.id !== e.target.value)
            .map(o => partners.find(p => p.id === o).id)
        })
      } else {
        setUserData({
          ...userData,
          offers: e.target.value.map(
            o => adminOffers.find(ao => ao.id === o).id
          )
        })
      }
    } else {
      setUserData({
        ...userData,
        [e.target.name]: e.target.value
      })
    }
  }

  const handleCreate = () => {
    const formattedData = { ...userData }
    if (formattedData.partner === -1) {
      delete formattedData.partner
    }
    dispatch(createCustomUser(formattedData))
    setUserData(initUserDataState)
    handleClose()
  }

  return (
    <MyModal open={open} onClose={handleClose}>
      <Stack spacing={2}>
        <EditProfileInfo
          handleChange={handleChange}
          handleClick={handleCreate}
          address={address}
          email={email}
          offers={offers}
          partner={partner}
          webmaster_id={webmaster_id}
          phone={phone}
          balance={balance}
          conversion_payment={conversion_payment}
          first_name={first_name}
          last_name={last_name}
          user_type={user_type}
          isCreation={true}
        />
        <TextField
          type='password'
          required
          label='Пароль'
          name='password'
          onChange={e => handleChange(e)}
          value={password}
        />
      </Stack>
      <Button
        disabled={
          (user_type === 'webmaster' && (!webmaster_id || offers.length < 1)) ||
          (user_type === 'advertiser' && !partner) ||
          email === '' ||
          password === ''
        }
        variant='contained'
        color='primary'
        sx={{ mt: theme.spacing(3) }}
        onClick={() => handleCreate()}
      >
        Створити
      </Button>
    </MyModal>
  )
}

export default CreateProfile
