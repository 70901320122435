import React, { useEffect, useState } from 'react'
import Box from '@mui/material/Box'
import { useSelector } from 'react-redux'
import moment from 'moment'
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer
} from 'recharts'
import ToggleButton from '@mui/material/ToggleButton'
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup'
import { useTheme } from '@emotion/react'

const ChartsByDate = () => {
  const { filteredConversions } = useSelector(state => state.admin)
  const { user } = useSelector(state => state.admin)

  const theme = useTheme()

  const [data, setData] = useState()
  const [alignment, setAlignment] = useState('all')
  const [maxY, setMaxY] = useState(0)

  const handleChange = (_, newAlignment) => {
    if (newAlignment) setAlignment(newAlignment)
  }

  const convertDate = date => {
    const newDate = date.split('-')
    const curentDate = [newDate[2], newDate[1], newDate[0]]
    return curentDate.join('.')
  }

  const getDates = (start, stop) => {
    let dateArray = []
    let currentDate = moment(start)
    let stopDate = moment(stop)

    while (currentDate <= stopDate) {
      const date = moment(currentDate).format('YYYY-MM-DD')
      dateArray.push({
        name: date,
        conversions: 0,
        ip: {},
        countIpRepead: 0
      })
      currentDate = moment(currentDate).add(1, 'days')
    }
    return dateArray
  }

  useEffect(() => {
    const date = JSON.parse(localStorage.getItem('dateRange')) || {
      end_date: '2023-07-19',
      start_date: '2023-07-01'
    }
    const currentDates = getDates(date.start_date, date.end_date)
    let countMaxConversions = 0

    const conversionMap = filteredConversions.reduce((map, conversion) => {
      const date = moment(conversion.conversion_time)
        .format('DD.MM.YYYY HH:mm:ss')
        .split(' ')[0]
      if (!map[date]) {
        map[date] = {
          conversions: 0,
          ip: {},
          countIpRepead: 0
        }
      }
      map[date].conversions += 1
      if (map[date].ip[conversion.visitor_ip]) {
        map[date].ip[conversion.visitor_ip] += 1
        map[date].countIpRepead += 1
      } else {
        map[date].ip[conversion.visitor_ip] = 1
      }

      return map
    }, {})

    for (let i = 0; i < currentDates.length; i++) {
      const dateStats =
        conversionMap[currentDates[i].name.split('-').reverse().join('.')]
      if (dateStats) {
        currentDates[i].conversions = dateStats.conversions
        currentDates[i].ip = dateStats.ip
        currentDates[i].countIpRepead = dateStats.countIpRepead

        const currentCount = currentDates[i].conversions
        if (currentCount > countMaxConversions) {
          countMaxConversions = currentCount
        }
      }
      currentDates[i].name = convertDate(currentDates[i].name)
    }

    if (alignment === 'unique') {
      currentDates.forEach(
        date => (date.conversions = date.conversions - date.countIpRepead)
      )
    }

    setMaxY(countMaxConversions + 1)
    setData(currentDates)
  }, [alignment, filteredConversions, user.offers, user.user_type])

  return (
    <Box
      sx={{
        width: '100%',
        alignItems: 'center',
        display: 'flex',
        paddingBlock: 3,
        paddingRight: 3,
        gap: 2
      }}
    >
      <ResponsiveContainer width='100%' height={300}>
        <LineChart
          width={730}
          height={250}
          data={data}
          margin={{ top: 10, right: 30, left: 5, bottom: 5 }}
          padding={{ right: 10 }}
        >
          <CartesianGrid strokeDasharray='3 3' vertical={false} />
          <XAxis
            dataKey='name'
            tick={{ fontSize: 10 }}
            minTickGap={10}
            tickFormatter={date => date.split('.').slice(0, 2).join('.')}
          />

          <YAxis
            domain={[0, dataMax => Math.max(dataMax, maxY)]}
            tick={{ fontSize: 12 }}
          />
          <Tooltip
            wrapperStyle={{
              border:
                theme.palette.mode === 'dark'
                  ? '1px solid white'
                  : '1 px solid black'
            }}
            contentStyle={{
              backgroundColor: theme.palette.mode === 'dark' ? 'gray' : 'white',
              color: theme.palette.mode === 'dark' ? 'white' : 'black'
            }}
            itemStyle={{
              color: theme.palette.mode === 'dark' ? 'white' : 'black'
            }}
          />
          <Line
            type='monotone'
            dataKey='conversions'
            name='конверсій'
            stroke='#1565c0'
            strokeWidth={3}
            dot={{ stroke: '#1565c0', strokeWidth: 1, r: 2 }}
          />
        </LineChart>
      </ResponsiveContainer>
      <ToggleButtonGroup
        color='primary'
        value={alignment}
        exclusive
        onChange={handleChange}
        aria-label='Platform'
        orientation='vertical'
      >
        <ToggleButton value='all'>Усі</ToggleButton>
        <ToggleButton value='unique'>Унікальні</ToggleButton>
      </ToggleButtonGroup>
    </Box>
  )
}
export default ChartsByDate
