import { useTheme } from '@mui/material'
import { useState } from 'react'
import {
  GridCsvExportMenuItem,
  GridPrintExportMenuItem,
  GridToolbarExportContainer
} from '@mui/x-data-grid'
import PdfExportMenuItem from './PdfExportMenuItem'
import TxtExportMenuItem from './TxtExportMenuItem'
import { saveAs } from 'file-saver'
import { pdf } from '@react-pdf/renderer'
import { useSelector } from 'react-redux'
import { Button, CircularProgress, Box } from '@mui/material'

function CustomExportToolbar({ rows, columns }) {
  const { user } = useSelector(state => state.admin)
  const [isLoading, setIsLoading] = useState(false)
  const theme = useTheme()

  const handlePdfExport = async () => {
    setIsLoading(true)

    const containsIdField = columns.some(obj => obj.field === 'id')

    let newRows = rows
    if (!containsIdField) {
      newRows = [...rows]
        .map(({ id, ...rest }) => rest)
        .sort(
          (a, b) => new Date(a.conversion_time) - new Date(b.conversion_time)
        )
    } else {
      newRows = [...rows].sort(
        (a, b) => new Date(a.conversion_time) - new Date(b.conversion_time)
      )
    }

    const indexToRemove = columns.findIndex(obj => obj.field === 'webmaster_id')
    if (indexToRemove !== -1) {
      const removedObject = columns.splice(indexToRemove, 1)[0]
      columns.push(removedObject)
    }

    try {
      const pdfDocument = (
        <PdfExportMenuItem rows={newRows} columns={columns} user={user} />
      )
      if (pdfDocument) {
        const blob = await pdf(pdfDocument).toBlob()
        saveAs(blob, 'conversion-list.pdf')
        setIsLoading(false)
      } else {
        console.error('Ошибка при создании PDF-документа.')
      }
    } catch (error) {
      console.error('Ошибка при экспорте в PDF:', error)
      setIsLoading(false)
    }
  }

  return (
    <GridToolbarExportContainer>
      {isLoading ? (
        <Box
          sx={{
            width: '100%',
            display: 'flex',
            justifyContent: 'center'
          }}
        >
          <CircularProgress size={30} />
        </Box>
      ) : (
        <Box
          sx={{
            width: '100%',
            ':hover': {
              bgcolor: theme.palette.mode === 'dark' ? '#424242' : '#fafafa'
            }
          }}
        >
          <Button
            disableRipple
            style={{
              color: theme.palette.mode === 'dark' ? 'white' : 'black',
              borderRadius: 0,
              textTransform: 'none',
              backgroundColor: 'transparent',
              border: 'none',
              paddingLeft: '16px',
              fontSize: '1rem',
              fontWeight: 400
            }}
            onClick={handlePdfExport}
          >
            Завантажити як PDF
          </Button>
        </Box>
      )}

      <TxtExportMenuItem rows={rows} />
      <GridCsvExportMenuItem
        options={{
          getRowsToExport: params => {
            let response = []

            if (params.apiRef.current) {
              params.apiRef.current.setSortModel([
                { field: 'conversion_time', sort: 'asc' }
              ])

              response = params.apiRef.current.getSortedRowIds()
            }

            return response
          }
        }}
      />
      <GridPrintExportMenuItem />
    </GridToolbarExportContainer>
  )
}

export default CustomExportToolbar
